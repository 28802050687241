import './Notification.css'; 
import { useEffect} from 'react'; 
//Components
import NotificationItem from './NotificationItem';


const Notification = ({show,curAccount,requestorList,setrequestorList,setnotiResponse,setfromNoti,requestorStatus,fromEventWith,invitorEvent,setinvitorEvent,invitorList,resfollowList,setresfollowList}) => {

    const notificationClass = ["notification"]; 
    if (show) {
        notificationClass.push("show");
    }

    return <div className={notificationClass.join(" ")}>

    <div className="notification__title">Notifications</div>

    {fromEventWith ? 
    invitorEvent && invitorEvent.length === 0
                    ? ""
                    : invitorEvent.map(eventitem => (
                    <NotificationItem eventitem={eventitem} 
                                        curAccount={curAccount} 
                                        setinvitorEvent={setinvitorEvent} 
                                        invitorEvent={invitorEvent} 
                                        setnotiResponse={setnotiResponse} 
                                        setfromNoti={setfromNoti} 
                                        invitorList={invitorList} 
                                        fromEventWith={fromEventWith}/> 
                    ))
    :
    requestorList && requestorList.length === 0
                    ? ""
                    : requestorList.map(requestitem => (
                    <NotificationItem requestitem={requestitem} 
                                        curAccount={curAccount} 
                                        setrequestorList={setrequestorList} 
                                        requestorList={requestorList} 
                                        setnotiResponse={setnotiResponse} 
                                        setfromNoti={setfromNoti} 
                                        requestorStatus={requestorStatus}/> 
                    ))
    }
    



    
    </div>;
};

export default Notification

