import './EventsWith.css';
import {useHistory} from 'react-router-dom';

const EventsWith = ({userWith,vAccount,curAccount}) => {
    let history = useHistory();

    const openUserAccount = (linkk) => {
        history.push(`/${linkk}`);
        window.location.reload();
      }
    
    return (
        
        <div className="withuser__pic" onClick={() => openUserAccount(userWith.inviteLink)}>
                {/*
                {withResultList.filter(res => userWith._id === res.inviteId)[0].result
                    ? 
                    <>
                    <span className="with__pic__accept__badge">OK</span>
                    <img src={userWith.imageUrl} alt="with__pic" className="with__pic__accept"/>  
                    </>
                    : withResultList.filter(ress => userWith._id === ress.inviteId)[0].inviteRequest 
                        ? 
                        <>
                        <span className="with__pic__wait">Wait</span>
                        <img src={userWith.imageUrl} alt="with__pic" className="with__pic__request"/>  
                        </>
                        : 
                        <>
                        <span className="with__pic__reject__badge">Busy</span>
                        <img src={userWith.imageUrl} alt="with__pic" className="with__pic__reject"/>
                        </>
                } */}
                {curAccount === null ?
                <img src={userWith.inviteImage} alt="with__pic" className="with__pic__accept"/>
                :
                
                
                curAccount.userDetail._id === vAccount.vuserDetail._id ?

                userWith.result
                    ? 
                    <>
                    <span className="with__pic__accept__badge">OK</span>
                    <img src={userWith.inviteImage} alt="with__pic" className="with__pic__accept"/>  
                    </>
                    : userWith.inviteRequest 
                        ? 
                        <>
                        <span className="with__pic__wait">Wait</span>
                        <img src={userWith.inviteImage} alt="with__pic" className="with__pic__request"/>  
                        </>
                        : 
                        <>
                        <span className="with__pic__reject__badge">Busy</span>
                        <img src={userWith.inviteImage} alt="with__pic" className="with__pic__reject"/>
                        </>
                :
                <img src={userWith.inviteImage} alt="with__pic" className="with__pic__accept"/>  
                }
                
        </div>
                
        
    )
}

export default EventsWith