import './NotificationItem.css';
import { Button } from "@material-ui/core";
import { useSelector, useDispatch } from 'react-redux';
import ButterToast , { Cinnamon } from "butter-toast";
import { CheckCircle, Cancel,DirectionsRun } from "@material-ui/icons";
import {useHistory} from 'react-router-dom';

// Actions
import { unFollowAct,updateRequestAct,updateFriendAct,updateResponsefAct,updateAcknowledgeAct } from '../redux/actions/followActions';
import { updateInviteAct,updateInviteResAct,updateResultAct,addEventWithAct } from '../redux/actions/withActions';


const NotificationItem = ({requestitem,curAccount,setrequestorList,requestorList,setnotiResponse,setfromNoti,requestorStatus,eventitem,setinvitorEvent,invitorEvent,invitorList,fromEventWith}) => {
    let history = useHistory();
    // ******************** Redux Store variable**********************
    const dispatch = useDispatch();
    const unFollow = useSelector(state => state.unFollows); 
    const { unfollowLog } = unFollow; 
    const updateRequest = useSelector(state => state.updateRequests); 
    const { requestLog } = updateRequest; 
    const updateFriend = useSelector(state => state.updateFriends); 
    const { friendLog } = updateFriend; 
    const updateInvite = useSelector(state => state.updateInvites); 
    const { invitedLog } = updateInvite; 
    const updateInviteRes = useSelector(state => state.updateInviteRess); 
    const { invitedResLog } = updateInviteRes; 
    const updateResult = useSelector(state => state.updateResults); 
    const { resLog } = updateResult; 
    const updateResponse = useSelector(state => state.updateResponses); 
    const { responsefLog } = updateResponse; 
    const updateAcknowledge = useSelector(state => state.updateAcknowledges); 
    const { acknowledgeLog } = updateAcknowledge; 
    // **********************************************************

    const openUserAccount = (linkk) => {
        history.push(`/${linkk}`);
        window.location.reload();
      }
      const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
      }  

    const acceptHandle = (id, followId) => {
        const filterUndefine = requestorStatus.filter(chk => typeof(chk) !== 'undefined');
        const filterUserId = filterUndefine.filter(chk2 => chk2.followId === requestitem._id);
        
        if (filterUserId.length > 0) {
            dispatch(updateRequestAct(id,followId));
            dispatch(updateFriendAct(id,followId,"true"));
            dispatch(updateFriendAct(followId,id,"true"));
            dispatch(updateResponsefAct(id,followId,"true"));
            dispatch(updateAcknowledgeAct(id,followId,"true"));
            setrequestorList(requestorList.filter(a => a._id !== requestitem._id));
            setfromNoti(true);
            setnotiResponse(true);
            ButterToast.raise({
                content:<Cinnamon.Crisp title="Accept Request"
                    content= {`Now! ${requestitem.name} and you are Friend`}
                    scheme = {Cinnamon.Crisp.SCHEME_GREEN}
                    icon={<CheckCircle />}
                />})
        } else {
            dispatch(updateRequestAct(id,followId));
            dispatch(updateResponsefAct(id,followId,"true"));
            dispatch(updateAcknowledgeAct(id,followId,"true"));
            setrequestorList(requestorList.filter(a => a._id !== requestitem._id));
            setfromNoti(true);
            setnotiResponse(true);
            ButterToast.raise({
                content:<Cinnamon.Crisp title="Accept Request"
                    content= {`Now! ${requestitem.name} following you`}
                    scheme = {Cinnamon.Crisp.SCHEME_GREEN}
                    icon={<CheckCircle />}
                />})
        }
      } 
      const declineHandle = (id, followId) => {
        dispatch(unFollowAct(id,followId));
        setrequestorList(requestorList.filter(a => a._id !== requestitem._id));
        setfromNoti(true);
        setnotiResponse(true);
        ButterToast.raise({
            content:<Cinnamon.Crisp title="Decline Request"
                content= {`${requestitem.name} will can't see your events`}
                scheme = {Cinnamon.Crisp.SCHEME_RED}
                icon={<Cancel />}
            />})
      }  

      const busyHandle = (eventiid, id) => {
        dispatch(updateInviteAct(eventiid,id));
        dispatch(updateResultAct(eventiid,id,"false"));
        dispatch(updateInviteResAct(eventiid,id,"true"));
        setinvitorEvent(invitorEvent.filter(b => b.eventId !== eventitem.eventId));
        setfromNoti(true);
        setnotiResponse(true);
        ButterToast.raise({
            content:<Cinnamon.Crisp title="You're busy on that time"
                content= {`${invitorList.filter(res => eventitem.createId === res._id)[0].name} will know you answer`}
                scheme = {Cinnamon.Crisp.SCHEME_RED}
                icon={<Cancel />}
            />})
      }  

      const goHandle = (eventiid, id) => {
        dispatch(updateInviteAct(eventiid,id));
        dispatch(updateResultAct(eventiid,id,"true"));
        dispatch(updateInviteResAct(eventiid,id,"true"));
        setinvitorEvent(invitorEvent.filter(b => b.eventId !== eventitem.eventId));
        setfromNoti(true);
        setnotiResponse(true);
        ButterToast.raise({
            content:<Cinnamon.Crisp title="Let's Go"
                content= {`${invitorList.filter(res => eventitem.createId === res._id)[0].name} will know you answer`}
                scheme = {Cinnamon.Crisp.SCHEME_GREEN}
                icon={<DirectionsRun />}
            />})
      }  
    // **********************************************************
//
    return (
        <div className="item__detail">
            {fromEventWith ?
                eventitem.notiType ?
            <>
                <div className="item__left">
                    <img src={eventitem.resImageUrl}
                    alt="item__pic" 
                    className="item__pic"
                    onClick={() => openUserAccount(eventitem.resLink)}/>  
                </div> 
                <div className="item__right">
                    <div className="item__name">{eventitem.resName}</div>  
                    <div style={{ backgroundColor: `${eventitem.eventColor}`, color: "black"}} className="item__title" onClick={() => openInNewTab(`https://google.co.th/maps/search/${eventitem.eventLocation}/`)}>[<span className = "icon">{eventitem.eventIcon}</span>    {eventitem.eventLocation}]</div> 
                    
                    
                    <div className="item__info2">{new Date(eventitem.eventDate).toString().substring(0,15)}</div> 
                    
                    <div className="item__info2">Time : {new Date(eventitem.eventDate).toString().substring(16,21)} - {new Date(eventitem.eventEndDate).toString().substring(16,21)}</div> 
                    { eventitem.resResult ?
                    <div className="item__info__go">I'm avaliable. Let's go!</div> 
                    :
                    <div className="item__info__busy">I'm busy. Maybe next time!</div> 
                    }
                </div>
            </>
            :
            <>
            <div className="item__left">
                <img src={invitorList.filter(res => eventitem.createId === res._id)[0].imageUrl}
                alt="item__pic" 
                className="item__pic"
                onClick={() => openUserAccount(invitorList.filter(res => eventitem.createId === res._id)[0].linkId)}/>  
            </div> 
            <div className="item__right">
                <div className="item__name">{invitorList.filter(res => eventitem.createId === res._id)[0].name}</div>  
                <div className="item__info">invited you to</div> 
                <div style={{ backgroundColor: `${eventitem.eventColor}`, color: "black"}} className="item__title" onClick={() => openInNewTab(`https://google.co.th/maps/search/${eventitem.eventLocation}/`)}>[<span className = "icon">{eventitem.eventIcon}</span>    {eventitem.eventLocation}]</div> 
                <span className="item__location__badge" onClick={() => openInNewTab(`https://google.co.th/maps/search/${eventitem.eventLocation}/`)}>Open Map</span> 
                <div className="item__info2"> - {eventitem.eventDetail}</div> 
                
                <div className="item__info2">{new Date(eventitem.eventDate).toString().substring(0,15)}</div> 
                
                <div className="item__info2">Time : {new Date(eventitem.eventDate).toString().substring(16,21)} - {new Date(eventitem.eventEndDate).toString().substring(16,21)}</div> 
                
                <div className="item__btnevent">
                    <Button variant="contained" 
                    size="medium"
                    color= "primary"
                    type="submit"
                    className="item__go"
                    onClick= {() => goHandle(eventitem.eventId,curAccount.userDetail._id)}
                    >Let's Go</Button>
                    <Button variant="contained" 
                    size="medium"
                    color="secondary"
                    type="submit"
                    className="item__busy"
                    onClick= {() => busyHandle(eventitem.eventId,curAccount.userDetail._id)}
                    >I'm busy</Button>
                </div>
                
            </div>
            </>
            :
            requestitem.notiType === 1 ?
            <>
            <div className="item__left">
                <img src={requestitem.imageUrl}
                alt="item__pic" 
                className="item__pic"
                onClick={() => openUserAccount(requestitem.linkId)}/>  
            </div> 
            <div className="item__right">
                <div className="item__name">{requestitem.name}</div>  
                <div className="item__info">accept your follow requested.</div> 
            </div>
            </>
            :
            requestitem.notiType === 2 ?
            <>
            <div className="item__left">
                <img src={requestitem.imageUrl}
                alt="item__pic" 
                className="item__pic"
                onClick={() => openUserAccount(requestitem.linkId)}/>  
            </div> 
            <div className="item__right">
                <div className="item__name">{requestitem.name}</div>  
                <div className="item__info">start following your account.</div> 
            </div>
            </>
            :
            <>
            <div className="item__left">
                <img src={requestitem.imageUrl}
                alt="item__pic" 
                className="item__pic"
                onClick={() => openUserAccount(requestitem.linkId)}/>  
            </div> 
            <div className="item__right">
                <div className="item__name">{requestitem.name}</div>  
                <div className="item__info">requested to follow your account.</div> 
                
                <div className="item__btn">
                    <Button variant="contained" 
                    size="medium"
                    color="primary"
                    type="submit"
                    className="item__accept"
                    onClick= {() => acceptHandle(requestitem._id,curAccount.userDetail._id)}
                    >Accept</Button>
                    <Button variant="contained" 
                    size="medium"
                    type="submit"
                    className="item__decline"
                    onClick= {() => declineHandle(requestitem._id,curAccount.userDetail._id)}
                    >Decline</Button>
                </div>
            </div>
            </>
            }
            

        </div>
                
        
    )
}

export default NotificationItem