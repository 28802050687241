export const GET_EVENT_WITH_REQUEST = "GET_EVENT_WITH_REQUEST";
export const GET_EVENT_WITH_SUCCESS = "GET_EVENT_WITH_SUCCESS";
export const GET_EVENT_WITH_FAIL = "GET_EVENT_WITH_FAIL";

export const ADD_EVENT_WITH = "ADD_EVENT_WITH";
export const ADD_EVENT_WITH_FAIL = "ADD_EVENT_WITH_FAIL";

export const GET_INVITEREQUEST_REQUEST = "GET_INVITEREQUEST_REQUEST";
export const GET_INVITEREQUEST_SUCCESS = "GET_INVITEREQUEST_SUCCESS";
export const GET_INVITEREQUEST_FAIL = "GET_INVITEREQUEST_FAIL";

export const GET_INVITERESPONSE_REQUEST = "GET_INVITERESPONSE_REQUEST";
export const GET_INVITERESPONSE_SUCCESS = "GET_INVITERESPONSE_SUCCESS";
export const GET_INVITERESPONSE_FAIL = "GET_INVITERESPONSE_FAIL";

export const UPDATE_INVITEREQUEST = "UPDATE_INVITEREQUEST";
export const UPDATE_INVITEREQUEST_FAIL = "UPDATE_INVITEREQUEST_FAIL";

export const UPDATE_INVITERESPONSE = "UPDATE_INVITERESPONSE";
export const UPDATE_INVITERESPONSE_FAIL = "UPDATE_INVITERESPONSE_FAIL";

export const UPDATE_RESULT = "UPDATE_RESULT";
export const UPDATE_RESULT_FAIL = "UPDATE_RESULT_FAIL";

export const UPDATE_WITHEVENTTYPE = "UPDATE_WITHEVENTTYPE";
export const UPDATE_WITHEVENTTYPE_FAIL = "UPDATE_WITHEVENTTYPE_FAIL";

export const DELETE_EVENT_WITH = "DELETE_EVENT_WITH";
export const DELETE_EVENT_WITH_FAIL = "DELETE_EVENT_WITH_FAIL";

export const DELETE_ALLEVENT_WITH = "DELETE_ALLEVENT_WITH";
export const DELETE_ALLEVENT_WITH_FAIL = "DELETE_ALLEVENT_WITH_FAIL";

