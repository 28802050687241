
import './HomeScreen.css';
import { useState , useEffect} from 'react'; 
import {useHistory} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';



// Actions
import { getUserDetailsByEmail } from '../redux/actions/userActions';




const HomeScreen = ({currentUser,isLoggedIn,setNotiToggle,setfromNoti,fromNoti,setregisterToggle,setMainToggle}) => {
  const [InitialPage, setInitialPage] = useState(false);
  let history = useHistory();
  const dispatch = useDispatch();
  const userDetails = useSelector(state => state.getUserDetails); 
  const { loading, userDetail } = userDetails; 

  const requestUserDataHandler = (email) => {
    dispatch(getUserDetailsByEmail(email));
    console.log("request User Detail by Email Success[Home]");
  }
  
  useEffect(()=>{ 
      if (isLoggedIn)
      {
        requestUserDataHandler(currentUser.email);
        setInitialPage(true);
        setMainToggle(false);
      } 
      setMainToggle(false);
  },[isLoggedIn]); 


  useEffect(()=>{ 
    if (isLoggedIn) {
      if (typeof(userDetail) !== 'undefined') 
      {
        setregisterToggle(false);
        history.push(`/${userDetail.linkId}`);
        window.location.reload();
      } else {
        
        if (loading === false)
        {
          setregisterToggle(true);
        } 
        
      }
    } else {
      if (typeof(loading) === 'undefined')
        {
            setMainToggle(true);
        } else {
          setMainToggle(false);
        }
       
    }
    
},[userDetail]); 



  if (InitialPage) {
    if (isLoggedIn) {
      if (typeof(userDetail) === 'undefined') 
      {
        if (loading === false)
        {
          setregisterToggle(true);
        } 
      } 
    } 
  }
  
    return (
      <div className = "homescreen" onClick={() => {fromNoti ?  window.location.reload() || setNotiToggle(false) || setfromNoti(false) : setNotiToggle(false)}}>
       {loading === true ?
       <>
        <div className="homescreen__loading"></div>
        <div className="homescreen__loadtext">Loading . . .</div>
      </>
       :
       ""
       } 
      
        
      </div>
    )
    
}

export default HomeScreen