import './CreateWith.css';

const CreateWith = ({friend,invitefriendList,setinvitefriendList,setinviteSelect,allfriendList,setallfriendList}) => {
    const addInviteFriendList = () => { 
        let arr = invitefriendList;
        arr.push(friend);
        setinvitefriendList(arr);
        setallfriendList(allfriendList.filter(a => a._id !== friend._id));
        setinviteSelect(true);
    };
    return (
        <div className="createwith__detail" onClick={() => addInviteFriendList()}>
            <div className="createwith__picarea">
                <img src={friend.imageUrl} 
                alt="createwith__pic" 
                className="createwith__pic"/>
            </div>        
            <div className="createwith__name">{friend.name}</div>    
        </div>
                
        
    )
}

export default CreateWith