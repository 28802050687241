import './AccountSetting.css';
import { useSelector, useDispatch } from 'react-redux';
//Actions
import { updateUserTypeAct } from '../redux/actions/userActions';
const AccountSetting = ({show,relation,curAccount,vAccount,setregisterToggle,setupdatefavourite,setunfollow}) => {
    const dispatch = useDispatch();
    const updateUserType = useSelector(state => state.updateUserTypes); 
    const { userTypeLog } = updateUserType;

    const setupdateusertype = (id, type) => {
        dispatch(updateUserTypeAct(id,type));
        console.log("Update UserShareType Success");
        window.location.reload()
      };



    const accountSettingDrawerClass = ["accountsettingdrawer"]; 
    if (show) {
        accountSettingDrawerClass.push("show");
    }


    return (
        <div className="accountsetting__main">
            {relation === "Private Account" || relation === "Public Account" || relation === "Private" ||  relation === "Public"  ?
                curAccount.userDetail.shareType === 1 ?
                <>
                    <div className="accountsetting__public" onClick={() => setupdateusertype(curAccount.userDetail._id,1)}>Set Account to Public  <span className="icon">check</span> </div>
                    <div className="accountsetting__private" onClick={() => setupdateusertype(curAccount.userDetail._id,2)}>Set Account to Private</div>
                    <div className="accountsetting__set" onClick={() => setregisterToggle(true)}>Setting Account</div>
                </>
                :
                <>
                <div className="accountsetting__public" onClick={() => setupdateusertype(curAccount.userDetail._id,1)}>Set Account to Public</div>
                <div className="accountsetting__private" onClick={() => setupdateusertype(curAccount.userDetail._id,2)}>Set Account to Private  <span className="icon">check</span> </div>
                <div className="accountsetting__set" onClick={() => setregisterToggle(true)}>Setting Account</div>
                </>
            :
            relation === "Friend" || relation === "Following"?
            <>
                <div onClick={() => setupdatefavourite(curAccount.userDetail._id,vAccount.vuserDetail._id,"true")}>Set Favourite Account</div>
                <div onClick={() => setunfollow(curAccount.userDetail._id,vAccount.vuserDetail._id)}>Unfollow this Account</div>
                
            </>
            :
            relation === "Favourite" ?
            <>
                <div onClick={() => setupdatefavourite(curAccount.userDetail._id,vAccount.vuserDetail._id,"false")}>Remove Favourite Account</div>
                <div onClick={() => setunfollow(curAccount.userDetail._id,vAccount.vuserDetail._id)}>Unfollow this Account</div>
            </>
            :
            relation === "Requested" ?
            <>
                <div onClick={() => setunfollow(curAccount.userDetail._id,vAccount.vuserDetail._id)}>Remove Follow Requested</div>
            </>
            :
            ""
            }
            
            
        </div>
    )
}

export default AccountSetting