import './AccountSearch.css';
import {useHistory} from 'react-router-dom';

const AccountSearch = ({...faccount}) => {
    let history = useHistory();
    const openUserAccount = (linkk) => {
        history.push(`/${linkk}`);
        window.location.reload();
      }
    return (
        <div className="accountsearch__detail" onClick={() => openUserAccount(faccount.linkId)}>
            <img src={faccount.imageUrl} 
            alt="accountsearch__pic" 
            className="accountsearch__pic"/>         
            <div className="accountsearch__info">
                <div className="accountsearch__name">{faccount.name}</div>
                <div className="accountsearch__link">[ link: {faccount.linkId} ]</div>
            </div>    
        </div>
                
        
    )
}

export default AccountSearch