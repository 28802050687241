import * as actionTypes from '../constants/withConstants';
import axios from "axios";
const apiUrl = 'https://asia-southeast2-follows-up.cloudfunctions.net/app';
//const apiUrl = 'http://localhost:5000';
//const apiUrl = 'http://192.168.1.5:5000';



export const getEventWithListsAct = (eventid) => async (dispatch) => {
    try {
        dispatch({type: actionTypes.GET_EVENT_WITH_REQUEST});

        const {data}  = await axios.get(apiUrl+`/api/eventswith/${eventid}`);
        
        dispatch({
            type: actionTypes.GET_EVENT_WITH_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: actionTypes.GET_EVENT_WITH_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};

export const addEventWithAct = (newRecord) => async (dispatch) => {
    try {
        //const { data } = await axios.post('http://192.168.1.5:5000/api/eventswith', newRecord[0]);
        //const { data } = await axios.post('http://localhost:5000/api/eventswith', newRecord[0]);
        const { data } = await axios.post('https://asia-southeast2-follows-up.cloudfunctions.net/app/api/eventswith', newRecord[0]);
        dispatch({
            type: actionTypes.ADD_EVENT_WITH,
            payload: data
        });
        console.log("Data imported Success.");
    } catch (error) {
        dispatch({
            type: actionTypes.ADD_EVENT_WITH_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
        console.error("Error with data imported[Action].");
    }
};

export const getInviteRequestListAct = (id) => async (dispatch) => {
    try {
        dispatch({type: actionTypes.GET_INVITEREQUEST_REQUEST});

        const {data}  = await axios.get(apiUrl+`/api/eventswith/${id}/inviteReq`);
        dispatch({
            type: actionTypes.GET_INVITEREQUEST_SUCCESS,
            payload: data
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.GET_INVITEREQUEST_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};

export const getInviteResponseListAct = (id) => async (dispatch) => {
    try {
        dispatch({type: actionTypes.GET_INVITERESPONSE_REQUEST});

        const {data}  = await axios.get(apiUrl+`/api/eventswith/${id}/inviteRes`);
        dispatch({
            type: actionTypes.GET_INVITERESPONSE_SUCCESS,
            payload: data
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.GET_INVITERESPONSE_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};

export const updateInviteAct = (eventid,id) => async (dispatch) => {
    try {
        const {data}  = await axios.get(apiUrl+`/api/eventswith/updaterequest/${eventid}/${id}/false`);
        dispatch({
            type: actionTypes.UPDATE_INVITEREQUEST,
            payload: data
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.UPDATE_INVITEREQUEST_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};

export const updateInviteResAct = (eventid,id,resp) => async (dispatch) => {
    try {
        const {data}  = await axios.get(apiUrl+`/api/eventswith/updateresponse/${eventid}/${id}/${resp}`);
        dispatch({
            type: actionTypes.UPDATE_INVITERESPONSE,
            payload: data
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.UPDATE_INVITERESPONSE_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};

export const updateResultAct = (eventid,id,res) => async (dispatch) => {
    try {
        const {data}  = await axios.get(apiUrl+`/api/eventswith/update/${eventid}/${id}/${res}`);
        dispatch({
            type: actionTypes.UPDATE_RESULT,
            payload: data
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.UPDATE_RESULT_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};

export const updateWithEventTypeAct = (eventid,id,typee) => async (dispatch) => {
    try {
        const {data}  = await axios.get(apiUrl+`/api/eventswith/updateeventtype/${eventid}/${id}/${typee}`);
        dispatch({
            type: actionTypes.UPDATE_WITHEVENTTYPE,
            payload: data
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.UPDATE_WITHEVENTTYPE_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};

export const deleteEventWithAct = (eid,id) => async (dispatch) => {
    try {
        const {data}  = await axios.get(apiUrl+`/api/eventswith/delete/${eid}/${id}`);
        dispatch({
            type: actionTypes.DELETE_EVENT_WITH,
            payload: data
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.DELETE_EVENT_WITH_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};

export const deleteAllEventWithAct = (eid) => async (dispatch) => {
    try {
        const {data}  = await axios.get(apiUrl+`/api/eventswith/deleteall/${eid}`);
        dispatch({
            type: actionTypes.DELETE_ALLEVENT_WITH,
            payload: data
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.DELETE_ALLEVENT_WITH_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};