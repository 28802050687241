import './EventsSide.css';
import { useState , useEffect} from 'react'; 
import { useSelector, useDispatch } from 'react-redux';
import * as dateFns from 'date-fns';

// Actions
import { getEventWithListsAct } from '../redux/actions/withActions';

//Components
import EventsWith from './EventsWith';
import EventSetting from './EventSetting';


const EventsSide = ({seevent,vAccount,curAccount,setfromNoti,selectDate,setaddedEvents,setEventToggle,setSideToggle,sideToggle,seteditedEvents,seteditedWithEvents}) => {
let currentDate = new Date();
let selectedDate = dateFns.addDays(new Date(selectDate),1);
// ******************** Redux Store variable**********************
const dispatch = useDispatch();
const EventWithLists = useSelector(state => state.getEventWithLists); 
const { loading,WithList } = EventWithLists; 

// ******************** useState ****************************
/*
const [withStateList,setwithStateList] = useState([]);
const [withResultList,setwithResultList] = useState([]);*/
const [withUserList,setwithUserList] = useState([]);
const [eSetting,seteSetting] = useState(false);
const [eventtypee, seteventtypee] = useState(0);

// ******************** Function ***************************
const requestEventWithList = (eId) => { 
    dispatch(getEventWithListsAct(eId));
    console.log("request Event With Success");
};

const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
// ******************************************************

// ************** useEffect && if condition *****************
useEffect(() => {
    requestEventWithList(seevent.eventId);
    seteventtypee(seevent.eventShareType)
    seteSetting(false);
  }, [])

  useEffect(() => {
    if (sideToggle === false) {
        seteSetting(false);
    }
    
  }, [sideToggle])

  useEffect(() => {
    if (typeof(WithList) !== 'undefined' && WithList.length > 0)
    {   
            if (seevent.eventId === WithList[0].withIdd.eventId.toString())// ได้ WithList ของ event นั้นๆมาแล้ว
            {
                const allUser = WithList.map(x => { return x.withUser[0];});
                /*
                setwithStateList(allUser.filter(xx => vAccount.userDetail._id !== xx._id));
                
                
                //setwithStateList(WithList.map(x => { return x.withUser[0];}));
                setwithResultList(WithList.map(x => { return x.withIdd;}));
                */
                
                
                const newList = WithList.map((item) => {
                    if (item.withIdd.inviteId === allUser.filter(xx => xx._id === item.withIdd.inviteId)[0]._id) {
                      const updatedItem = {
                        ...item.withIdd,
                        inviteName: allUser.filter(xxx => xxx._id === item.withIdd.inviteId)[0].name,
                        inviteImage: allUser.filter(xxx => xxx._id === item.withIdd.inviteId)[0].imageUrl,
                        inviteLink: allUser.filter(xxx => xxx._id === item.withIdd.inviteId)[0].linkId,
                      };
                      
                      return updatedItem;
                    }
             
                    return item;
                  });
                  //console.log(newList.filter(a => vAccount.userDetail._id !== a.inviteId));
                  setwithUserList(newList.filter(a => vAccount.vuserDetail._id !== a.inviteId))
                  
            }
    }
  }, [WithList])

// ******************************************************

    return (    
        
        <div className="event__all">
            <div style={{backgroundColor: `${seevent.eventColor}`}} className="event__top">
                <div id="logo"  className="event__title">
                                <span className="icon">{seevent.eventIcon}</span>
                                
                                {curAccount ===null ? 
                                    seevent.eventLocation.toLowerCase().toString().substring(0,4) === 'www.' ?
                                    <span className="event__location__badge" onClick={() => openInNewTab(`https://${seevent.eventLocation}`)}>Link</span> 
                                    :
                                    <span className="event__location__badge" onClick={() => openInNewTab(`https://google.co.th/maps/search/${seevent.eventLocation}/`)}>Map</span> 
                                : 
                                
                                ""}
                                {seevent.eventLocation.toLowerCase().toString().substring(0,4) === 'www.' ?
                                <div className="event__location" onClick={() => openInNewTab(`https://${seevent.eventLocation}`)}>{seevent.eventLocation}</div>
                                :
                                <div className="event__location" onClick={() => openInNewTab(`https://google.co.th/maps/search/${seevent.eventLocation}/`)}>{seevent.eventLocation}</div>
                                }
                                
                                
                </div>
                {curAccount === null ? ""
                :
                curAccount.userDetail._id === vAccount.vuserDetail._id ?
                    eventtypee === 1 ? <div className="event__type" onClick={()=> seteSetting(!eSetting)}>Public</div> :
                    eventtypee === 2 ? <div className="event__type" onClick={()=> seteSetting(!eSetting)}>Friend</div> : <div className="event__type" onClick={()=> seteSetting(!eSetting)}>Only Me</div>
                       
                    
                    :
                    currentDate <= selectedDate ? 
                    <>
                    <div className="event__addevent"><span className="icon" onClick={()=> setEventToggle(true) || setSideToggle(false) || setaddedEvents(seevent)}>group_add</span></div>
                    {seevent.eventLocation.toLowerCase().toString().substring(0,4) === 'www.' ?
                    <span className="event__location__badge" onClick={() => openInNewTab(`https://${seevent.eventLocation}`)}>Link</span>
                    :
                    <span className="event__location__badge" onClick={() => openInNewTab(`https://google.co.th/maps/search/${seevent.eventLocation}/`)}>Map</span>}
                    </>
                   
                    :

                    seevent.eventLocation.toLowerCase().toString().substring(0,4) === 'www.' ?
                    <span className="event__location__badge" onClick={() => openInNewTab(`https://${seevent.eventLocation}`)}>Link</span>
                    :
                    <span className="event__location__badge" onClick={() => openInNewTab(`https://google.co.th/maps/search/${seevent.eventLocation}/`)}>Map</span>
                }
                
            </div>
            {eSetting ? <EventSetting show={eSetting} {...{seevent,setfromNoti,curAccount,eventtypee,seteventtypee,seteSetting,setaddedEvents,setEventToggle,setSideToggle,sideToggle,seteditedEvents,seteditedWithEvents,withUserList}}/> : ""}
                     <div className="event__info">
                     <div className="event__time">[{new Date(seevent.eventDate).toString().substring(16,21)}-{new Date(seevent.eventEndDate).toString().substring(16,21)}]</div>
                        <div className="event__detail">{seevent.eventDetail}</div>
                        {loading ? <div className="event__loading"></div>
                        :
                        <div className="event__withamount"><span className="icon">supervisor_account</span> {withUserList.filter(ress => ress.result === true).length +1}</div>
                        }
                        
                     </div>
                
               
                <div className="event__with">
                    
                    <div className="event__pic">
                        {/*withStateList && withStateList.length == 0
                            ? ""
                            : withStateList.map(userWith => (
                                <EventsWith key={userWith._id} {...{userWith,withResultList}}/> 
                            ))
                            */}
                        {curAccount === null ? 
                        withUserList && withUserList.filter(y => y.result === true).length === 0
                        ? ""
                        : withUserList.filter(y => y.result === true).map(userWith => (
                            <EventsWith key={userWith.inviteId} {...{userWith,vAccount,curAccount}}/> 
                        ))
                        :
                        curAccount.userDetail._id !== vAccount.vuserDetail._id ?
                            withUserList && withUserList.filter(y => y.result === true).length === 0
                            ? ""
                            : withUserList.filter(y => y.result === true).map(userWith => (
                                <EventsWith key={userWith.inviteId} {...{userWith,vAccount,curAccount}}/> 
                            ))
                    
                        :
                            withUserList && withUserList.length === 0
                            ? ""
                            : withUserList.map(userWith => (
                                <EventsWith key={userWith.inviteId} {...{userWith,vAccount,curAccount}}/> 
                            ))
                        }
                        
                    </div>
                </div>
            {/*
            [{new Date(seevent.eventDate).toString().substring(16,21)}-{new Date(seevent.eventEndDate).toString().substring(16,21)}]
            <div className="event__left">
                <div className="event__title">{seevent.eventDetail}</div>
                <div className="event__location">
                    <div className="event__location2" onClick={() => openInNewTab(`https://google.co.th/maps/search/${seevent.eventLocation}/`)}>@{seevent.eventLocation}</div>
                    <div className="event__time">{new Date(seevent.eventDate).toString().substring(16,21)} to {new Date(seevent.eventEndDate).toString().substring(16,21)}</div>
                </div>
                <div className="event__with">
                    {withStateList && withStateList.length == 0
                    ? ""
                    : <div>with</div>}
                    <div className="event__pic">
                        {withStateList && withStateList.length == 0
                        ? ""
                        : withStateList.map(userWith => (
                            <EventsWith key={userWith._id} {...{userWith,withResultList}}/> 
                        ))} 
                    </div>
                </div>
                <p></p>
            </div>
                        */}
        </div>
        
    )
}

export default EventsSide