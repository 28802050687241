import './InviteWith.css';

const InviteWith = ({friendWith,invitefriendList,setinvitefriendList,setinviteSelect,allfriendList,setallfriendList}) => {
    const removeInviteFriendList = () => { 
        let arr = allfriendList;
        arr.push(friendWith);
        setallfriendList(arr);
        setinvitefriendList(invitefriendList.filter(a => a._id !== friendWith._id));
        setinviteSelect(true);
    };
    return (
        <div className="inviteuser__pic">
            <span className="invite__cancle" onClick={() => removeInviteFriendList()}>X</span>
            <img src={friendWith.imageUrl} alt="invite__pic" className="invite__pic"/>             
        </div>
                
        
    )
}

export default InviteWith