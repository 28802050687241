import * as actionTypes from '../constants/eventConstants';
import axios from "axios";
import * as dateFns from 'date-fns';
const apiUrl = 'https://asia-southeast2-follows-up.cloudfunctions.net/app';
//const apiUrl = 'http://localhost:5000';
//const apiUrl = 'http://192.168.1.5:5000';



export const getEventListsAct = (id) => async (dispatch) => {
    try {
        dispatch({type: actionTypes.GET_USER_EVENTS_REQUEST});

        const {data}  = await axios.get(apiUrl+`/api/events/${id}`);
        dispatch({
            type: actionTypes.GET_USER_EVENTS_SUCCESS,
            payload: data
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.GET_USER_EVENTS_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};

export const getEventLocationsAct = () => async (dispatch) => {
    try {
        dispatch({type: actionTypes.GET_ALL_LOCATION_REQUEST});

        const {data}  = await axios.get(apiUrl+`/api/events/getlocation/all`);
        dispatch({
            type: actionTypes.GET_ALL_LOCATION_SUCCESS,
            payload: data
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.GET_ALL_LOCATION_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};

export const addEventAct = (newRecord) => async (dispatch) => {
    try {
        //const { data } = await axios.post('http://192.168.1.5:5000/api/events', newRecord[0]);
        //const { data } = await axios.post('http://localhost:5000/api/events', newRecord[0]);
        const { data } = await axios.post('https://asia-southeast2-follows-up.cloudfunctions.net/app/api/events', newRecord[0]);
        dispatch({
            type: actionTypes.ADD_EVENT,
            payload: data
        });
        console.log("Data imported Success.");
        window.location.reload();
    } catch (error) {
        dispatch({
            type: actionTypes.ADD_EVENT_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
        console.error("Error with data imported[Action].");
    }
};

export const updateEventTypeAct = (id,type) => async (dispatch) => {
    try {
        const {data}  = await axios.get(apiUrl+`/api/events/update/${id}/${type}`);
        dispatch({
            type: actionTypes.UPDATE_EVENTTYPE,
            payload: data
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.UPDATE_EVENTTYPE_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};

export const deleteEventAct = (eid) => async (dispatch) => {
    try {
        const {data}  = await axios.get(apiUrl+`/api/events/delete/${eid}`);
        dispatch({
            type: actionTypes.DELETE_EVENT,
            payload: data
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.DELETE_EVENT_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};

export const updateEventAct = (eid,newRecord) => async (dispatch) => {
    try {
        //const { data } = await axios.put(`http://192.168.1.5:5000/api/events/${eid}`, newRecord[0]);
        //const { data } = await axios.put(`http://localhost:5000/api/events/${eid}`, newRecord[0]);
        const { data } = await axios.put(`https://asia-southeast2-follows-up.cloudfunctions.net/app/api/events/${eid}`, newRecord[0]);
        dispatch({
            type: actionTypes.UPDATE_EVENT,
            payload: data
        });
        console.log("Update Event Success.");
        window.location.reload();
    } catch (error) {
        dispatch({
            type: actionTypes.UPDATE_EVENT_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
        console.error("Error with event update[Action].");
    }
};

export const getHotEventListsAct = () => async (dispatch) => {
    try {
        dispatch({type: actionTypes.GET_HOTEVENTS_REQUEST});
        const startDate = dateFns.startOfWeek(new Date());
        const endString = `T${startDate.getUTCHours().toString()}:00:00.000Z`;
        let currentTime = `${new Date().toISOString()}`;
        let endTime = ""
        if ((new Date().getUTCHours()) >= 17 && (new Date().getUTCHours()) < 24) {
            endTime = `${dateFns.addDays(new Date(),1).toISOString().substring(0,10)}${endString}`;
        } else {
            endTime = `${new Date().toISOString().substring(0,10)}${endString}`;
        }
        const {data}  = await axios.get(apiUrl+`/api/events/phot/${currentTime}/${endTime}`);
        dispatch({
            type: actionTypes.GET_HOTEVENTS_SUCCESS,
            payload: data
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.GET_HOTEVENTS_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};

export const updateEventAddedAct = (id) => async (dispatch) => {
    try {
        const {data}  = await axios.get(apiUrl+`/api/events/updateeventadd/${id}`);
        dispatch({
            type: actionTypes.UPDATE_EVENTADDED,
            payload: data
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.UPDATE_EVENTADDED_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};

export const updateEventPromoteAct = (id,type) => async (dispatch) => {
    try {
        const {data}  = await axios.get(apiUrl+`/api/events/updatepromote/${id}/${type}`);
        dispatch({
            type: actionTypes.UPDATE_EVENTPROMOTE,
            payload: data
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.UPDATE_EVENTPROMOTE_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};