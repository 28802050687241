import * as actionTypes from '../constants/userConstants';
import axios from "axios";
const apiUrl = 'https://asia-southeast2-follows-up.cloudfunctions.net/app';
//const apiUrl = 'http://localhost:5000';
//const apiUrl = 'http://192.168.1.5:5000';

export const getUserDetailsByEmail = (email) => async (dispatch) => {
    try {
        dispatch({type: actionTypes.GET_USER_DETAILS_REQUEST});

        const {data}  = await axios.get(apiUrl+`/api/users/get/${email}`);
        dispatch({
            type: actionTypes.GET_USER_DETAILS_SUCCESS,
            payload: data[0]
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.GET_USER_DETAILS_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};

export const getUserDetailsById = (id) => async (dispatch) => {
    try {
        dispatch({type: actionTypes.GET_USER_DETAILS_REQUEST});

        const {data}  = await axios.get(apiUrl+`/api/users/getid/${id}`);
        dispatch({
            type: actionTypes.GET_USER_DETAILS_SUCCESS,
            payload: data[0]
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.GET_USER_DETAILS_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};

export const getUserDetailsByLinkId = (linkId) => async (dispatch) => {
    try {
        dispatch({type: actionTypes.GET_VUSER_DETAILS_REQUEST});

        const {data}  = await axios.get(apiUrl+`/api/users${linkId}`);
        dispatch({
            type: actionTypes.GET_VUSER_DETAILS_SUCCESS,
            payload: data[0]
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.GET_VUSER_DETAILS_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};

export const removeUserDetails = (email) => async (dispatch) => {
        dispatch({
            type: actionTypes.GET_USER_DETAILS_RESET,
        });
        
    
};

export const addUserAct = (newRecord) => async (dispatch) => {
    try {
        //const { data } = await axios.post('http://192.168.1.5:5000/api/users', newRecord[0]);
        //const { data } = await axios.post('http://localhost:5000/api/users', newRecord[0]);
        const { data } = await axios.post('https://asia-southeast2-follows-up.cloudfunctions.net/app/api/users', newRecord[0]);
        dispatch({
            type: actionTypes.ADD_USER,
            payload: data
        });
        console.log("Data imported Success.");
        window.location.reload();
    } catch (error) {
        dispatch({
            type: actionTypes.ADD_USER_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
        console.error("Error with data imported[Action].");
    }
};

export const getAllLinksAct = () => async (dispatch) => {
    try {
        dispatch({type: actionTypes.GET_ALL_LINK_REQUEST});

        const {data}  = await axios.get(apiUrl+`/api/users/getlink/all`);
        dispatch({
            type: actionTypes.GET_ALL_LINK_SUCCESS,
            payload: data
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.GET_ALL_LINK_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};

export const getAllUsersAct = () => async (dispatch) => {
    try {
        dispatch({type: actionTypes.GET_ALL_USERS_REQUEST});
        const {data}  = await axios.get(apiUrl+`/api/users`);
        dispatch({
            type: actionTypes.GET_ALL_USERS_SUCCESS,
            payload: data
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.GET_ALL_USERS_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};

export const updateUserAct = (id,newRecord) => async (dispatch) => {
    try {
        //const { data } = await axios.put(`http://192.168.1.5:5000/api/users/${id}`, newRecord[0]);
        //const { data } = await axios.put(`http://localhost:5000/api/users/${id}`, newRecord[0]);
        const { data } = await axios.put(`https://asia-southeast2-follows-up.cloudfunctions.net/app/api/users/${id}`, newRecord[0]);
        dispatch({
            type: actionTypes.UPDATE_USER,
            payload: data
        });
        console.log("Update Data Success.");
        window.location.reload();
    } catch (error) {
        dispatch({
            type: actionTypes.UPDATE_USER_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
        console.error("Error with data update[Action].");
    }
};

export const updateUserTypeAct = (id,type) => async (dispatch) => {
    try {
        const {data}  = await axios.get(apiUrl+`/api/users/updatesharetype/${id}/${type}`);
        dispatch({
            type: actionTypes.UPDATE_USERTYPE,
            payload: data
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.UPDATE_USERTYPE_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};

export const updateUserKeyAct = (id,amo) => async (dispatch) => {
    try {
        const {data}  = await axios.get(apiUrl+`/api/users/updatepkey/${id}/${amo}`);
        dispatch({
            type: actionTypes.UPDATE_USERKEY,
            payload: data
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.UPDATE_USERKEY_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};

export const updateUserLastAct = (id) => async (dispatch) => {
    try {
        const {data}  = await axios.get(apiUrl+`/api/users/updatelastact/${id}`);
        dispatch({
            type: actionTypes.UPDATE_USERLAST,
            payload: data
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.UPDATE_USERLAST_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};