import * as actionTypes from '../constants/eventConstants';

export const getEventsReducers = (state = { EventList: [] }, action) => {
    switch(action.type) {
        case actionTypes.GET_USER_EVENTS_REQUEST:
            return {
                loading: true,
            }
        case actionTypes.GET_USER_EVENTS_SUCCESS:
            return {
                loading: false,
                EventList: action.payload
            }
        case actionTypes.GET_USER_EVENTS_FAIL:
            return {
                loading: false,
                error: action.payload
            } 
        default:
            return state;
    }
}
export const getEventAllLocationsReducers = (state = { allLocations: []}, action) => {
    switch(action.type) {
        case actionTypes.GET_ALL_LOCATION_REQUEST:
            return {
                locationloading: true,
            }
        case actionTypes.GET_ALL_LOCATION_SUCCESS:
            return {
                locationloading: false,
                allLocations:action.payload
            }
        case actionTypes.GET_ALL_LOCATION_FAIL:
            return {
                locationloading: false,
                error: action.payload
            } 
        default:
            return state;
    }
}

export const addEventReducers = (state = { eventRecord: [] }, action) => {
    switch(action.type) {
        case actionTypes.ADD_EVENT:
                return { 
                    ...state,
                    eventRecord : [...state.eventRecord,action.payload]
                }
        case actionTypes.ADD_EVENT_FAIL:
            return { 
                error: action.payload
            }
        default:
            return state;
    }
}

export const updateEventTypeReducers = (state = { eventLog: {}}, action) => {
    switch(action.type) {
        case actionTypes.UPDATE_EVENTTYPE:
            return {
                eventLog:action.payload
            }
        case actionTypes.UPDATE_EVENTTYPE_FAIL:
            return { 
                error: action.payload
            }
        default:
            return state;
    }
}

export const deleteEventReducers = (state = { deleteEventRecord: {}}, action) => {
    switch(action.type) {
        case actionTypes.DELETE_EVENT:
            return {
                deleteEventRecord:action.payload
            }
        case actionTypes.DELETE_EVENT_FAIL:
            return { 
                error: action.payload
            }
        default:
            return state;
    }
}

export const updateEventReducers = (state = { eupdatedRecord: [] }, action) => {
    switch(action.type) {
        case actionTypes.UPDATE_EVENT:
                return { 
                    ...state,
                    eupdatedRecord : state.eupdatedRecord.map(x => x.eventId === action.payload.eventId ? action.payload : x)
                }
        case actionTypes.UPDATE_EVENT_FAIL:
            return { 
                error: action.payload
            }
        default:
            return state;
    }
}

export const getHotEventsReducers = (state = { HotEventList: [] }, action) => {
    switch(action.type) {
        case actionTypes.GET_HOTEVENTS_REQUEST:
            return {
                loading: true,
            }
        case actionTypes.GET_HOTEVENTS_SUCCESS:
            return {
                loading: false,
                HotEventList: action.payload
            }
        case actionTypes.GET_HOTEVENTS_FAIL:
            return {
                loading: false,
                error: action.payload
            } 
        default:
            return state;
    }
}

export const updateEventAddedReducers = (state = { eventAddLog: {}}, action) => {
    switch(action.type) {
        case actionTypes.UPDATE_EVENTADDED:
            return {
                eventAddLog:action.payload
            }
        case actionTypes.UPDATE_EVENTADDED_FAIL:
            return { 
                error: action.payload
            }
        default:
            return state;
    }
}

export const updateEventPromoteReducers = (state = { eventProLog: {}}, action) => {
    switch(action.type) {
        case actionTypes.UPDATE_EVENTPROMOTE:
            return {
                eventProLog:action.payload
            }
        case actionTypes.UPDATE_EVENTPROMOTE_FAIL:
            return { 
                error: action.payload
            }
        default:
            return state;
    }
}