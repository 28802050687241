import './Main.css'; 
import { useState , useEffect} from 'react'; 
import { Button } from "@material-ui/core";
const Main = ({show,setMainToggle,loclick,mobileSize}) => {
  
    /*********************** Function*********** */
    /*
    window.onscroll = function() {scrollFunctionn()};

    function scrollFunctionn() {
      //console.log(document.documentElement.scrollLeft);
      //console.log(document.body.clientWidth);
      if (document.documentElement.scrollLeft > 150) {
        leftFunction();
      } 
    }
    
    
    function leftFunction() {
      //document.body.scrollTop = 0; // For Safari
      document.body.scrollLeft = 0; // For Safari
      //document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      document.documentElement.scrollLeft = 0; // For Chrome, Firefox, IE and Opera
    }
    
    //window.onscroll = function() {console.log(window.screen.width* window.devicePixelRatio)};*/
    /*
    useEffect(() => {
      if (window.matchMedia('(max-width: 960px)').matches) { 
        setmobileSize(true);
      }
    }, [])
    */
    
    const mainClass = ["maindrawer"]; 
    if (show) {
        mainClass.push("show");
    }


    return <div className={mainClass.join(" ")}>
    
    <div className = "mainscreen__main">
          
    <img src="https://firebasestorage.googleapis.com/v0/b/follows-up.appspot.com/o/bg%2Fhomescreen.JPG?alt=media&token=874ee164-887b-4094-b03c-bd32e06f3f58" alt="mainscreen__pic" className="mainscreen__pic"/>
    <h2 className = "mainscreen__message">" KEEP CLAM AND FOLLOW YOUR's PLAN "</h2>
    
    
    <h2 className = "mainscreen__message__dream">DREAM</h2>
    <h2 className = "mainscreen__message__love">LOVE</h2>
    <h2 className = "mainscreen__message__like">FAVOUR</h2>
    <div className = "mainscreen__box">
      {mobileSize ?
      <div className="mainscreen__area1">
        <img src="https://firebasestorage.googleapis.com/v0/b/follows-up.appspot.com/o/icon%2Ffollowsup.png?alt=media&token=54fb4bfb-da8b-4afc-ae9a-8962bfde1d36" alt="mainscreen__logo" className="mainscreen__logo"/>
        <h1>Hey, Follow's up ?</h1>
        <h2>Try Our Application </h2>
        <h2>Easy By 3-Steps </h2>
        <h3>Search favours's name.</h3>
        <h3>See their events.</h3>
        <h3>Click on Location.</h3>
        <h5>Now !!</h5>
        <h5>All Features Avaliables</h5>
        <h6>Create Your's Link</h6>
        <div className="mainscreen__login">
        <Button variant="contained" size="large" color="primary" className= "mainscreen_login_btn" onClick={loclick} >
                GET STARTED
        </Button>
        </div>
        
        <div className="mainscreen__mobile">
          <div><img src="https://firebasestorage.googleapis.com/v0/b/follows-up.appspot.com/o/icon%2Fappstore.png?alt=media&token=39e4966f-8d99-47ea-9d9e-db12e2378f6a" alt="mainscreen__appstore" className="mainscreen__appstore"/></div>
          <div><img src="https://firebasestorage.googleapis.com/v0/b/follows-up.appspot.com/o/icon%2Fgooglestore.png?alt=media&token=3e9009cd-f76a-4cb3-a3a7-683c3b7b18ec" alt="mainscreen__googlestore" className="mainscreen__googlestore"/></div>
        </div>
        <h4>Coming Soon !</h4>
      </div>
      :
      <div className="mainscreen__area1">
                <img src="https://firebasestorage.googleapis.com/v0/b/follows-up.appspot.com/o/icon%2Ffollowsup.png?alt=media&token=54fb4bfb-da8b-4afc-ae9a-8962bfde1d36" alt="mainscreen__logo" className="mainscreen__logo"/>
                <h1>Hey, Follow's up ?</h1>
                <h2>Try Our Application </h2>
                <h2>Easy By 3-Steps </h2>
                <h3>- Follow your's favours.</h3>
                <h3>- See their events.</h3>
                <h3>- Invite your friends.</h3>
        </div>
      }
        
        <div className="mainscreen__area2">
                
                <h2>3-Steps for Register</h2>
                <h3>1. Press Login.</h3>
                <h3>2. Setting your account.</h3>
                <h3>3. Get your Calendar Link !</h3>
        </div>
            
    </div>
          {/*
          <>
          <div className = "mainscreen__top">
            <div className = "mainscreen__left">
            <img src="https://firebasestorage.googleapis.com/v0/b/follows-up.appspot.com/o/bg%2Fhomescreen.JPG?alt=media&token=874ee164-887b-4094-b03c-bd32e06f3f58" alt="mainscreen__pic" className="mainscreen__pic"/>
            
            </div>
              `<div className = "mainscreen__right">
              <div>aaa</div>
              </div>`
          </div>  
          <div className = "mainscreen__foot">
            <div>aaaa</div>
            <div>aaaa</div>
            <div>aaaa</div>
          </div>
         </>
          */}
          
          
          
         
        
          
        </div>
    
    </div>;
};

export default Main

