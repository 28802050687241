export const GET_USER_EVENTS_REQUEST = "GET_USER_EVENTS_REQUEST";
export const GET_USER_EVENTS_SUCCESS = "GET_USER_EVENTS_SUCCESS";
export const GET_USER_EVENTS_FAIL = "GET_USER_EVENTS_FAIL";

export const GET_ALL_LOCATION_REQUEST = "GET_ALL_LOCATION_REQUEST";
export const GET_ALL_LOCATION_SUCCESS = "GET_ALL_LOCATION_SUCCESS";
export const GET_ALL_LOCATION_FAIL = "GET_ALL_LOCATION_FAIL";

export const GET_HOTEVENTS_REQUEST = "GET_HOTEVENTS_REQUEST";
export const GET_HOTEVENTS_SUCCESS = "GET_HOTEVENTS_SUCCESS";
export const GET_HOTEVENTS_FAIL = "GET_HOTEVENTS_FAIL";

export const ADD_EVENT = "ADD_EVENT";
export const ADD_EVENT_FAIL = "ADD_EVENT_FAIL";

export const UPDATE_EVENTTYPE = "UPDATE_EVENTTYPE";
export const UPDATE_EVENTTYPE_FAIL = "UPDATE_EVENTTYPE_FAIL";

export const DELETE_EVENT = "DELETE_EVENT";
export const DELETE_EVENT_FAIL = "DELETE_EVENT_FAIL";

export const UPDATE_EVENT = "UPDATE_EVENT";
export const UPDATE_EVENT_FAIL = "UPDATE_EVENT_FAIL";

export const UPDATE_EVENTADDED = "UPDATE_EVENTADDED";
export const UPDATE_EVENTADDED_FAIL = "UPDATE_EVENTADDED_FAIL";

export const UPDATE_EVENTPROMOTE = "UPDATE_EVENTPROMOTE";
export const UPDATE_EVENTPROMOTE_FAIL = "UPDATE_EVENTPROMOTE_FAIL";


