import * as actionTypes from '../constants/userConstants';


export const getUserDetailsReducers = (state = { userDetail: {}}, action) => {
    switch(action.type) {
        case actionTypes.GET_USER_DETAILS_REQUEST:
            return {
                loading: true,
            }
        case actionTypes.GET_USER_DETAILS_SUCCESS:
            return {
                loading: false,
                userDetail:action.payload
            }
        case actionTypes.GET_USER_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload
            } 
        case actionTypes.GET_USER_DETAILS_RESET:
            return {
                userDetail: {}
            } 
        default:
            return state;
    }
}


export const getViewUserDetailsReducers = (state = { vuserDetail: {}}, action) => {
    switch(action.type) {
        case actionTypes.GET_VUSER_DETAILS_REQUEST:
            return {
                viewloading: true,
            }
        case actionTypes.GET_VUSER_DETAILS_SUCCESS:
            return {
                viewloading: false,
                vuserDetail:action.payload
            }
        case actionTypes.GET_VUSER_DETAILS_FAIL:
            return {
                viewloading: false,
                error: action.payload
            }
        default:
            return state;
    }
}

export const addUserReducers = (state = { userRecord: [] }, action) => {
    switch(action.type) {
        case actionTypes.ADD_USER:
                return { 
                    ...state,
                    userRecord : [...state.userRecord,action.payload]
                }
        case actionTypes.ADD_USER_FAIL:
            return { 
                error: action.payload
            }
        default:
            return state;
    }
}

export const getUserAllLinksReducers = (state = { allLinks: []}, action) => {
    switch(action.type) {
        case actionTypes.GET_ALL_LINK_REQUEST:
            return {
                linkloading: true,
            }
        case actionTypes.GET_ALL_LINK_SUCCESS:
            return {
                linkloading: false,
                allLinks:action.payload
            }
        case actionTypes.GET_ALL_LINK_FAIL:
            return {
                linkloading: false,
                error: action.payload
            } 
        default:
            return state;
    }
}

export const getAllUsersReducers = (state = { allUsers: []}, action) => {
    switch(action.type) {
        case actionTypes.GET_ALL_USERS_REQUEST:
            return {
                alluserloading: true,
            }
        case actionTypes.GET_ALL_USERS_SUCCESS:
            return {
                alluserloading: false,
                allUsers:action.payload
            }
        case actionTypes.GET_ALL_USERS_FAIL:
            return {
                alluserloading: false,
                error: action.payload
            } 
        default:
            return state;
    }
}

export const updateUserReducers = (state = { updatedRecord: [] }, action) => {
    switch(action.type) {
        case actionTypes.UPDATE_USER:
                return { 
                    ...state,
                    updatedRecord : state.updatedRecord.map(x => x._id === action.payload._id ? action.payload : x)
                }
        case actionTypes.UPDATE_USER_FAIL:
            return { 
                error: action.payload
            }
        default:
            return state;
    }
}

export const updateUserTypeReducers = (state = { userTypeLog: {}}, action) => {
    switch(action.type) {
        case actionTypes.UPDATE_USERTYPE:
            return {
                userTypeLog:action.payload
            }
        case actionTypes.UPDATE_USERTYPE_FAIL:
            return { 
                error: action.payload
            }
        default:
            return state;
    }
}

export const updateUserKeyReducers = (state = { userKeyLog: {}}, action) => {
    switch(action.type) {
        case actionTypes.UPDATE_USERKEY:
            return {
                userKeyLog:action.payload
            }
        case actionTypes.UPDATE_USERKEY_FAIL:
            return { 
                error: action.payload
            }
        default:
            return state;
    }
}

export const updateUserLastReducers = (state = { userLastLog: {}}, action) => {
    switch(action.type) {
        case actionTypes.UPDATE_USERLAST:
            return {
                userLastLog:action.payload
            }
        case actionTypes.UPDATE_USERLAST_FAIL:
            return { 
                error: action.payload
            }
        default:
            return state;
    }
}