import React from 'react';
import './Register.css';
import { Button, TextField } from "@material-ui/core";
import { useState , useEffect} from 'react'; 
import { useSelector, useDispatch } from 'react-redux';
import { storage } from '../firebase';
import ButterToast , { Cinnamon } from "butter-toast";
import { Block } from "@material-ui/icons";
import firebase from '../firebase';
import {useHistory} from 'react-router-dom';


// Action
import { addUserAct,getAllLinksAct,updateUserAct } from '../redux/actions/userActions';


const Register = ({show,eclick,session,curAccount,setSession}) => {
    let history = useHistory();
    // ******************** Redux Store variable**********************
    const dispatch = useDispatch();
    const addUser = useSelector(state => state.addUsers); 
    const { userRecord } = addUser;  
    const getUserAllLink = useSelector(state => state.getUserAllLinks); 
    const { allLinks } = getUserAllLink;  
    const updateUser = useSelector(state => state.updateUsers); 
    const { updatedRecord } = updateUser;  


    const [registerName, setregisterName] = useState("")
    const [checkResult, setcheckResult] = useState(true)
    const [verifyEmail, setverifyEmail] = useState(false)
    const [registerEmail, setregisterEmail] = useState("")
    const [registerLink, setregisterLink] = useState("")
    const [image, setImage] = useState(null);
    const [url, setUrl] = useState("");
    const [imgprogress, setimgprogress] = useState(0);

    useEffect(()=>{ 
        if (session.isLoggedIn)
        {
            if (curAccount === null) {
                setregisterName(session.currentUser.displayName);
                setregisterEmail(session.currentUser.email);
                dispatch(getAllLinksAct());
                if(session.currentUser.emailVerified || session.currentUser.providerData[0].providerId === "facebook.com") {
                    setverifyEmail(true)
                }
                
            } 
            
        }
    },[session.isLoggedIn]);

    useEffect(()=>{ 
        if (session.isLoggedIn)
        {
            if (curAccount !== null) {
                setregisterName(curAccount.userDetail.name);
                setregisterEmail(curAccount.userDetail.email);
                setregisterLink(curAccount.userDetail.linkId);
                setUrl(curAccount.userDetail.imageUrl);
            } 
        }
    },[curAccount]);

    useEffect(()=>{ 
        if (curAccount === null){
            if (image !== null){
                //console.log(image);
                const uploadTask = storage.ref(`images/${image.name}`).put(image);
                
                uploadTask.on(
                "state_changed",
                snapshot => {},
                error => {
                  console.log(error);
                },
                () => {
                  storage
                    .ref("images")
                    .child(image.name)
                    .getDownloadURL()
                    .then(url => {
                      setUrl(url);
                    });
                }
              );
            }
        } else {
                if(url.substring(69+9,url.search(/[?]/g)) !== "unknow__pic.JPG")
                {
                    storage.ref(`images/${url.substring(69+9,url.search(/[?]/g))}`).delete();
                }
                const uploadTask = storage.ref(`images/${curAccount.userDetail.linkId}`).put(image);
                
                uploadTask.on(
                "state_changed",
                snapshot => {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setimgprogress(progress);
                },
                error => {
                  console.log(error);
                },
                () => {
                  storage
                    .ref("images")
                    .child(curAccount.userDetail.linkId)
                    .getDownloadURL()
                    .then(url => {
                      setUrl(url);

                      let registerImage = url;
                      const updatedata = [
                          {
                              name: curAccount.userDetail.name,
                              linkId: curAccount.userDetail.linkId,
                              email: curAccount.userDetail.email,
                              imageUrl : registerImage,
                              shareType: curAccount.userDetail.shareType,
                              pKey: curAccount.userDetail.pKey,
                              lastAction: curAccount.userDetail.lastAction,
                          },
                      ];
                      dispatch(updateUserAct(curAccount.userDetail._id,updatedata));




                    });
                }
              );
            
            
        }
        
        
    },[image]);

    const hiddenFileInput = React.useRef(null);
    const handleFile = e => {
        hiddenFileInput.current.click();
      };

      const handleCheckLink = (textt) => {
        setregisterLink(textt.toLowerCase());
        //console.log(allLinks.filter(li => li === textt).length)
        if (allLinks.filter(li => li === textt).length === 0) {
            setcheckResult(true)
        } else {
            setcheckResult(false)
        }
        
      };


    const handleChange = e => {
        if (e.target.files[0]) {
            if (e.target.files[0].size < 5000000) {
                if (image !== null) {
                    if (e.target.files[0] !== image){
                        storage.ref(`images/${image.name}`).delete();
                        setImage(e.target.files[0]);
                    }
                } else {
                    setImage(e.target.files[0]);
                }
            } else {
                ButterToast.raise({
                    content:<Cinnamon.Crisp title="Image Size over 5 MB"
                        content="Please re-size your picture"
                        scheme = {Cinnamon.Crisp.SCHEME_RED}
                        icon={<Block />}
                    />})
            }
            
            
        }
    };

    const registerBtnHandler = () => {
        if (registerLink === "") {
            ButterToast.raise({
                content:<Cinnamon.Crisp title="Can not Create Account"
                    content="Please Input your link"
                    scheme = {Cinnamon.Crisp.SCHEME_RED}
                    icon={<Block />}
                />})
        } else {
            if (checkResult) {
                let registerImage = url;
                if (url === "") {
                    registerImage = "https://firebasestorage.googleapis.com/v0/b/follows-up.appspot.com/o/images%2Funknow__pic.JPG?alt=media&token=259b325e-aef0-4f41-9408-aedf24fb1813"
                }
                const userdata = [
                    {
                        name: registerName,
                        linkId: registerLink,
                        email: registerEmail,
                        imageUrl : registerImage,
                        shareType: 1,
                        pKey: 1,
                        lastAction: new Date(),
                    },
                ];
                dispatch(addUserAct(userdata));
            } else {
                ButterToast.raise({
                    content:<Cinnamon.Crisp title="Your Link already use"
                        content="Please change your link"
                        scheme = {Cinnamon.Crisp.SCHEME_RED}
                        icon={<Block />}
                    />})
            }
        }
    }

    const settingBtnHandler = () => {
        let registerImage = url;
        const updatedata = [
            {
                name: registerName,
                linkId: registerLink,
                email: registerEmail,
                imageUrl : registerImage,
                shareType: curAccount.userDetail.shareType,
                pKey: curAccount.userDetail.pKey,
                lastAction: curAccount.userDetail.lastAction,
            },
        ];
        dispatch(updateUserAct(curAccount.userDetail._id,updatedata));
    }
    const handleLogout = () => {
        firebase.auth().signOut().then(result => {
            setSession ({
                isLoggedIn: false,
                currentUser: null,
              });
              history.push("/");
              window.location.reload();
        });
        
    }

    const registerClass = ["register"]; 
    if (show) {
        registerClass.push("show");
    }
    return <div className={registerClass.join(" ")}>
    {curAccount === null ?
        verifyEmail ?
            <div className="register__title">Register Account</div>
        : <div className="register__title">Please Verify Your Email.</div>
    :
    <div className="register__title">Setting Account</div>
    }
    
    
    <div className={verifyEmail ? "register__main" : "register__main2"} >

    {session.isLoggedIn ? 
        verifyEmail ?
        <>
            <div className="register__area1">
                <input type="file" 
                        ref={hiddenFileInput}
                        onChange={handleChange}
                        style={{display:'none'}} />
                <span className="register__pic__badge" onClick={handleFile}>Upload Picture</span>
                {url === "" ? 
                    <img src="https://firebasestorage.googleapis.com/v0/b/follows-up.appspot.com/o/images%2Funknow__pic.JPG?alt=media&token=259b325e-aef0-4f41-9408-aedf24fb1813" alt="register__pic" className="register__pic"/>
                :
                    <img src={url} alt="register__pic" className="register__pic"/>
                }
                
            </div>
            <div className="register__area2">
                <div className="register__name">
                    <TextField
                                name="register__name"
                                variant="outlined"
                                label="Name"
                                fullWidth   
                                value= {registerName}
                                onChange={e => setregisterName(e.target.value)}
                                inputProps={{ maxLength: 17 }}
                    />
                </div>
            </div>
            <div className="register__area3">
                <div className="register__email">
                    <TextField
                                disabled
                                name="register__email"
                                variant="filled"
                                label="Email"
                                fullWidth
                                value= {registerEmail}
                                onChange={e => setregisterEmail(e.target.value)}   
                    />
                </div>
            </div>
            <div className="register__area4">
                <div className="register__link">
                    {curAccount === null ?
                    <TextField
                        required
                        name="register__link"
                        variant="outlined"
                        label="Link"
                        fullWidth
                        value= {registerLink.toLowerCase()}
                        onChange={e => handleCheckLink(e.target.value)} 
                        inputProps={{ maxLength: 15 }}  
                    />
                    :
                    <TextField
                        disabled
                        name="register__link"
                        variant="filled"
                        label="Link"
                        fullWidth
                        value= {registerLink.toLowerCase()}
                        onChange={e => handleCheckLink(e.target.value)}   
                    />
                    }
                    
                </div>
            </div>
            <div className="register__area5">
                <div className="register__title__ex">Your Link:</div>
                {checkResult
                ?
                    <div className="register__example__ok">www.follows-up.com/{registerLink.toLowerCase()}</div>
                :
                    <div className="register__example__ng">www.follows-up.com/{registerLink.toLowerCase()}</div>
                }
                
            </div>
            <div className="register__area6">
                {imgprogress === 0 || imgprogress === 100
                ?
                <div className="register__btn">
                    {curAccount === null ?
                    <Button variant="contained" 
                    color="primary"
                    type="submit"
                    className="aaaaa"
                    onClick={() => registerBtnHandler()}
                    >Register</Button>
                    :
                    <Button variant="contained" 
                            color="primary"
                            type="submit"
                            className="aaaaa"
                            onClick={() => settingBtnHandler()}
                            >Confirm</Button>
                    }
                    
                </div>
                :
                <div className="register__btn">
                    <Button variant="contained" 
                    type="submit"
                    className="aaaaa"
                    disabled
                    >Uploading</Button>
                </div>
                }
                
            </div>
        </>
        : 
        <div className="register__area6">
            <h1>How to verify</h1>
            <h2>Click the link that send to your Email.</h2>
            <h2>Email: {session.currentUser.email}</h2>
            <h2>Refresh this page</h2>
            <div className="register__btn">
                <Button variant="contained" 
                color="primary"
                type="submit"
                className="aaaaa"
                onClick={handleLogout}
                >Go Back / Re-Login</Button>
            </div>
        </div>
    :
    "" }
    </div>
    
    </div>;
};

export default Register

