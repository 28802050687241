import { createStore, combineReducers, applyMiddleware } from 'redux'; 
import thunk from "redux-thunk"; 
import { composeWithDevTools } from 'redux-devtools-extension'; 

//Reducers
import { getUserDetailsReducers,getViewUserDetailsReducers,addUserReducers,getUserAllLinksReducers,updateUserReducers,getAllUsersReducers,updateUserTypeReducers,updateUserKeyReducers,updateUserLastReducers } from './reducers/userReducers';
import { getFollowingReducers,getFollowerReducers,getFriendReducers,checkFavouriteReducers,checkFriendReducers,
        addFollowReducers,unFollowReducers,getCurrentFollowingReducers,getFollowRequestReducers,getFollowResponseReducers,getFollowAcknowledgeReducers,
        checkRelationReducers,updateRequestReducers,updateFavouriteReducers,updateFriendReducers,updateResponsefReducers,updateAcknowledgeReducers } from './reducers/followReducer';
import { getEventsReducers,addEventReducers,updateEventTypeReducers,deleteEventReducers,updateEventReducers,getEventAllLocationsReducers,getHotEventsReducers,updateEventAddedReducers,updateEventPromoteReducers } from './reducers/eventReducers';
import { getEventWithReducers,addEventWithReducers,getInviteRequestReducers,getInviteResponseReducers,updateInviteReducers,updateInviteResReducers,updateResultReducers,updateWithEventTypeReducers,deleteEventWithReducers,deleteAllEventWithReducers } from './reducers/withReducers';



const reducer = combineReducers({
    //รอจาก reducers
    addUsers:addUserReducers,
    updateUsers:updateUserReducers,
    getUserDetails: getUserDetailsReducers,
    getViewUserDetails:getViewUserDetailsReducers,
    getAllUsers:getAllUsersReducers,
    getUserAllLinks:getUserAllLinksReducers,
    getFollowingLists: getFollowingReducers,
    getCurrentFollowingLists:getCurrentFollowingReducers,
    getFollowerLists: getFollowerReducers,
    getFriendLists:getFriendReducers,
    checkFavourites: checkFavouriteReducers,
    checkFriends: checkFriendReducers,
    addFollows: addFollowReducers,
    unFollows: unFollowReducers,
    getEventLists: getEventsReducers,
    getEventWithLists: getEventWithReducers,
    addEvents:addEventReducers,
    addEventWiths:addEventWithReducers,
    getFollowRequestLists:getFollowRequestReducers,
    checkRelations: checkRelationReducers,
    updateRequests:updateRequestReducers,
    updateFavourites:updateFavouriteReducers,
    updateFriends:updateFriendReducers,
    getInviteRequestLists:getInviteRequestReducers,
    getInviteResponseLists:getInviteResponseReducers,
    getFollowAcknowledgeLists:getFollowAcknowledgeReducers,
    updateInvites:updateInviteReducers,
    updateInviteRess:updateInviteResReducers,
    updateResults:updateResultReducers,
    updateEventTypes:updateEventTypeReducers,
    updateWithEventTypes:updateWithEventTypeReducers,
    deleteEvents:deleteEventReducers,
    deleteEventWiths:deleteEventWithReducers,
    deleteAllEventWiths:deleteAllEventWithReducers,
    updateEvents:updateEventReducers,
    updateUserTypes:updateUserTypeReducers,
    getFollowResponseLists:getFollowResponseReducers,
    updateResponses:updateResponsefReducers,
    updateAcknowledges:updateAcknowledgeReducers,
    getEventAllLocations:getEventAllLocationsReducers,
    getHotEvents:getHotEventsReducers,
    updateEventAddeds:updateEventAddedReducers,
    updateEventPromotes:updateEventPromoteReducers,
    updateUserKeys:updateUserKeyReducers,
    updateUserLasts:updateUserLastReducers,
    
})

const middleware = [thunk];

export const store = createStore(
    reducer,
    composeWithDevTools(applyMiddleware(...middleware))
)