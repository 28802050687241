import React from 'react';
import './UserScreen.css';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import { useState , useEffect} from 'react'; 
import { useSelector, useDispatch } from 'react-redux';
import firebase from '../firebase';
import {useHistory,useRouteMatch, useLocation} from 'react-router-dom';
import ButterToast , { Cinnamon } from "butter-toast";
import { CheckCircle } from "@material-ui/icons";




// Actions
import { getFollowerList,getFollowingList,checkFavouriteAct,checkFriendAct,addToFollowAct,
          unFollowAct,getCurrentFollowingListAct,getFollowRequestListAct,checkRelationAct,
          updateFavouriteAct,updateFriendAct,getFollowResponseListAct,getFollowAcknowledgeListAct } from '../redux/actions/followActions';
import { getUserDetailsByLinkId,getUserDetailsByEmail,updateUserLastAct } from '../redux/actions/userActions';
import { getEventListsAct,getHotEventListsAct } from '../redux/actions/eventActions';
import { getInviteRequestListAct,getInviteResponseListAct } from '../redux/actions/withActions';

//Components
import Schedule from '../components/Schedule';
import EventsActive from '../components/EventsActive';
import AccountSetting from '../components/AccountSetting';


const useStyles = makeStyles((theme) => ({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: "100%",
      marginBottom: "10px",
      
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
      fontSize: "100%",
    },
    iconButton: {
      padding: 1,
      marginRight: "5px",
      fontSize: "125%",
    },
    divider: {
      height: 28,
      margin: 4,
    },
  }));

  



const UserScreen = ({currentUser,isLoggedIn, setSideToggle, setSession,setselectEvents,setselectDate,setCurAccount,vAccount,curAccount,setvAccount,setrequestorList,
                    setNotiToggle,fromNoti,setfromNoti,setrequestorStatus,setinvitorList,setinvitorEvent,setvfollowerMember,vfollowerMember,setvfollowingMember,setfromFollow,
                    setsearchToggle,setregisterToggle,setresfollowList,setacknowList,setinvitorResEvent,mobileSize,loginToggle,setLoginToggle,setEventToggle,setaddedEvents,swipeToggle}) => {


// ******************** Style *******************************
    const classes = useStyles();
// **********************************************************

// ******************** URL variables ***********************
    let history = useHistory();
    let location = useLocation();
    const match = useRouteMatch('/:linkId');
// **********************************************************

// ******************** Redux Store variable**********************
  const dispatch = useDispatch();
  const userDetails = useSelector(state => state.getUserDetails); 
  const { loading,userDetail } = userDetails;
  const viewUserDetails = useSelector(state => state.getViewUserDetails); 
  const { viewloading,vuserDetail } = viewUserDetails;
  const followerLists = useSelector(state => state.getFollowerLists); 
  const { followerList } = followerLists; 
  const followingLists = useSelector(state => state.getFollowingLists); 
  const { followingList } = followingLists; 
  const currentfollowingLists = useSelector(state => state.getCurrentFollowingLists); 
  const { currentfollowingList } = currentfollowingLists; 
  const checkFavourite = useSelector(state => state.checkFavourites); 
  const { resultFavourite } = checkFavourite; 
  const checkFriend = useSelector(state => state.checkFriends); 
  const { resultFriend } = checkFriend; 
  const addFollow = useSelector(state => state.addFollows); 
  const { followRecord } = addFollow; 
  const unFollow = useSelector(state => state.unFollows); 
  const { unfollowLog } = unFollow; 
  const EventLists = useSelector(state => state.getEventLists); 
  const { EventList } = EventLists; 
  const RequestLists = useSelector(state => state.getFollowRequestLists); 
  const { requestList } = RequestLists; 
  const InviteLists = useSelector(state => state.getInviteRequestLists); 
  const { inviteList } = InviteLists; 
  const InviteResLists = useSelector(state => state.getInviteResponseLists); 
  const { inviteResList } = InviteResLists; 
  const chkRelation = useSelector(state => state.checkRelations); 
  const { resultRelation } = chkRelation; 
  const updateFavourite = useSelector(state => state.updateFavourites); 
  const { favouriteLog } = updateFavourite; 
  const updateFriend = useSelector(state => state.updateFriends); 
  const { friendLog } = updateFriend; 
  const ResponseLists = useSelector(state => state.getFollowResponseLists); 
  const { responsefList } = ResponseLists; 
  const FollowAcknowledgeList = useSelector(state => state.getFollowAcknowledgeLists); 
  const { acknowledgeList } = FollowAcknowledgeList; 
  const HotEvent = useSelector(state => state.getHotEvents); 
  const { HotEventList } = HotEvent; 
  const updateUserLast = useSelector(state => state.updateUserLasts); 
  const { userLastLog } = updateUserLast; 
// ***************************************************************

// ******************** useState ****************************
  const [InitialPage, setInitialPage] = useState(false);
  const [currentAccount,setCurrentAccount] = useState(null);
  const [viewAccount,setViewAccount] = useState(null);
  const [previewToggle, setPreviewToggle] = useState(true);
  const [favouriteToggle, setfavouriteToggle] = useState(false);
  const [friendToggle, setfriendToggle] = useState(false);
  const [filterToggle, setFilterToggle] = useState(0);
  const [followAmount,setfollowAmount] = useState({
      followerAmount: "0",
      followingAmount: "0",
      followerMember: [],
      followingMember: [],
      followRequestt: false,
    });
  const [currentFollowing,setcurrentFollowing] = useState({
    cfollowingMember: [],
    todayEvents : [],
    favtodayEvents : [],
    fritodayEvents : [],
    todayEventsRequest: false,
  });
  const [eventAmount,seteventAmount] = useState({
      Amount: 0,
      EventDetailList: [],
    });
  const [userDetailInitial, setuserDetailInitial] = useState(false);
  const [checkViewer, setcheckViewer] = useState(false);
  const [checkRelation, setcheckRelation] = useState(false);
  const [judgeRelation, setjudgeRelation] = useState(false);
  const [checkBack, setcheckBack] = useState(false);
  const [followBack, setfollowBack] = useState(false);
  const [relation, setRelation] = useState("");
  const [requestorToggle, setrequestorToggle] = useState(false);
  const [responsefToggle, setresponsefToggle] = useState(false);
  const [allLoaded, setallLoaded] = useState(false);
  const [userstat, setuserstat] = useState(0);
  const [eventTodayLoaded, seteventTodayLoaded] = useState(false);
  const [searchActive, setsearchActive] = useState("");
  const [aSetting,setaSetting] = useState(false);
  const [hotToggle,sethotToggle] = useState(false);
  const [hottodayEvents,sethottodayEvents] = useState([]);

/*
  const [swipeToggle, setswipeToggle] = useState(false);
  const [swiped, setswiped] = useState(false);
  const [swipe,setswipe] = useState({
    x: 0,
    swiping: false,
  });
  */
// **********************************************************

// ******************** Function ***************************
const copyToClipboard = (str) => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  ButterToast.raise({
    content:<Cinnamon.Crisp title="Copy to Clipboard"
        content= {`${str}`}
        scheme = {Cinnamon.Crisp.SCHEME_GREEN}
        icon={<CheckCircle />}
    />})
  //alert("Copied Already");
};

  const requestUserDataHandler = (linkId) => {
    dispatch(getUserDetailsByLinkId(linkId));
    setuserDetailInitial(true);
    //console.log("request User Detail Success[User]");
  };
  const requestUserDataHandlerByEmail = (email) => {
    dispatch(getUserDetailsByEmail(email));
    //console.log("request User Detail by Email Success[User]");
  };

  const requestUserFollowList = (ID) => { 
      dispatch(getFollowerList(ID));
      dispatch(getFollowingList(ID));
      //console.log("request User Follow Success");
      setfollowAmount({followRequestt: true});
  };

  const requestUserFollowingEventList = (ID) => { 
    dispatch(getCurrentFollowingListAct(ID));
    dispatch(getHotEventListsAct());
    //console.log("request Events of Current User Following  Success");
  };

  const requestUserFollowRequestList = (ID) => { 
  dispatch(getFollowRequestListAct(ID));
  //console.log("request Current User Follow Request  Success");
  };

  const requestUserFollowResponseList = (ID) => { 
    dispatch(getFollowResponseListAct(ID));
    //console.log("request Current User Follow Response  Success");
    };

  const requestUserFollowAcknowledgeList = (ID) => { 
    dispatch(getFollowAcknowledgeListAct(ID));
    //console.log("request Current User Follow Acknowledge Success");
  };

  const requestUserInviteRequestList = (ID) => { 
    dispatch(getInviteRequestListAct(ID));
    //console.log("request Current User Invite Request  Success");
    };
  
  const requestUserInviteResponseList = (ID) => { 
    dispatch(getInviteResponseListAct(ID));
    //console.log("request Current User Invite Response  Success");
  };

  const requestCheckFavourite = (id, followId) => {
    dispatch(checkFavouriteAct(id,followId));
    //console.log("request to check favourite Success[User]");
  };

  const requestCheckFriend = (id, followId) => {
    dispatch(checkFriendAct(id,followId));
    //console.log("request to check friend Success[User]");
  };

  const requestCheckRelation = (id, followId) => {
    dispatch(checkRelationAct(id,followId));
    //console.log("request to check relation Success[User]");
  };

  const requestUserEventList = (ID) => { 
    dispatch(getEventListsAct(ID));
    //console.log("request User Event Success");
  };

  const setupdatefavourite = (id, followId,fav) => {
    dispatch(updateFavouriteAct(id,followId,fav));
    //console.log("Update Favourite Success[User]");
    window.location.reload();
  };

  const setunfollow = (id, followid) => {
    dispatch(updateFriendAct(followid,id,"false"));
    dispatch(unFollowAct(id,followid));
    window.location.reload();
  };

  const setupdatefriend = (id, followId,fri) => {
    dispatch(updateFriendAct(id,followId,fri));
    //console.log("Update Friend Success[User]");
  };

  const followBtnHandler = (id, followid, share) => {
    if (relation === "Follow")
    {
      if (share === 1) {
        if (followBack) {
          const followbackdata = [
            {
              userId: id,
              followId: followid,
              followDate: new Date(),
              favourite : false,
              friend: true,
              followRequest: false,
              responseRequest: false,
              followAcknowledge: true,
            },
          ];
          dispatch(updateFriendAct(followid,id,"true"));
          dispatch(addToFollowAct(followbackdata));
        } else {
          const followdata = [
            {
              userId: id,
              followId: followid,
              followDate: new Date(),
              favourite : false,
              friend: false,
              followRequest: false,
              responseRequest: false,
              followAcknowledge: true,
            },
          ];
          dispatch(addToFollowAct(followdata));
        }
      } else {
        const followdataa = [
          {
            userId: id,
            followId: followid,
            followDate: new Date(),
            favourite : false,
            friend: false,
            followRequest: true,
            responseRequest: false,
            followAcknowledge: false,
          },
        ];
        dispatch(addToFollowAct(followdataa));
      }
      //window.location.reload();
    }
    else if (relation === "Unfollow")
    {
      dispatch(unFollowAct(id,followid));
      dispatch(updateFriendAct(followid,id,"false"));
      window.location.reload();
    }
  }  

  const handleFollowers = () => {
    setfromFollow(1);
    setsearchToggle(true);
  };

  const handleFollowing = () => {
    setfromFollow(2);
    setsearchToggle(true);
  };

  const openUserAccount = (linkk) => {
    history.push(`/${linkk}`);
    window.location.reload();
  }
/*
  const onTouchStart = (e) => {
    const touch = e.touches[0];
    setswipe({x: touch.clientX});
    setswiped(false);
  };
  const onTouchMove = (e) => {
    if (e.changedTouches && e.changedTouches.length) {
      const touch = e.changedTouches[0];
      setswipe({
        x : swipe.x,
        swiping: true});
    }
  };
  const onTouchEnd = (e) => {
    const touch = e.changedTouches[0];
    const absX = Math.abs(touch.clientX - swipe.x);
    if (swipe.swiping && absX > 90) {
      setswiped(true);
    }
    setswipe({
      x: 0,
      swiping: false,});
  };
  if(swiped) {
    if (curAccount !== null) {
      setswipeToggle(!swipeToggle);
    } else {
      setLoginToggle(!loginToggle)
    }
    setswiped(false);
  }
  */
// **********************************************************

// ************** useEffect && if condition *****************

  useEffect(() => {
    const handleAuth = firebase.auth().onAuthStateChanged(user => {
      //console.log("4: Check Firebase");
      if (user) {
        setSession({
          isLoggedIn: true,
          currentUser: user,
          errorMessage: null
        });
        if (allLoaded) {
          setuserstat(2);
        }
        if (InitialPage === false){
          requestUserDataHandlerByEmail(user.email);
          setcheckViewer(true);
        } 
      } else {
        setSession({
          isLoggedIn: false,
          currentUser: null,
          errorMessage: null
        })
        
          setuserstat(1);
        
      }
      });
        return () => {
          handleAuth();
        }
      
      }, []);

    useEffect(()=>{ 
      if (InitialPage === false)
      {
        history.push(`/${match.params.linkId}`);
        requestUserDataHandler(location.pathname); 
        location.pathname = "/";
        match.params.linkId = "";
        //console.log("1: Initial Page");
        setInitialPage(true);
      }
        
      
    },[isLoggedIn]); 
/*
    useEffect(()=>{ 
      console.log(searchActive);
      //console.log(currentFollowing.cfollowingMember.filter(element => {return element.name.toLowerCase().includes(searchActive.toLowerCase());})[0]._id);
      console.log(currentFollowing.todayEvents)
      console.log(currentFollowing.todayEvents.filter(acc => {return acc.createName.toLowerCase().includes(searchActive.toLowerCase());}))
    },[searchActive]); 
*/
    if (userDetailInitial){
      if (viewloading === false){
        if (typeof(vuserDetail) !== 'undefined')
        {
          //console.log("2: Request Data of View Account and store")
          setViewAccount({vuserDetail});
          setvAccount({vuserDetail});
          requestUserEventList(vuserDetail._id); 
          requestUserFollowList(vuserDetail._id); 
          setuserDetailInitial(false);
          setfollowAmount({followRequestt: true});
        }
      }
      /*
      if (typeof(userDetail) !== 'undefined' && loading === false) // จะได้ data ของเจ้าของ Link แบบชัวๆ
      {
        console.log("2: Request Data of View Account and store");
        setViewAccount({userDetail});
        setvAccount({userDetail});
        requestUserEventList(userDetail._id); 
        requestUserFollowList(userDetail._id); 
        setuserDetailInitial(false);
        setfollowAmount({followRequestt: true});
      } else if(typeof(userDetail) === 'undefined' && loading === false) {
        
        alert("Not found link");
        setuserDetailInitial(false);
        openUserAccount("");
      }*/
    }
    if (followAmount.followRequestt){
      if (typeof(followerList) !== [] && typeof(followingList) !== [] && typeof(followerList) !== 'undefined' && typeof(followingList) !== 'undefined' && typeof(EventList) !== [] && typeof(EventList) !== 'undefined') 
      { 
        //console.log("3: Request Follow Data of View Account and Store");
        setfollowAmount({
            followerMember: followerList.map(x => { return x.followerUser[0];}), 
            followingMember: followingList.map(x => { return x.followingUser[0];}),
            followerAmount: followerList.length, 
            followingAmount: followingList.length - 1, 
            followRequestt: false
        });
        setvfollowerMember(followerList.map(x => { return x.followerUser[0];}));
        setvfollowingMember(followingList.map(x => { return x.followingUser[0];}));
        seteventAmount({EventDetailList: EventList, 
          Amount: EventList.length});
        /*
        if (typeof(EventList) !== [] && typeof(EventList) !== 'undefined') 
        { 
          console.log("3.1: Request Event Data of View Account and Store");
          seteventAmount({EventDetailList: EventList, 
                          Amount: EventList.length});
          console.log(EventList);
        }
        */
        setallLoaded(true);
      }
      
    }

    

    if (checkViewer) {
      if (typeof(userDetail) !== 'undefined') {
        //console.log("5:if LoggedIn: Request Data of Login Account and Store");
        requestUserFollowingEventList(userDetail._id);
        requestUserFollowRequestList(userDetail._id);
        requestUserFollowAcknowledgeList(userDetail._id);
        requestUserFollowResponseList(userDetail._id);
        requestUserInviteRequestList(userDetail._id);
        requestUserInviteResponseList(userDetail._id);
        dispatch(updateUserLastAct(userDetail._id));
        setrequestorToggle(true);
        setresponsefToggle(true);
        setcurrentFollowing({todayEventsRequest: true});
        setCurrentAccount({userDetail});
        setCurAccount({userDetail});
        setcheckRelation(true);
        setcheckViewer(false);
      }
    }
    if(responsefToggle){
      if(typeof(responsefList) !== [] && typeof(responsefList) !== 'undefined' && typeof(inviteResList) !== [] && typeof(inviteResList) !== 'undefined' && typeof(acknowledgeList) !== [] && typeof(acknowledgeList) !== 'undefined'){
        setresfollowList(responsefList.map(reUser => { return reUser.responseReUser[0]; }));
        setacknowList(acknowledgeList.map(reUser => { return reUser.followAckUser[0]; }))
        setinvitorResEvent(inviteResList);
        setresponsefToggle(false);
      }
    }
    if (requestorToggle)
    {
      if (typeof(requestList) !== [] && typeof(requestList) !== 'undefined' && typeof(inviteList) !== [] && typeof(inviteList) !== 'undefined') {
        setrequestorList(requestList.map(reUser => { return reUser.followReUser[0]; }));
        setrequestorStatus(requestList.map(reUser => { return reUser.followReChk[0]; }));
        setinvitorList(inviteList.map(iUs => { return iUs.inviteUser[0]}));
        setinvitorEvent(inviteList.map(iEv => { return iEv.inviteEvent[0]}));
        setrequestorToggle(false);
      }
    }
    if (currentFollowing.todayEventsRequest)
    {
        if (typeof(currentfollowingList) !== [] && typeof(currentfollowingList) !== 'undefined' && typeof(HotEventList) !== [] && typeof(HotEventList) !== 'undefined') 
        { 
          let CEarray = [];
          let FavCEarray = [];
          let FriCEarray = [];
          if(HotEventList.length > 0){
            const hoteventfilter = HotEventList.filter(h => h.createId !== curAccount.userDetail._id);
            if(hoteventfilter.length > 0){
              sethottodayEvents(hoteventfilter);
            }
          }
          const test2 = currentfollowingList.map(x2 => { return x2.followingEvent;});
          const status = currentfollowingList.map(st => { return st.followingStatus[0];}).filter(e => typeof(e) !== 'undefined');
          //console.log(test2);
          //console.log(status);
          if (test2.length > 0)
          {
            for (let i2 = 0; i2 < test2.length; i2++) {
              if (test2[i2].length > 0) {
                for (let ii2 = 0; ii2 < test2[i2].length; ii2++) {
                  //console.log(currentfollowingList.map(xx => { return xx.followingUser[0];}).filter(us => us._id === test2[i2][ii2].createId)[0].name); // ได้ชื่อ
                  //console.log(test2[i2][ii2]);
                  const addNametoEvent = {
                    ...test2[i2][ii2],
                    createName: currentfollowingList.map(xx => { return xx.followingUser[0];}).filter(us => us._id === test2[i2][ii2].createId)[0].name,
                    createImg: currentfollowingList.map(xx => { return xx.followingUser[0];}).filter(us => us._id === test2[i2][ii2].createId)[0].imageUrl,
                    createLink: currentfollowingList.map(xx => { return xx.followingUser[0];}).filter(us => us._id === test2[i2][ii2].createId)[0].linkId,
                  };
                  if (curAccount.userDetail._id !== addNametoEvent.createId) {
                    if (addNametoEvent.eventShareType === 1)
                    {
                      CEarray.push(addNametoEvent);
                    }
                    else {
                      if(addNametoEvent.eventShareType === 2) 
                      {
                        if (status.filter(fri => addNametoEvent.createId === fri.followId)[0].friend) 
                        {
                          CEarray.push(addNametoEvent);
                        }
                      }
                    }

                    //CEarray.push(addNametoEvent);
                    
                    if (status.filter(fav => addNametoEvent.createId === fav.followId)[0].favourite) {
                      if (addNametoEvent.eventShareType === 1)
                      {
                        FavCEarray.push(addNametoEvent);
                      } else {
                        if(addNametoEvent.eventShareType === 2) 
                        {
                          if (status.filter(fri => addNametoEvent.createId === fri.followId)[0].friend) 
                          {
                            FavCEarray.push(addNametoEvent);
                          }
                        }
                      }
                    }

                    if (status.filter(fri => addNametoEvent.createId === fri.followId)[0].friend) {
                      if (addNametoEvent.eventShareType !== 3) 
                      {
                        FriCEarray.push(addNametoEvent);
                      }
                    }
                  } else {
                    CEarray.push(addNametoEvent);
                    FavCEarray.push(addNametoEvent);
                    FriCEarray.push(addNametoEvent);
                  }

                  
                  
                }
              }
            }
            //console.log("5.1 Rearrange Today Events of Current User Success")
            setcurrentFollowing({
              cfollowingMember: currentfollowingList.map(xx => { return xx.followingUser[0];}),
              todayEvents: CEarray,
              favtodayEvents: FavCEarray,
              fritodayEvents: FriCEarray,
              todayEventsRequest: false
            });
          } else {
            setcurrentFollowing({
              cfollowingMember: [],
              todayEvents: [],
              favtodayEvents: [],
              fritodayEvents: [],
              todayEventsRequest: false
            });
          }
          seteventTodayLoaded(true);
        }
      
    }
    if (checkRelation) {
      if (viewAccount.vuserDetail._id !== currentAccount.userDetail._id)
      {
        requestCheckRelation(currentAccount.userDetail._id,viewAccount.vuserDetail._id);
        requestCheckFriend(viewAccount.vuserDetail._id,currentAccount.userDetail._id);
        setcheckBack(true);
        setjudgeRelation(true);
      } else {
        if (currentAccount.userDetail.shareType === 1) {
          if (mobileSize){
            setRelation("Public");
          } else {
            setRelation("Public Account");
          }
          
        } 
        else 
        {
          if (mobileSize){
            setRelation("Private");
          } else {
            setRelation("Private Account");
          }
          
        }
        
      }
      setcheckRelation(false);
    }

    if (judgeRelation) { 
      if (typeof(resultRelation) !== [] && typeof(resultRelation) !== 'undefined')
      {
        if (typeof(resultRelation.followRequest) !== 'undefined' && typeof(resultRelation.friend) !== 'undefined' && typeof(resultRelation.favourite) !== 'undefined')
        {
          
          if (resultRelation.followRequest)
          {
            setRelation("Requested");
          } else {
            if (resultRelation.favourite) {
              setRelation("Favourite");
              setfavouriteToggle(true);
              if (resultRelation.friend) {
                setfriendToggle(true);
              }
            } else {
              if (resultRelation.friend) {
                setRelation("Friend");
                setfriendToggle(true);
              } else {
                setRelation("Following");
              } 
            }
          }
          setjudgeRelation(false);
          
        } 
        
      }  
    }

    if (checkBack) {
      if (resultFriend === false)
      {
        //console.log("He follow you already");
        setfollowBack(true);
        setcheckBack(false);
      } else if (typeof(resultFriend) === 'undefined')
      {
        //console.log("He not follow you ");
        setfollowBack(false);
        setcheckBack(false);
      }
    }

    if (eventTodayLoaded) {
      if (relation === "") {
        setRelation("Follow");
      }
    }
      
      /*
      if(resultFriend === false || resultFriend === true)
      {
        setRelation("Unfollow");
        setjudgeRelation(false);
      } 
      else if(typeof(resultFriend) === 'undefined')
      {
        setRelation("Follow");
        setjudgeRelation(false);
      }
      */
      
      
    //onTouchStart={e => onTouchStart(e)} onTouchMove={e => onTouchMove(e)} onTouchEnd={e=> onTouchEnd(e)}

    return (
        <div className="userscreen" 
            onClick={() => {fromNoti ?  window.location.reload() || setNotiToggle(false) || setfromNoti(false) : aSetting ? setaSetting(false) || setNotiToggle(false) : setNotiToggle(false)}}
            >

            <div className={`${swipeToggle ? "userscreen__primary2" : "userscreen__primary"}`}>
            {allLoaded === false ? 
            
            <>
                <div className="userscreen__loading"></div>
                <div className="userscreen__loadtext">Loading . . .</div>
            </>
            
            : userstat > 0 ? 

                <>
                <div className="schedule__body">
                
                    <header className="schedule__header">
                        
                        <div className="header__account">
                        
                              <div className="account__left">
                                  <img src={viewAccount.vuserDetail.imageUrl} alt="account__pic" className="account__pic"/>
                              </div>
                              <div className="account__center">
                                    <div className="account__nameplate">
                                        <div className="account__name">{viewAccount.vuserDetail.name}</div>
                                        <div className="account__id">www.follows-up.com/{viewAccount.vuserDetail.linkId}<span className="account__id__badge" onClick={() => copyToClipboard(`www.follows-up.com/${viewAccount.vuserDetail.linkId}`)}>Copy</span></div>
                                        
                                    </div>
                                    <div className="account__stat">
                                      <div className="account__event">
                                          <h2>{eventAmount.Amount < 1000 ? eventAmount.Amount : eventAmount.Amount > 1000000 ? `${Math.round((eventAmount.Amount/1000000)*10)/10}m` : `${Math.round((eventAmount.Amount/1000)*10)/10}k`}</h2>
                                          <p>EVENTS</p>
                                      </div>
                                      <div className="account__follower" onClick={()=> handleFollowers()}>
                                          <h2>{followAmount.followerAmount < 1000 ? followAmount.followerAmount : followAmount.followerAmount > 1000000 ? `${Math.round((followAmount.followerAmount/1000000)*10)/10}m` : `${Math.round((followAmount.followerAmount/1000)*10)/10}k`}</h2>
                                          <p>Followers</p>
                                      </div>
                                      <div className="account__following" onClick={()=> handleFollowing()}>
                                          <h2>{followAmount.followingAmount < 1000 ? followAmount.followingAmount : followAmount.followingAmount > 1000000 ? `${Math.round((followAmount.followingAmount/1000000)*10)/10}m` : `${Math.round((followAmount.followingAmount/1000)*10)/10}k`}</h2>
                                          <p>Following</p>
                                      </div>
                                    </div>
                              </div>
                              <div className="account__right">
                                    <div className="account__righttop">
                                      <div className="account__public" onClick={() => followBtnHandler(currentAccount.userDetail._id,viewAccount.vuserDetail._id,viewAccount.vuserDetail.shareType)}>
                                        {relation !== "Follow" ?
                                          relation === "" ? "" :
                                          <>
                                          <div>
                                            {relation}
                                            {aSetting ? <AccountSetting show={aSetting} {...{relation,curAccount,vAccount,setregisterToggle,setupdatefavourite,setunfollow}}/> : ""} 
                                          </div>
                                          <span className="icon" onClick={()=> setaSetting(!aSetting)}>settings</span>
                                          </>
                                        :
                                          <div className="account__followbtn">{relation}</div>
                                        }
                                        
                                        </div>
                                    
                                    </div>
                                    <div className="account__rightbottom">
                                      <div className="account__toggle"></div>
                                    <div className="account__relation">
                                        {relation === "Private Account" ||  relation === "Public Account" || relation === "Private" || relation === "Public" ?
                                        "" :
                                        friendToggle === false ? "" : <span className="icon__friend">supervisor_account</span>
                                        }

                                        {relation === "Private Account" ||  relation === "Public Account" || relation === "Requested" || relation === "Follow" || relation === "" || relation === "Private" || relation === "Public" ?
                                        "" :
                                        favouriteToggle === false ? 
                                        <span className="icon__favourite" onClick={() => setupdatefavourite(currentAccount.userDetail._id,viewAccount.vuserDetail._id,"true")}>star</span> 
                                        : 
                                        <span className="icon2__favourite" onClick={() =>setupdatefavourite(currentAccount.userDetail._id,viewAccount.vuserDetail._id,"false")}>star</span>
                                        }
                                      
                                        
                                      {previewToggle ? 
                                            <>
                                            <span className="icon" onClick={() => setPreviewToggle(false)}>preview</span>
                                            </>
                                            :
                                            <>
                                            <span className="icon2" onClick={() => setPreviewToggle(true)}>preview</span>
                                            </>}  
                                      
                                      </div>
                                    </div>
                              </div> 
                                  
                       
                        </div>
                        
                        
                    </header>
                    
                    <main>
                    <Schedule {...eventAmount} previewToggle={previewToggle} setSideToggle={setSideToggle} setselectEvents={setselectEvents} setselectDate={setselectDate} 
                                  eventTodayLoaded={eventTodayLoaded} allLoaded={allLoaded} curAccount={curAccount} vAccount={vAccount} isLoggedIn={isLoggedIn} userstat={userstat}
                                  friendToggle={friendToggle} mobileSize={mobileSize}/>
                    </main>
                    {!mobileSize ? "" : 
                    curAccount === null ? <div className="userscreen__swipe__text">Swipe on Calendar to Create Your Calendar Link <span className="icon">swipe</span></div>
                    :
                    <div className="userscreen__swipe__text">Swipe on Calendar to see Events Today <span className="icon">swipe</span></div>
                    }
                  </div>
                  </>


                :

                eventTodayLoaded ?
                

                <>
                <div className="schedule__body">
                
                    <header className="schedule__header">
                        
                        <div className="header__account">
                        
                              <div className="account__left">
                                  <img src={viewAccount.vuserDetail.imageUrl} alt="account__pic" className="account__pic"/>
                              </div>
                              <div className="account__center">
                                    <div className="account__nameplate">
                                        <div className="account__name">{viewAccount.vuserDetail.name}</div>
                                        <div className="account__id">www.follows-up.com/{viewAccount.vuserDetail.linkId}<span className="account__id__badge" onClick={() => copyToClipboard(`www.follows-up.com/${viewAccount.vuserDetail.linkId}`)}>Copy</span></div>
                                        
                                    </div>
                                    <div className="account__stat">
                                      <div className="account__event">
                                          <h2>{eventAmount.Amount < 1000 ? eventAmount.Amount : eventAmount.Amount > 1000000 ? `${Math.round((eventAmount.Amount/1000000)*10)/10}m` : `${Math.round((eventAmount.Amount/1000)*10)/10}k`}</h2>
                                          <p>EVENTS</p>
                                      </div>
                                      <div className="account__follower" onClick={()=> handleFollowers()}>
                                          <h2>{followAmount.followerAmount < 1000 ? followAmount.followerAmount : followAmount.followerAmount > 1000000 ? `${Math.round((followAmount.followerAmount/1000000)*10)/10}m` : `${Math.round((followAmount.followerAmount/1000)*10)/10}k`}</h2>
                                          <p>Followers</p>
                                      </div>
                                      <div className="account__following" onClick={()=> handleFollowing()}>
                                          <h2>{followAmount.followingAmount < 1000 ? followAmount.followingAmount : followAmount.followingAmount > 1000000 ? `${Math.round((followAmount.followingAmount/1000000)*10)/10}m` : `${Math.round((followAmount.followingAmount/1000)*10)/10}k`}</h2>
                                          <p>Following</p>
                                      </div>
                                    </div>
                              </div>

                              <div className="account__right">
                                    <div className="account__righttop">
                                    <div className="account__public" onClick={() => followBtnHandler(currentAccount.userDetail._id,viewAccount.vuserDetail._id,viewAccount.vuserDetail.shareType)}>
                                        
                                        {relation !== "Follow" ?
                                          relation === "" ? "" :
                                          <>
                                          <div>
                                            {relation}
                                            {aSetting ? <AccountSetting show={aSetting} {...{relation,curAccount,vAccount,setregisterToggle,setupdatefavourite,setunfollow}}/> : ""} 
                                            </div>
                                          <span className="icon" onClick={()=> setaSetting(!aSetting)}>settings</span>
                                          </>
                                        :
                                          <div className="account__followbtn">{relation}</div>
                                        }
                                        </div>
                                    </div>
                                    <div className="account__rightbottom">
                                      <div className="account__toggle"></div>
                                    <div className="account__relation">
                                        {relation === "Private Account" ||  relation === "Public Account" || relation === "Private" ||  relation === "Public"  ?
                                        "" :
                                        friendToggle === false ? "" : <span className="icon__friend">supervisor_account</span>
                                        }

                                        {relation === "Private Account" ||  relation === "Public Account" || relation === "Requested" || relation === "Follow" || relation === "" || relation === "Private" ||  relation === "Public"  ?
                                        "" :
                                        favouriteToggle === false ? 
                                        <span className="icon__favourite" onClick={() => setupdatefavourite(currentAccount.userDetail._id,viewAccount.vuserDetail._id,"true")}>star</span> 
                                        : 
                                        <span className="icon2__favourite" onClick={() =>setupdatefavourite(currentAccount.userDetail._id,viewAccount.vuserDetail._id,"false")}>star</span>
                                        }
                                      
                                        
                                      {previewToggle ? 
                                            <>
                                            <span className="icon" onClick={() => setPreviewToggle(false)}>preview</span>
                                            </>
                                            :
                                            <>
                                            <span className="icon2" onClick={() => setPreviewToggle(true)}>preview</span>
                                            </>}  
                                      
                                      </div>
                                    </div>
                              </div>
                              {/*{aSetting ? <AccountSetting show={aSetting} {...{relation,curAccount,setregisterToggle}}/> : ""}      */}          
                       
                        </div>
                        
                        
                    </header>
                    
                    <main>
                    <Schedule {...eventAmount} previewToggle={previewToggle} setSideToggle={setSideToggle} setselectEvents={setselectEvents} setselectDate={setselectDate} 
                                  eventTodayLoaded={eventTodayLoaded} allLoaded={allLoaded} curAccount={curAccount} vAccount={vAccount} isLoggedIn={isLoggedIn} userstat={userstat} 
                                  friendToggle={friendToggle} mobileSize={mobileSize}/>
                    </main>
                    {!mobileSize ? "" : 
                    curAccount === null ? <div className="userscreen__swipe__text">Swipe on Calendar to Create Your Calendar Link <span className="icon">swipe</span></div>
                    :
                    <div className="userscreen__swipe__text">Swipe on Calendar to see Events Today <span className="icon">swipe</span></div>
                    }
                    
                  </div>
                  </>
                  :
                  <>
                <div className="userscreen__loading"></div>
                <div className="userscreen__loadtext">Loading . . .</div>
                </>
              }     
            </div>
            
            {eventTodayLoaded=== true ? 
            <div className={`${swipeToggle ? "userscreen__secondary2" : "userscreen__secondary"}`}>
                <div className="eventactive__title">
                  <div></div>
                  <div className="eventactive__today">Events Today</div>
                  <div className={hotToggle ? `eventactive__hotevent2` : `eventactive__hotevent`} onClick = {()=>sethotToggle(!hotToggle)}><span className="icon">whatshot</span>Hot event !</div>
                </div>
                
                <div className="eventactive__filter">
                  {filterToggle === 0 ?
                  <>
                  <span className="icon" onClick={()=> setFilterToggle(2)}>star</span>
                  <span className="icon" onClick={()=> setFilterToggle(1)}>supervisor_account</span>
                  <span className="icon2" onClick={()=> setFilterToggle(0)}>contacts</span>
                  </>
                :
                    filterToggle === 1 ?
                    <>
                    <span className="icon" onClick={()=> setFilterToggle(2)}>star</span>
                    <span className="icon2" onClick={()=> setFilterToggle(1)}>supervisor_account</span>
                    <span className="icon" onClick={()=> setFilterToggle(0)}>contacts</span>
                    </>
                    :
                    <>
                    <span className="icon2" onClick={()=> setFilterToggle(2)}>star</span>
                    <span className="icon" onClick={()=> setFilterToggle(1)}>supervisor_account</span>
                    <span className="icon" onClick={()=> setFilterToggle(0)}>contacts</span>
                    </>
                }
                </div>
                
                <Paper component="form" className={classes.root}>
                  {/*
                    <IconButton color="primary" className={classes.iconButton} aria-label="filtersearch">
                          
                        <Select className="select__filter"
                            placeholder={<span className="icon">star</span>}
                            value="a"
                            options={websiteFilterFiedsOptions}
                          />
                            
                            
                    </IconButton>
                  */}
                    <InputBase
                        className={classes.input}
                        placeholder={`${filterToggle === 0 ? "Search Following Name" : filterToggle === 1 ? "Search Friend Name" : "Search Favourite Name"}`}
                        inputProps={{ 'aria-label': 'search google maps' }}
                        onChange={e => setsearchActive(e.target.value)}
                    />
                    <IconButton className={classes.iconButton} aria-label="search">
                            <div id="logo">
                                <span className="icon">person_search</span>
                            </div>
                    </IconButton>
                </Paper>
                <div className="eventactive__event">
                {eventTodayLoaded && currentFollowing.cfollowingMember !== 0 ? 
                <>


                {currentFollowing.todayEvents && currentFollowing.todayEvents.length === 0
                ? 
                  hotToggle ?
                    hottodayEvents.length > 0 
                    ?
                    searchActive === "" ?
                      hottodayEvents.sort((b, a) => new Date(b.eventDate) - new Date(a.eventDate)).map(toevent => (
                        <EventsActive key={`${toevent.eventId}${toevent.createId}`} toevent={toevent} curAccount={curAccount} setEventToggle={setEventToggle} setaddedEvents={setaddedEvents} setselectDate={setselectDate}/> 
                      ))
                      :
                      hottodayEvents.filter(acc => {return acc.createName.toLowerCase().includes(searchActive.toLowerCase());}).length > 0 ?
                      hottodayEvents.filter(acc => {return acc.createName.toLowerCase().includes(searchActive.toLowerCase());}).sort((b, a) => new Date(b.eventDate) - new Date(a.eventDate)).map(toevent => (
                        <EventsActive key={`${toevent.eventId}${toevent.createId}`} toevent={toevent} curAccount={curAccount} setEventToggle={setEventToggle} setaddedEvents={setaddedEvents} setselectDate={setselectDate}/> 
                      ))
                      :
                      <div className="eventactive__not">Not have Hot event avaliable.</div>
                    :
                    <>
                    <div className="eventactive__not">Not have any Hot events avaliable.</div>
                    <div className="eventactive__not">Hot events are event that will happen today</div>
                    <div className="eventactive__not">and people added more than 100 times.</div>
                    <div className="eventactive__not">*Recommend events are event that will happen</div>
                    <div className="eventactive__not">today and event's owner promoted this event.</div>
                    </>
                  :
                  <>
                  <div className="eventactive__not">Not have any event avaliable.</div>
                  <div className="eventactive__not">Events Today will show your events {'&'}</div>
                  <div className="eventactive__not">your following events that will happen today</div>
                  <div className="eventactive__not">- Let Try to Create Your Event -</div>
                  <div className="eventactive__not">Select Date on Calendar {'>'} CREATE EVENT</div>
                  </>
                : 
                
                hotToggle && hottodayEvents.length > 0 ?


                filterToggle === 0 ? 

                searchActive === "" ?
                currentFollowing.todayEvents.concat(hottodayEvents).length === 0 ? <div className="eventactive__not">Not have following's event avaliable.</div> :
                  currentFollowing.todayEvents.concat(hottodayEvents).sort((b, a) => new Date(b.eventDate) - new Date(a.eventDate)).map(toevent => (
                      <EventsActive key={`${toevent.eventId}${toevent.createId}`} toevent={toevent} curAccount={curAccount} setEventToggle={setEventToggle} setaddedEvents={setaddedEvents} setselectDate={setselectDate}/> 
                  ))
                  : 
                  currentFollowing.todayEvents.concat(hottodayEvents).filter(acc => {return acc.createName.toLowerCase().includes(searchActive.toLowerCase());}).length > 0 
                    ? 
                    currentFollowing.todayEvents.concat(hottodayEvents).filter(acc => {return acc.createName.toLowerCase().includes(searchActive.toLowerCase());}).sort((b, a) => new Date(b.eventDate) - new Date(a.eventDate)).map(toevent => (
                      <EventsActive key={`${toevent.eventId}${toevent.createId}`} toevent={toevent} curAccount={curAccount} setEventToggle={setEventToggle} setaddedEvents={setaddedEvents} setselectDate={setselectDate}/> 
                    ))
                    :
                    <div className="eventactive__not">Not have following's event avaliable.</div>
                
                :

                filterToggle === 1 ? 
                
                searchActive === "" ?
                currentFollowing.fritodayEvents.concat(hottodayEvents).length === 0 ? <div className="eventactive__not">Not have friend's event avaliable.</div> :
                  currentFollowing.fritodayEvents.concat(hottodayEvents).sort((b, a) => new Date(b.eventDate) - new Date(a.eventDate)).map(toevent => (
                      <EventsActive key={`${toevent.eventId}${toevent.createId}`} toevent={toevent} curAccount={curAccount} setEventToggle={setEventToggle} setaddedEvents={setaddedEvents} setselectDate={setselectDate}/> 
                  ))
                  : 
                  currentFollowing.fritodayEvents.concat(hottodayEvents).filter(acc => {return acc.createName.toLowerCase().includes(searchActive.toLowerCase());}).length > 0 
                    ? 
                    currentFollowing.fritodayEvents.concat(hottodayEvents).filter(acc => {return acc.createName.toLowerCase().includes(searchActive.toLowerCase());}).sort((b, a) => new Date(b.eventDate) - new Date(a.eventDate)).map(toevent => (
                      <EventsActive key={`${toevent.eventId}${toevent.createId}`} toevent={toevent} curAccount={curAccount} setEventToggle={setEventToggle} setaddedEvents={setaddedEvents} setselectDate={setselectDate}/> 
                    ))
                    :
                    <div className="eventactive__not">Not have friend's event avaliable.</div>
                
                :

                searchActive === "" ?
                currentFollowing.favtodayEvents.concat(hottodayEvents).length === 0 ? <div className="eventactive__not">Not have favourite's event avaliable.</div> :
                  currentFollowing.favtodayEvents.concat(hottodayEvents).sort((b, a) => new Date(b.eventDate) - new Date(a.eventDate)).map(toevent => (
                      <EventsActive key={`${toevent.eventId}${toevent.createId}`} toevent={toevent} curAccount={curAccount} setEventToggle={setEventToggle} setaddedEvents={setaddedEvents} setselectDate={setselectDate}/> 
                  ))
                  : 
                  currentFollowing.favtodayEvents.concat(hottodayEvents).filter(acc => {return acc.createName.toLowerCase().includes(searchActive.toLowerCase());}).length > 0 
                    ? 
                    currentFollowing.favtodayEvents.concat(hottodayEvents).filter(acc => {return acc.createName.toLowerCase().includes(searchActive.toLowerCase());}).sort((b, a) => new Date(b.eventDate) - new Date(a.eventDate)).map(toevent => (
                      <EventsActive key={`${toevent.eventId}${toevent.createId}`} toevent={toevent} curAccount={curAccount} setEventToggle={setEventToggle} setaddedEvents={setaddedEvents} setselectDate={setselectDate}/> 
                    ))
                    :
                    <div className="eventactive__not">Not have favourite's event avaliable.</div>


                  
                :
                filterToggle === 0 ? 

                searchActive === "" ?
                currentFollowing.todayEvents.length === 0 ? <div className="eventactive__not">Not have following's event avaliable.</div> :
                  currentFollowing.todayEvents.sort((b, a) => new Date(b.eventDate) - new Date(a.eventDate)).map(toevent => (
                      <EventsActive key={`${toevent.eventId}${toevent.createId}`} toevent={toevent} curAccount={curAccount} setEventToggle={setEventToggle} setaddedEvents={setaddedEvents} setselectDate={setselectDate}/> 
                  ))
                  : 
                  currentFollowing.todayEvents.filter(acc => {return acc.createName.toLowerCase().includes(searchActive.toLowerCase());}).length > 0 
                    ? 
                    currentFollowing.todayEvents.filter(acc => {return acc.createName.toLowerCase().includes(searchActive.toLowerCase());}).sort((b, a) => new Date(b.eventDate) - new Date(a.eventDate)).map(toevent => (
                      <EventsActive key={`${toevent.eventId}${toevent.createId}`} toevent={toevent} curAccount={curAccount} setEventToggle={setEventToggle} setaddedEvents={setaddedEvents} setselectDate={setselectDate}/> 
                    ))
                    :
                    <div className="eventactive__not">Not have following's event avaliable.</div>
                
                :

                filterToggle === 1 ? 
                
                searchActive === "" ?
                currentFollowing.fritodayEvents.length === 0 ? <div className="eventactive__not">Not have friend's event avaliable.</div> :
                  currentFollowing.fritodayEvents.sort((b, a) => new Date(b.eventDate) - new Date(a.eventDate)).map(toevent => (
                      <EventsActive key={`${toevent.eventId}${toevent.createId}`} toevent={toevent} curAccount={curAccount} setEventToggle={setEventToggle} setaddedEvents={setaddedEvents} setselectDate={setselectDate}/> 
                  ))
                  : 
                  currentFollowing.fritodayEvents.filter(acc => {return acc.createName.toLowerCase().includes(searchActive.toLowerCase());}).length > 0 
                    ? 
                    currentFollowing.fritodayEvents.filter(acc => {return acc.createName.toLowerCase().includes(searchActive.toLowerCase());}).sort((b, a) => new Date(b.eventDate) - new Date(a.eventDate)).map(toevent => (
                      <EventsActive key={`${toevent.eventId}${toevent.createId}`} toevent={toevent} curAccount={curAccount} setEventToggle={setEventToggle} setaddedEvents={setaddedEvents} setselectDate={setselectDate}/> 
                    ))
                    :
                    <div className="eventactive__not">Not have friend's event avaliable.</div>
                
                :

                searchActive === "" ?
                currentFollowing.favtodayEvents.length === 0 ? <div className="eventactive__not">Not have favourite's event avaliable.</div> :
                  currentFollowing.favtodayEvents.sort((b, a) => new Date(b.eventDate) - new Date(a.eventDate)).map(toevent => (
                      <EventsActive key={`${toevent.eventId}${toevent.createId}`} toevent={toevent} curAccount={curAccount} setEventToggle={setEventToggle} setaddedEvents={setaddedEvents} setselectDate={setselectDate}/> 
                  ))
                  : 
                  currentFollowing.favtodayEvents.filter(acc => {return acc.createName.toLowerCase().includes(searchActive.toLowerCase());}).length > 0 
                    ? 
                    currentFollowing.favtodayEvents.filter(acc => {return acc.createName.toLowerCase().includes(searchActive.toLowerCase());}).sort((b, a) => new Date(b.eventDate) - new Date(a.eventDate)).map(toevent => (
                      <EventsActive key={`${toevent.eventId}${toevent.createId}`} toevent={toevent} curAccount={curAccount} setEventToggle={setEventToggle} setaddedEvents={setaddedEvents} setselectDate={setselectDate}/> 
                    ))
                    :
                    <div className="eventactive__not">Not have favourite's event avaliable.</div>
                }
                </>

                :
                hotToggle ?
                  hottodayEvents.length > 0 
                  ?
                  searchActive === "" ?
                  hottodayEvents.sort((b, a) => new Date(b.eventDate) - new Date(a.eventDate)).map(toevent => (
                    <EventsActive key={`${toevent.eventId}${toevent.createId}`} toevent={toevent} curAccount={curAccount} setEventToggle={setEventToggle} setaddedEvents={setaddedEvents} setselectDate={setselectDate}/> 
                  ))
                  :
                  hottodayEvents.filter(acc => {return acc.createName.toLowerCase().includes(searchActive.toLowerCase());}).length > 0 ?
                  hottodayEvents.filter(acc => {return acc.createName.toLowerCase().includes(searchActive.toLowerCase());}).sort((b, a) => new Date(b.eventDate) - new Date(a.eventDate)).map(toevent => (
                    <EventsActive key={`${toevent.eventId}${toevent.createId}`} toevent={toevent} curAccount={curAccount} setEventToggle={setEventToggle} setaddedEvents={setaddedEvents} setselectDate={setselectDate}/> 
                  ))
                  :
                  <div className="eventactive__not">Not have Hot event avaliable.</div>
                  :
                  <>
                    <div className="eventactive__not">Not have any Hot events avaliable.</div>
                    <div className="eventactive__not">Hot events are event that will happen today</div>
                    <div className="eventactive__not">and people added more than 100 times.</div>
                    <div className="eventactive__not">*Recommend events are event that will happen</div>
                    <div className="eventactive__not">today and event's owner promoted this event.</div>
                    </>
                :
                <>
                  <div className="eventactive__not">Not have any event avaliable.</div>
                  <div className="eventactive__not">Events Today will show your events {'&'}</div>
                  <div className="eventactive__not">your following events that will happen today</div>
                  <div className="eventactive__not">- Let Try to Create Your Event -</div>
                  <div className="eventactive__not">Select Date on Calendar {'>'} CREATE EVENT</div>
                </>
                
              }
                
                </div>
                
            </div>
            
            : 
            ""
            
            }
        </div>
    )
    
}

export default UserScreen