import './EventsActive.css';
import {useHistory} from 'react-router-dom';
import { useState , useEffect} from 'react'; 
import * as dateFns from 'date-fns';

const EventsActive = ({toevent,curAccount,setEventToggle,setaddedEvents,setselectDate}) => {
    let history = useHistory();
    const [eventOnline, seteventOnline] = useState(false);
    const [eventBeforeH, seteventBeforeH] = useState("");
    const [eventBeforeM, seteventBeforeM] = useState("");
    

    



    useEffect(() => {
        let compareDate = new Date();  
        let startDate = new Date(toevent.eventDate);  
        let endDate = new Date(toevent.eventEndDate);
        seteventOnline(compareDate <= endDate && compareDate >= startDate);
        if (compareDate < startDate) {
            var resulthour = dateFns.differenceInHours(
                startDate,
                compareDate
              )
              var resultmin = (dateFns.differenceInMinutes(
                startDate,
                compareDate
              ) + 1)
              
            seteventBeforeH(resulthour);
            seteventBeforeM(resultmin - (resulthour*60));
        }
      }, [])

    const handleAddEvent = (eventtt) => {
        const starteDate = dateFns.startOfWeek(new Date());
        const endString = `T${starteDate.getUTCHours().toString()}:00:00.000Z`;
        let endTime = ""
        if ((new Date().getUTCHours()) >= 17 && (new Date().getUTCHours()) < 24) {
            endTime = `${dateFns.addDays(new Date(),1).toISOString().substring(0,10)}${endString}`;
        } else {
            endTime = `${new Date().toISOString().substring(0,10)}${endString}`;
        }
        
        //console.log(dateFns.addDays(new Date(endTime),-1));
        setselectDate((dateFns.addDays(new Date(endTime),-1)));
        setEventToggle(true);
        setaddedEvents(eventtt);
      }
    const openUserAccount = (linkk) => {
        history.push(`/${linkk}`);
        window.location.reload();
      }
      const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
      }

    return (
        <div className="eventactive">
            <div style={{
            backgroundColor: `${toevent.eventColor}`
          }} className="eventactive__detail">
                <div className="eventactive__left" style={{backgroundColor: `${curAccount.userDetail._id === toevent.createId ? "darkslategrey" : `${toevent.eventColor}`}`}}>
                {toevent.eventAdded > 100 ? <span className="eventactive__hot__badge">Hot</span> 
                :
                toevent.eventPromote ? 
                curAccount.userDetail._id === toevent.createId ?
                <span className="eventactive__promo__badge">Promoted</span> 
                :
                <span className="eventactive__promo__badge">Recommend</span> 
                :
                ""
                }
                 <img src={toevent.createImg} alt="eventactive__pic" className="eventactive__pic" onClick={() => openUserAccount(toevent.createLink)}/>   
                </div>
                <div className="eventactive__right">
                <div className="eventactive__up">
                    <div className="eventactive__name">{toevent.createName}</div>
                    {eventOnline ? <div className="eventactive__online">Live</div> : 
                    eventBeforeM === null ? "" : 
                      eventBeforeH === 0 ?
                      <div className="eventactive__before">{eventBeforeH}:{eventBeforeM}m{curAccount.userDetail._id !== toevent.createId ? <span className="icon" onClick={()=> handleAddEvent(toevent)}>group_add</span> : ""}</div>
                      :
                    <div className="eventactive__before">{eventBeforeH}h:{eventBeforeM}m{curAccount.userDetail._id !== toevent.createId ? <span className="icon" onClick={()=> handleAddEvent(toevent)}>group_add</span> : ""}</div>
                    }
                </div>
                    <div className="eventactive__info">[{new Date(toevent.eventDate).toString().substring(16,21)}-{new Date(toevent.eventEndDate).toString().substring(16,21)}]  {toevent.eventDetail.length > 20 ? `${toevent.eventDetail.substring(0,20)}...` : toevent.eventDetail}</div>
                        <div id="logo" className="eventactive__location" >
                            <div className="eventactive__location__left">
                            <span className="icon">{toevent.eventIcon}</span>{toevent.eventLocation.length > 23 ? `${toevent.eventLocation.substring(0,23)}...` : toevent.eventLocation} 
                            
                            </div>
                            <div className="eventactive__location__right">
                            {toevent.eventLocation.toLowerCase().toString().substring(0,4) === 'www.' ?
                            <span className="eventactive__map" onClick={() => openInNewTab(`https://${toevent.eventLocation}`)}>Link</span>
                            :
                            <span className="eventactive__map" onClick={() => openInNewTab(`https://google.co.th/maps/search/${toevent.eventLocation}/`)}>Map</span>
                            }
                            
                            </div>
                            
                        </div>
                        
                    
                </div>
                
            </div>
        </div>
    )
}

export default EventsActive