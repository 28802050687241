
export const GET_USER_FOLLOWING_REQUEST = "GET_USER_FOLLOWING_REQUEST";
export const GET_USER_FOLLOWING_SUCCESS = "GET_USER_FOLLOWING_SUCCESS";
export const GET_USER_FOLLOWING_FAIL = "GET_USER_FOLLOWING_FAIL";

export const GET_CURRENT_USER_FOLLOWING_REQUEST = "GET_CURRENT_USER_FOLLOWING_REQUEST";
export const GET_CURRENT_USER_FOLLOWING_SUCCESS = "GET_CURRENT_USER_FOLLOWING_SUCCESS";
export const GET_CURRENT_USER_FOLLOWING_FAIL = "GET_CURRENT_USER_FOLLOWING_FAIL";

export const GET_USER_FOLLOWERS_REQUEST = "GET_USER_FOLLOWERS_REQUEST";
export const GET_USER_FOLLOWERS_SUCCESS = "GET_USER_FOLLOWERS_SUCCESS";
export const GET_USER_FOLLOWERS_FAIL = "GET_USER_FOLLOWERS_FAIL";

export const GET_USER_FAVOURITE_REQUEST = "GET_USER_FAVOURITE_REQUEST";
export const GET_USER_FAVOURITE_SUCCESS = "GET_USER_FAVOURITE_SUCCESS";
export const GET_USER_FAVOURITE_FAIL = "GET_USER_FAVOURITE_FAIL";

export const GET_USER_FRIEND_REQUEST = "GET_USER_FRIEND_REQUEST";
export const GET_USER_FRIEND_SUCCESS = "GET_USER_FRIEND_SUCCESS";
export const GET_USER_FRIEND_FAIL = "GET_USER_FRIEND_FAIL";

export const CHECK_FAVOURITE_REQUEST = "CHECK_FAVOURITE_REQUEST";
export const CHECK_FAVOURITE_FAIL = "CHECK_FAVOURITE_FAIL";

export const CHECK_FRIEND_REQUEST = "CHECK_FRIEND_REQUEST";
export const CHECK_FRIEND_FAIL = "CHECK_FRIEND_FAIL";

export const CHECK_RELATION_REQUEST = "CHECK_RELATION_REQUEST";
export const CHECK_RELATION_FAIL = "CHECK_RELATION_FAIL";

export const ADD_TO_FOLLOWS = "ADD_TO_FOLLOWS";
export const ADD_TO_FOLLOWS_FAIL = "ADD_TO_FOLLOWS_FAIL";

export const UNFOLLOWS = "UNFOLLOWS";
export const UNFOLLOWS_FAIL = "UNFOLLOWS_FAIL";

export const GET_FOLLOWREQUEST_REQUEST = "GET_FOLLOWREQUEST_REQUEST";
export const GET_FOLLOWREQUEST_SUCCESS = "GET_FOLLOWREQUEST_SUCCESS";
export const GET_FOLLOWREQUEST_FAIL = "GET_FOLLOWREQUEST_FAIL";

export const GET_FOLLOWRESPONSE_REQUEST = "GET_FOLLOWRESPONSE_REQUEST";
export const GET_FOLLOWRESPONSE_SUCCESS = "GET_FOLLOWRESPONSE_SUCCESS";
export const GET_FOLLOWRESPONSE_FAIL = "GET_FOLLOWRESPONSE_FAIL";

export const GET_FOLLOWACKNOWLEDGE_REQUEST = "GET_FOLLOWACKNOWLEDGE_REQUEST";
export const GET_FOLLOWACKNOWLEDGE_SUCCESS = "GET_FOLLOWACKNOWLEDGE_SUCCESS";
export const GET_FOLLOWACKNOWLEDGE_FAIL = "GET_FOLLOWACKNOWLEDGE_FAIL";

export const UPDATE_REQUEST = "UPDATE_REQUEST";
export const UPDATE_REQUEST_FAIL = "UPDATE_REQUEST_FAIL";

export const UPDATE_ACKNOWLEDGE = "UPDATE_ACKNOWLEDGE";
export const UPDATE_ACKNOWLEDGE_FAIL = "UPDATE_ACKNOWLEDGE_FAIL";

export const UPDATE_FOLLOWRESPOND = "UPDATE_FOLLOWRESPOND";
export const UPDATE_FOLLOWRESPOND_FAIL = "UPDATE_FOLLOWRESPOND_FAIL";

export const UPDATE_FAVOURITE = "UPDATE_FAVOURITE";
export const UPDATE_FAVOURITE_FAIL = "UPDATE_FAVOURITE_FAIL";

export const UPDATE_FRIEND = "UPDATE_FRIEND";
export const UPDATE_FRIEND_FAIL = "UPDATE_FRIEND_FAIL";

