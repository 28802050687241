import './LoginDrawer.css'; 
import { useState,useEffect } from 'react'; 
import { Redirect } from 'react-router-dom'; 
import firebase from '../firebase';
import { Button,TextField } from "@material-ui/core";
import {useHistory} from 'react-router-dom';
import StyleFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import emailjs from 'emailjs-com';
import ButterToast , { Cinnamon } from "butter-toast";
import { CheckCircle } from "@material-ui/icons";



const LoginDrawer = ({show, loclick, session,setSession,curAccount,setregisterToggle,setLoginToggle,loginToggle}) => {

    const [feedToggle, setfeedToggle] = useState(false)
    useEffect(() => {
        if (loginToggle === false) {
            setfeedToggle(false);
        }
      }, [loginToggle]);
    let history = useHistory();
    var uiConfig = {
        signInFlow: "popup",
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ],
        callbacks: {
            signInSuccessWithAuthResult: async (authResult)=> {
            const userInfo = authResult.additionalUserInfo;
            if(userInfo.isNewUser && userInfo.providerId === 'password') {
                try {
                    await authResult.user.sendEmailVerification();
                    console.log('Check your email.');
                } catch (e) {
                    console.log('unable to send email');
                }
            }
            setLoginToggle(false);
              // User successfully signed in.
              //console.log("sign in success");
              
            return false;
              
              
            }
        },
    };
    

    const handleLogout = () => {
        firebase.auth().signOut().then(result => {
            setSession ({
                isLoggedIn: false,
                currentUser: null,
              });
              history.push("/");
              window.location.reload();
        });
        
    }
    const handleSendfeedback = (e) => {
        e.preventDefault(); 
        emailjs.sendForm(
            'service_follows-up',
            'template_follows-up',
            e.target,
            'user_AXnwKphsnNFtYegRJZgS2'
        ).then(res=>{
            ButterToast.raise({
                content:<Cinnamon.Crisp title="Thank you for your feedback"
                    content= {`We will reply as soon as possible`}
                    scheme = {Cinnamon.Crisp.SCHEME_GREEN}
                    icon={<CheckCircle />}
                />})
                setfeedToggle(false);
                setLoginToggle(false);
            
        }).catch(err => console.log(err));

    }

    const loginDrawerClass = ["logindrawer"]; 
    if (show) {
        loginDrawerClass.push("show");
    }


    return <div className={loginDrawerClass.join(" ")}>
    
    {session.isLoggedIn ? 
        curAccount === null ?
            <>
            <div className="user__main">
                    <img src={session.currentUser.photoURL} alt="user__pic" className="user__pic"/> 
                    <div className="user__name">{session.currentUser.displayName}</div>
                    <div className="user__email">{session.currentUser.email}</div>
                <div className="user__btn">
                    <Button variant="contained" color="secondary" className= "navbar_logout_btn" onClick={handleLogout}>
                                Sign Out
                    </Button>
                </div>
            </div>
            </>
        :
            <>
            <div className="user__setting" onClick={() => setregisterToggle(true)}>
                <span className="icon">settings</span>
            </div>
            <div className="user__main">
                <img src={curAccount.userDetail.imageUrl} alt="user__pic" className="user__pic"/> 
                <div className="user__name">{curAccount.userDetail.name}</div>
                <div className="user__email">{curAccount.userDetail.email}</div>
                <div className="user__link">Link: www.follows-up.com/{curAccount.userDetail.linkId}</div>
                <div className="user__pkey">Promote Event Avaliable: {curAccount.userDetail.pKey}<span className="user__pkey__buy">Buy more</span></div>
                {feedToggle ?
                    <>
                    <form onSubmit={e => handleSendfeedback(e)}>
                    <input style={{visibility:'hidden'}}type="text"name="user__email" defaultValue={`${curAccount.userDetail._id} - ${curAccount.userDetail.linkId} - ${curAccount.userDetail.email}`}/>
                    <div className ="user__feedback__text">
                    <TextField
                        name="feedbacktext"
                        variant="outlined"
                        label="Tell us some thing."
                        size="small"
                        multiline
                        fullWidth   
                    />
                    </div>
                    <div className="user__btn2">
                    <input 
                        className="feedback__send__btn"
                        type="submit"
                        value="Send"
                    />
                    </div>
                    </form>
                    </>
                :
                    <div className="user__btn2">
                    <Button variant="contained" size="small" color="primary" className= "navbar_feedback_btn" onClick ={()=> setfeedToggle(!feedToggle)}>
                                Send Feedback to us
                    </Button>
                    </div>
                }
                
                
                <div className="user__btn">
                    <Button variant="contained" color="secondary" className= "navbar_logout_btn" onClick={handleLogout}>
                                Sign Out
                    </Button>
                </div>
                
            </div>
            </>
    : 
    <>
    <Redirect  to="/" />
    <div className="logindrawer__title">Register / Login</div>
        <StyleFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
        {/*
        <ul className="logindrawer__links" onClick={loclick}>
            
            <li className="logindrawer__google" onClick={handleGoogle}>
                <Link to="/">Gmail</Link>
            </li>
            <li className="logindrawer__facebook" onClick={handleFacebook}>
                <Link to="/">facebook</Link>
            </li> 
            
        </ul>    */} 
    </>
    }
    </div>;
};

export default LoginDrawer

