import './SearchDrawer.css'; 

//Components
import AccountSearch from './AccountSearch';


const SearchDrawer = ({show, session,setSession,curAccount,setsearchToggle,searchAccount,allAccount,vfollowerMember,vfollowingMember,fromFollow,vAccount}) => {

    const searchDrawerClass = ["searchdrawer"]; 
    if (show) {
        searchDrawerClass.push("show");
    }


    return <div className={searchDrawerClass.join(" ")}>

    {fromFollow === 0 ?
    <div className="searchdrawer__title">Search Result</div>
    :
    fromFollow === 1 ?
    <div className="searchdrawer__title">{vAccount.vuserDetail.name}'s Followers account</div>
    :
    <div className="searchdrawer__title">{vAccount.vuserDetail.name}'s Following account</div>
    }
    


    {fromFollow === 0 ?
    
    searchAccount === "" ? "" :
    allAccount.filter(acc => {return acc.name.toLowerCase().includes(searchAccount.toLowerCase());}).length > 0 ? 
    allAccount.filter(acc => {return acc.name.toLowerCase().includes(searchAccount.toLowerCase());}).map(faccount => (
                    
                      <AccountSearch key={faccount._id} {...faccount} /> 
    )) : allAccount.filter(acc => {return acc.linkId.toLowerCase().includes(searchAccount.toLowerCase());}).length > 0 ?
    allAccount.filter(acc => {return acc.linkId.toLowerCase().includes(searchAccount.toLowerCase());}).map(faccount => (
                    
        <AccountSearch key={faccount._id} {...faccount} /> 
    ))
    :
    
    <div className="searchdrawer__not">Not have account</div>  
    
    :

    fromFollow === 1 ? 

    vfollowerMember.length > 0 ?
    vfollowerMember.map(followerAcc => (
        <AccountSearch key={followerAcc._id} {...followerAcc} /> 
    ))
    : <div className="searchdrawer__not">{vAccount.vuserDetail.name} not have follower</div>  

    :

    vfollowingMember.length -1 > 0 ?
    vfollowingMember.filter(x=> x._id !== vAccount.vuserDetail._id).map(followingAcc => (
        <AccountSearch key={followingAcc._id} {...followingAcc} /> 
    ))
    : <div className="searchdrawer__not">{vAccount.vuserDetail.name} not have following</div> 
    }
    </div>;
};

export default SearchDrawer

