import * as actionTypes from '../constants/withConstants';

export const getEventWithReducers = (state = { WithList: [] }, action) => {
    switch(action.type) {
        case actionTypes.GET_EVENT_WITH_REQUEST:
            return {
                loading: true,
            }
        case actionTypes.GET_EVENT_WITH_SUCCESS:
            return {
                loading: false,
                WithList: action.payload
            }
        case actionTypes.GET_EVENT_WITH_FAIL:
            return {
                loading: false,
                error: action.payload
            } 
        default:
            return state;
    }
}

export const addEventWithReducers = (state = { withRecord: [] }, action) => {
    switch(action.type) {
        case actionTypes.ADD_EVENT_WITH:
                return { 
                    ...state,
                    withRecord : [...state.withRecord,action.payload]
                }
        case actionTypes.ADD_EVENT_WITH_FAIL:
            return { 
                error: action.payload
            }
        default:
            return state;
    }
}

export const getInviteRequestReducers = (state = { inviteList: [] }, action) => {
    switch(action.type) {
        case actionTypes.GET_INVITEREQUEST_REQUEST:
            return {
                loading: true,
            }
        case actionTypes.GET_INVITEREQUEST_SUCCESS:
            return {
                loading: false,
                inviteList:action.payload
            }
        case actionTypes.GET_INVITEREQUEST_FAIL:
            return {
                loading: false,
                error: action.payload
            } 
        default:
            return state;
    }
}

export const getInviteResponseReducers = (state = { inviteResList: [] }, action) => {
    switch(action.type) {
        case actionTypes.GET_INVITERESPONSE_REQUEST:
            return {
                loading: true,
            }
        case actionTypes.GET_INVITERESPONSE_SUCCESS:
            return {
                loading: false,
                inviteResList:action.payload
            }
        case actionTypes.GET_INVITERESPONSE_FAIL:
            return {
                loading: false,
                error: action.payload
            } 
        default:
            return state;
    }
}

export const updateInviteReducers = (state = { invitedLog: {}}, action) => {
    switch(action.type) {
        case actionTypes.UPDATE_INVITEREQUEST:
            return {
                invitedLog:action.payload
            }
        case actionTypes.UPDATE_INVITEREQUEST_FAIL:
            return { 
                error: action.payload
            }
        default:
            return state;
    }
}

export const updateInviteResReducers = (state = { invitedResLog: {}}, action) => {
    switch(action.type) {
        case actionTypes.UPDATE_INVITERESPONSE:
            return {
                invitedResLog:action.payload
            }
        case actionTypes.UPDATE_INVITERESPONSE_FAIL:
            return { 
                error: action.payload
            }
        default:
            return state;
    }
}

export const updateResultReducers = (state = { resLog: {}}, action) => {
    switch(action.type) {
        case actionTypes.UPDATE_RESULT:
            return {
                resLog:action.payload
            }
        case actionTypes.UPDATE_RESULT_FAIL:
            return { 
                error: action.payload
            }
        default:
            return state;
    }
}

export const updateWithEventTypeReducers = (state = { withEventTypeLog: {}}, action) => {
    switch(action.type) {
        case actionTypes.UPDATE_WITHEVENTTYPE:
            return {
                withEventTypeLog:action.payload
            }
        case actionTypes.UPDATE_WITHEVENTTYPE_FAIL:
            return { 
                error: action.payload
            }
        default:
            return state;
    }
}

export const deleteEventWithReducers = (state = { deleteEventWithRecord: {}}, action) => {
    switch(action.type) {
        case actionTypes.DELETE_EVENT_WITH:
            return {
                deleteEventWithRecord:action.payload
            }
        case actionTypes.DELETE_EVENT_WITH_FAIL:
            return { 
                error: action.payload
            }
        default:
            return state;
    }
}

export const deleteAllEventWithReducers = (state = { deleteAllEventWithRecord: []}, action) => {
    switch(action.type) {
        case actionTypes.DELETE_ALLEVENT_WITH:
            return {
                deleteAllEventWithRecord:action.payload
            }
        case actionTypes.DELETE_ALLEVENT_WITH_FAIL:
            return { 
                error: action.payload
            }
        default:
            return state;
    }
}