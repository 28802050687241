import './SideDrawer.css'; 
import { Button } from "@material-ui/core";
import * as dateFns from 'date-fns';
import { useState } from 'react'; 

//Components
import EventsSide from './EventsSide';

const SideDrawer = ({show,selectEvents,selectDate,curAccount,vAccount,setfromNoti,setaddedEvents,setEventToggle,setSideToggle,sideToggle,seteditedEvents,seteditedWithEvents}) => {
    const [positop, setpositop] = useState(40);

    window.onscroll = function() {scrollFunction()};
    function scrollFunction() {
        //setpositop(document.documentElement.scrollTop);
        
        //console.log(document.body.scrollTop);
        //console.log(Math.round(document.documentElement.scrollTop));
        if (sideToggle) {
            setSideToggle(false);
        }
        if (document.documentElement.scrollTop < 40) {
            setpositop(40);
            
          
        } else {
            setpositop(Math.round(document.documentElement.scrollTop));
            
        }
        
      }
      
    let currentDate = new Date();
    let selectedDate = dateFns.addDays(new Date(selectDate),1);
    //console.log(currentDate <= selectedDate);
    const dateFormat = "EEEE d MMMM yyyy";
    const sideDrawerClass = ["sidedrawer"]; 
    if (show) {
        sideDrawerClass.push("show");
    }

    return <div style={{top: (positop) + 'px'}} className={sideDrawerClass.join(" ")}>
        <div className="sidedrawer__all" >
            <div className="sidedrawer__title">{dateFns.format(selectDate, dateFormat)}</div>
            
            <div className="sidedrawer__event">

                {selectEvents && selectEvents.length === 0
                ? ""
                : selectEvents.map(seevent => (
                    <EventsSide key={seevent._id} {...{seevent,vAccount,curAccount,setfromNoti,selectDate,setaddedEvents,setEventToggle,setSideToggle,sideToggle,seteditedEvents,seteditedWithEvents}}/> 
                ))}
               
            </div>
            
            {curAccount !== null && curAccount.userDetail._id === vAccount.vuserDetail._id? 
                currentDate >= selectedDate ?
                <Button variant="contained" color="primary" className= "sidedrawer_createBtn" onClick={()=>setEventToggle(true) || setSideToggle(false)}>
                +  Take Notes Event
                </Button>
                :
                <Button variant="contained" color="primary" className= "sidedrawer_createBtn" onClick={()=>setEventToggle(true) || setSideToggle(false)}>
                +  Create Event
                </Button>
            : selectEvents.length === 0 ?
            <div className="sidedrawer__not">Not have event</div>
            :
            ""}

            

            {/*
            <li>
                <Link to="/">Home</Link>
            </li>
            <li>
                <Link to="/loginPage">Project</Link>
            </li>
            */}
        </div>
    </div>;
};

export default SideDrawer

