import './EventsCreate.css';
import { useState , useEffect} from 'react'; 
import { Button, TextField, InputBase } from "@material-ui/core";
import * as dateFns from 'date-fns';
import Select from "react-select";
import { useSelector, useDispatch } from 'react-redux';
import ButterToast , { Cinnamon } from "butter-toast";
import { ArrowBackIosOutlined, Block } from "@material-ui/icons";
import Autocomplete from '@material-ui/lab/Autocomplete';

//Components
import CreateWith from './CreateWith';
import InviteWith from './InviteWith';

// Actions
import { getFriendListAct } from '../redux/actions/followActions';
import { addEventAct,updateEventAct,getEventLocationsAct,updateEventAddedAct } from '../redux/actions/eventActions';
import { addEventWithAct,deleteEventWithAct,deleteAllEventWithAct } from '../redux/actions/withActions';

const EventsCreate = ({show, eclick, session,setSession,selectDate,curAccount,addedEvents,setselectDate,editedEvents,editedWithEvents}) => {

  // ******************** Redux Store variable**********************
    const dispatch = useDispatch();
    const FriendLists = useSelector(state => state.getFriendLists); 
    const { friendList } = FriendLists;  
    const addEvent = useSelector(state => state.addEvents); 
    const { eventRecord } = addEvent; 
    const addEventWith = useSelector(state => state.addEventWiths); 
    const { withRecord } = addEventWith; 
    const updateEvent = useSelector(state => state.updateEvents); 
    const { eupdatedRecord } = updateEvent; 
    const getEventAllLocation = useSelector(state => state.getEventAllLocations); 
    const { allLocations } = getEventAllLocation; 
    const deleteEventWith = useSelector(state => state.deleteEventWiths); 
    const { deleteEventWithRecord } = deleteEventWith; 
    const deleteAllEventWith = useSelector(state => state.deleteAllEventWiths); 
    const { deleteAllEventWithRecord } = deleteAllEventWith; 
    const updateEventAdded = useSelector(state => state.updateEventAddeds); 
    const { eventAddLog } = updateEventAdded; 

  //********************** useState ******************************* */
    const [selectedTime, setselectedTime] = useState({
        startHour: "",
        startMinute: "",
        endHour: "",
        endMinute: ""
    });
    const [eventDetail, seteventDetail] = useState("")
    const [eventLocation, seteventLocation] = useState("")
    const [selectedColor, setselectedColor] = useState("")
    const [selectedIcon, setselectedIcon] = useState("")
    const [inviteToggle, setinviteToggle] = useState(false)
    const [allfriendList,setallfriendList] = useState([]);
    const [invitefriendList,setinvitefriendList] = useState([]);
    const [inviteSelect, setinviteSelect] = useState(false);
    const [diffTimeHour, setdiffTimeHour] = useState(0);
    const [diffTimeMin, setdiffTimeMin] = useState(0);
    const [timeNg, settimeNg] = useState(false);
    const [searchFriend, setsearchFriend] = useState("");
    const [locationList,setlocationList] = useState([]);
    const [isLink, setisLink] = useState(false)

    //************************************************************* */

    //***************** Variable******************************* */
    const dateFormat = "EEEE d MMMM yyyy";
    //********************************************************* */

    //**********************Function *************************** */
    const eventsCreateClass = ["eventscreate"]; 
    if (show) {
        eventsCreateClass.push("show");
    }
    const requestFriendList = (Id) => { 
      dispatch(getFriendListAct(Id));
      //console.log("request Friend List Success");
    };
    const requestLocationList = () => { 
      dispatch(getEventLocationsAct());
      //console.log("request Location List Success");
    };
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
    //*********************************************************** */

    // ************* Handler Function ****************************
  const createBtnHandler = (withuser) => {
    let eventtimeId = `${dateFns.format(new Date(),"yyMMddHHmmss")}${curAccount.userDetail._id}`;
    if (eventDetail === "") {
      ButterToast.raise({
        content:<Cinnamon.Crisp title="Can't Create Event"
            content="Please Input Event Detail"
            scheme = {Cinnamon.Crisp.SCHEME_RED}
            icon={<Block />}
        />})
    } else if (eventLocation === "") {
      ButterToast.raise({
        content:<Cinnamon.Crisp title="Can't Create Event"
            content="Please Input Event Location"
            scheme = {Cinnamon.Crisp.SCHEME_RED}
            icon={<Block />}
        />})
    } else if (selectedTime.startMinute === "" || selectedTime.startHour === "" || selectedTime.endMinute === "" || selectedTime.endHour === "" || timeNg) {
      if (timeNg) {
        ButterToast.raise({
          content:<Cinnamon.Crisp title="Can't Create Event"
              content="Please Input Correct time"
              scheme = {Cinnamon.Crisp.SCHEME_RED}
              icon={<Block />}
          />})
      }else {
        ButterToast.raise({
          content:<Cinnamon.Crisp title="Can't Create Event"
              content="Please Input Event Time"
              scheme = {Cinnamon.Crisp.SCHEME_RED}
              icon={<Block />}
          />})
      }
      
    } else if (selectedColor === "") {
      ButterToast.raise({
        content:<Cinnamon.Crisp title="Can't Create Event"
            content="Please Choose Event Color"
            scheme = {Cinnamon.Crisp.SCHEME_RED}
            icon={<Block />}
        />})
    } else if (selectedIcon === "") {
      ButterToast.raise({
        content:<Cinnamon.Crisp title="Can't Create Event"
            content="Please Choose Event Icon"
            scheme = {Cinnamon.Crisp.SCHEME_RED}
            icon={<Block />}
        />})
    } else {
      if(addedEvents !== null) {
        dispatch(updateEventAddedAct(addedEvents.eventId));
      }
      if (withuser.length > 0) {
        const eventownwithdataa = [
          {
            eventId: eventtimeId, 
            createId: curAccount.userDetail._id,
            inviteId: curAccount.userDetail._id,
            result: true,
            inviteRequest: false,
            inviteResponse: false,
            eventType: 1,
          },
        ];
        dispatch(addEventWithAct(eventownwithdataa));
        for (let i = 0; i < withuser.length; i++) {
          const eventwithdataa = [
            {
              eventId: eventtimeId, 
              createId: curAccount.userDetail._id,
              inviteId: withuser[i]._id,
              result: false,
              inviteRequest: true,
              inviteResponse: false,
              eventType: 1,
            },
          ];
          dispatch(addEventWithAct(eventwithdataa));
        }
      }
      const eventdataa = [
        {
          eventId: eventtimeId,
          createId: curAccount.userDetail._id,
          eventDetail: eventDetail,
          eventLocation: eventLocation,
          eventDate : dateFns.addHours(dateFns.addMinutes(selectDate, selectedTime.startMinute.value),selectedTime.startHour.value).toISOString(),    
          eventEndDate : dateFns.addHours(dateFns.addMinutes(selectDate, selectedTime.endMinute.value),selectedTime.endHour.value).toISOString(), 
          eventIcon: selectedIcon,
          eventColor: selectedColor,
          eventShareType: 1,
          eventAdded: 0,
          eventPromote: false,
        },
      ];
      dispatch(addEventAct(eventdataa));
      //window.location.reload();
    }
    }

    const editBtnHandler = (withuser) => {
      if (eventDetail === "") {
        ButterToast.raise({
          content:<Cinnamon.Crisp title="Can't Create Event"
              content="Please Input Event Detail"
              scheme = {Cinnamon.Crisp.SCHEME_RED}
              icon={<Block />}
          />})
      } else if (eventLocation === "") {
        ButterToast.raise({
          content:<Cinnamon.Crisp title="Can't Create Event"
              content="Please Input Event Location"
              scheme = {Cinnamon.Crisp.SCHEME_RED}
              icon={<Block />}
          />})
      } else if (selectedTime.startMinute === "" || selectedTime.startHour === "" || selectedTime.endMinute === "" || selectedTime.endHour === "" || timeNg) {
        if (timeNg) {
          ButterToast.raise({
            content:<Cinnamon.Crisp title="Can't Create Event"
                content="Please Input Correct time"
                scheme = {Cinnamon.Crisp.SCHEME_RED}
                icon={<Block />}
            />})
        }else {
          ButterToast.raise({
            content:<Cinnamon.Crisp title="Can't Create Event"
                content="Please Input Event Time"
                scheme = {Cinnamon.Crisp.SCHEME_RED}
                icon={<Block />}
            />})
        }
        
      } else if (selectedColor === "") {
        ButterToast.raise({
          content:<Cinnamon.Crisp title="Can't Create Event"
              content="Please Choose Event Color"
              scheme = {Cinnamon.Crisp.SCHEME_RED}
              icon={<Block />}
          />})
      } else if (selectedIcon === "") {
        ButterToast.raise({
          content:<Cinnamon.Crisp title="Can't Create Event"
              content="Please Choose Event Icon"
              scheme = {Cinnamon.Crisp.SCHEME_RED}
              icon={<Block />}
          />})
      } else {
        if (withuser.length > 0)
        {
          if (editedWithEvents.length === 0) {
            const eventownwithdataa = [
              {
                eventId: addedEvents.eventId, 
                createId: curAccount.userDetail._id,
                inviteId: curAccount.userDetail._id,
                result: true,
                inviteRequest: false,
                inviteResponse: false,
                eventType: 1,
              },
            ];
            dispatch(addEventWithAct(eventownwithdataa));
            withuser.map((item) => {
              const eventwithdataa = [
                {
                  eventId: addedEvents.eventId, 
                  createId: curAccount.userDetail._id,
                  inviteId: item._id,
                  result: false,
                  inviteRequest: true,
                  inviteResponse: false,
                  eventType: 1,
                },
              ];
              dispatch(addEventWithAct(eventwithdataa));
             });
          } 
          else 
          {
            withuser.map((item) => {
                  if(typeof(editedWithEvents.filter((y) => y.inviteId === item._id)[0]) === 'undefined') //มีใน editWith อยู่แล้ว
                  {
                    //console.log(`add ${item._id}`);
                    const eventwithdataa = [
                      {
                        eventId: addedEvents.eventId, 
                        createId: curAccount.userDetail._id,
                        inviteId: item._id,
                        result: false,
                        inviteRequest: true,
                        inviteResponse: false,
                        eventType: 1,
                      },
                    ];
                    dispatch(addEventWithAct(eventwithdataa));
                  }
            });
            editedWithEvents.map((x) => {
              if(typeof(withuser.filter((yy) => yy._id === x.inviteId)[0]) === 'undefined'){
                //console.log(`remove ${x.inviteId}`);
                dispatch(deleteEventWithAct(addedEvents.eventId,x.inviteId));
              }
            });
             
          }
        } 
        else 
        {
          if (editedWithEvents.length > 0) {
            //console.log("remove all")
            dispatch(deleteAllEventWithAct(addedEvents.eventId));
          }
        }
        const eupdatedata = [
            {
              eventId: addedEvents.eventId,
              createId: addedEvents.createId,
              eventDetail: eventDetail,
              eventLocation: eventLocation,
              eventDate : dateFns.addHours(dateFns.addMinutes(selectDate, selectedTime.startMinute.value),selectedTime.startHour.value).toISOString(),    
              eventEndDate : dateFns.addHours(dateFns.addMinutes(selectDate, selectedTime.endMinute.value),selectedTime.endHour.value).toISOString(), 
              eventIcon: selectedIcon,
              eventColor: selectedColor,
              eventShareType: addedEvents.eventShareType,
              eventAdded: addedEvents.eventAdded,
              eventPromote: addedEvents.eventPromote,
            },
        ];
        dispatch(updateEventAct(addedEvents.eventId,eupdatedata));
    }
  }
    

    
  //************************************************************* */

    //****************************** Option ********************************************* 
    let currentDate = new Date()
    let selectedDate = new Date(selectDate);
    let minHour = 0
    if (currentDate <= selectedDate) {
      minHour = 0;
    } else {
      minHour = dateFns.format(new Date(),"H");
    }
    const colorOptions = ["moccasin","khaki","lavender","powderblue","mistyrose","lightpink","thistle","skyblue","palegreen","tan"];

    const iconOptions = ["shopping_cart","coffee","dining","sports_soccer","fitness_center","directions_run","skateboarding","work","videocam"
                          ,"fastfood","ramen_dining","outdoor_grill","sports_basketball","pool", "pedal_bike","surfing","mic_external_on","music_video"
                          ,"liquor","nightlife","sports_bar","park","auto_stories","local_movies","hiking","flight_takeoff","local_hospital"];
    let minuteOptions = [];
      for (let i2 = 0; i2 <= 55; i2=i2+5) {
          if (i2 === 0 || i2 === 5) {
            minuteOptions.push({value: i2, label: `0${i2}`});
          } else {
            minuteOptions.push({value: i2, label: `${i2}`});
          }
        
      }

    let hourOptions = [];
      for (let i = 0; i <= 23; i++) {
          if (i < 10) {
            hourOptions.push({value: i, label: `0${i}`});
          } else {
            hourOptions.push({value: i, label: `${i}`});
          }
        
      }
  //****************************************************************************** */

  // ************** useEffect && if condition *****************
    useEffect(() => {
      if (curAccount !== null) {
        requestFriendList(curAccount.userDetail._id);
        requestLocationList();
      }
    }, [curAccount]);

    useEffect(() => {
      if (typeof(friendList) !== 'undefined' && friendList.length > 0)
      {   
                setallfriendList(friendList.map(x => { return x.friendUser[0];}));
      }
    }, [friendList]);
    useEffect(() => {
      if (typeof(allLocations) !== 'undefined' && allLocations.length > 0)
      {   
                setlocationList(allLocations);
      }
    }, [allLocations]);
    if (inviteSelect)
    {
      setinviteSelect(false);
    }

    useEffect(() => {
      if (addedEvents !== null) {
        setselectedColor(addedEvents.eventColor);
        seteventDetail(addedEvents.eventDetail);
        seteventLocation(addedEvents.eventLocation);
        setselectedTime({startHour : {value: dateFns.getHours(new Date(addedEvents.eventDate)), label: `${dateFns.getHours(new Date(addedEvents.eventDate)) < 10 ? `0${dateFns.getHours(new Date(addedEvents.eventDate))}` : dateFns.getHours(new Date(addedEvents.eventDate))}`},
                        startMinute : {value: dateFns.getMinutes(new Date(addedEvents.eventDate)), label: `${dateFns.getMinutes(new Date(addedEvents.eventDate)) === 0 || dateFns.getMinutes(new Date(addedEvents.eventDate)) === 5 ? `0${dateFns.getMinutes(new Date(addedEvents.eventDate))}` : dateFns.getMinutes(new Date(addedEvents.eventDate))}`},
                        endHour: {value: dateFns.getHours(new Date(addedEvents.eventEndDate)), label: `${dateFns.getHours(new Date(addedEvents.eventEndDate)) < 10 ? `0${dateFns.getHours(new Date(addedEvents.eventEndDate))}` : dateFns.getHours(new Date(addedEvents.eventEndDate))}`}, 
                        endMinute: {value: dateFns.getMinutes(new Date(addedEvents.eventEndDate)), label: `${dateFns.getMinutes(new Date(addedEvents.eventEndDate)) === 0 || dateFns.getMinutes(new Date(addedEvents.eventEndDate)) === 5 ? `0${dateFns.getMinutes(new Date(addedEvents.eventEndDate))}` : dateFns.getMinutes(new Date(addedEvents.eventEndDate))}`}})
        setselectedIcon(addedEvents.eventIcon);
        if (editedEvents === false)
        {
          setinviteToggle(true);
        } 
        else 
        {
          
          if (editedWithEvents.length > 0) 
          {
            let arr = invitefriendList;
            let arr2 = allfriendList;
            editedWithEvents.map((item) => {
              if(item.inviteId === allfriendList.filter((x) => x._id === item.inviteId)[0]._id){
                arr.push(allfriendList.filter((x) => x._id === item.inviteId)[0]);
                setinvitefriendList(arr);
                const index = arr2.indexOf(arr2.filter((x) => x._id === item.inviteId)[0]);
                if (index > -1) {
                  arr2.splice(index, 1);
                }
                setallfriendList(arr2);
              }
            });
          }
         }
      }
      else {
        setselectedColor("");
        seteventDetail("");
        seteventLocation("");
        setselectedIcon("");
        setselectedTime({
          startHour: "",
          startMinute: "",
          endHour: "",
          endMinute: ""
        });
        setdiffTimeHour(0);
        setdiffTimeMin(0);
        
        if(invitefriendList.length > 0){
        let arr = allfriendList;
          invitefriendList.map((item) => {arr.push(item);});
        setinvitefriendList([]);
        setallfriendList(arr);
        }
      }
      
    }, [addedEvents]);


    useEffect(() => {
      if (selectedTime.startMinute !== "" && selectedTime.startHour !== "" && selectedTime.endMinute !== "" && selectedTime.endHour !== "") {
        let st = dateFns.addHours(dateFns.addMinutes(selectDate, selectedTime.startMinute.value),selectedTime.startHour.value);
        let et = dateFns.addHours(dateFns.addMinutes(selectDate, selectedTime.endMinute.value),selectedTime.endHour.value);
        var resulthour = dateFns.differenceInHours(
          et,
          st
        )
        var resultmin = dateFns.differenceInMinutes(
          et,
          st
        )
        setdiffTimeHour(resulthour);
        setdiffTimeMin(resultmin - (resulthour*60));
        
        
        
        if(resulthour < 0) {
          settimeNg(true);
        }  
        else if (resulthour === 0)
        {
          if(resultmin <= 0) {
            settimeNg(true);
          } else {
            settimeNg(false);
          }
        } 
        else 
        {
          settimeNg(false);
        }
      }
        
    
    }, [selectedTime]);
    useEffect(() => {
      if (eventLocation.toLowerCase().toString().substring(0,4) === 'http' || eventLocation.toLowerCase().toString().substring(0,4) === 'www.') {
        seteventLocation(eventLocation.toString().substring(eventLocation.search(/[www]/g,eventLocation.length)));
        setisLink(true);
      } else {
        setisLink(false);
      }
    }, [eventLocation]);
// ******************************************************


    return <div className={eventsCreateClass.join(" ")}>
    <div className={`${inviteToggle ? "create__maininvite" : "create__main"} `}>
    
      {invitefriendList && invitefriendList.length === 0 ? <div className="create__invite__amount">Invite friend</div>
      :
      <div className="create__invite__amount">+ {invitefriendList.length} person</div>
      }
      
      <div className="create__invite">
          {inviteToggle ? 
          <span className="icon" onClick={() => setinviteToggle(!inviteToggle)}>check_circle</span>
          :
          <span className="icon" onClick={() => setinviteToggle(!inviteToggle)}>group_add</span>
          }
          
      </div>
      
      <div className={`${inviteToggle ? "create__leftshow" : "create__lefthide"} `}>
        {editedEvents ?
        <>
        <div className="create__title">Edit Event</div>
        
        <div className="create__date">
          <div></div>
          <div className="datee">{dateFns.format(selectDate, dateFormat)}</div>
          <div></div>
          </div>
          
        </>
        :
        addedEvents !== null ?
        <>
        <div className="create__title">Add Event</div>
        
        <div className="create__date">
          <div></div>
          <div className="datee">{dateFns.format(selectDate, dateFormat)}</div>
          <div></div>
          </div>
        </>
        :
        currentDate >= dateFns.addDays(new Date(selectDate),1) ?
        <>
        <div className="create__title">Take Notes Event</div>
        <div className="create__date">
          <div className="icon" onClick={()=> setselectDate(dateFns.addDays(selectDate,-1))}>chevron_left</div>
          <div className="datee">{dateFns.format(selectDate, dateFormat)}</div>
          <div className="icon" onClick={()=> setselectDate(dateFns.addDays(selectDate,1))}>chevron_right</div>
        </div>
        </>
        :
        <>
        <div className="create__title">Create Event</div>
        <div className="create__date">
          <div className="icon" onClick={()=> setselectDate(dateFns.addDays(selectDate,-1))}>chevron_left</div>
          <div className="datee">{dateFns.format(selectDate, dateFormat)}</div>
          <div className="icon" onClick={()=> setselectDate(dateFns.addDays(selectDate,1))}>chevron_right</div>
        </div>
        </>
        }
        

        <div className="create__optioncolor">
          {colorOptions.map(color => (
                  <div style={{
                    backgroundColor: `${color}`
                  }} onClick={() => setselectedColor(color)} className={`${selectedColor === color ? "create__selectcolor" : "create__color"} `}> </div>
            ))}
        </div>
        
        <div className ="create__detail">
        <TextField
                    name="detail"
                    variant="outlined"
                    label="Event Detail"
                    fullWidth   
                    value= {eventDetail}
                    onChange={e => seteventDetail(e.target.value)}
        />
        </div>
        
        <div className ="create__alllocation">
          <div className ="create__leftlocation">


          <Autocomplete
           value= {eventLocation}
            onChange={(event, newValue) => {
                if (newValue !== null) {
                  seteventLocation(newValue);
                }
              }}
            inputValue={eventLocation}
            onInputChange={(_, newInputValue) => {
              if (newInputValue !== null) {
                seteventLocation(newInputValue)
              }
            }}
            id="free-solo-demo"
            maxmenuheight={220}
            options={locationList.filter(acc => {return acc.toLowerCase().includes(eventLocation.toLowerCase());})}
            freeSolo
            renderInput={(params) => (
              <TextField
                      {...params}
                      name="location"
                      variant="outlined"
                      label="Event Location or Link(www.) "
                      className ="create__location"
                      fullWidth
                      value= {eventLocation}
                      onChange={e => seteventLocation(e.target.value)}    
              />
            )}
          />


                    
          </div>
          {isLink ?
          <div className ="create__rightlocation">
            <Button variant="contained" 
              color="primary"
              type="submit"
              className="create__checklocation"
              onClick={() => openInNewTab(`https://${eventLocation}`) }
            >Open Link</Button>
          </div>
          :
          <div className ="create__rightlocation">
            <Button variant="contained" 
              color="primary"
              type="submit"
              className="create__checklocation"
              onClick={() => 
                eventLocation === "" ? window.confirm('Search, Copy and Paste Location name in Event Location Box. Next time, you can type this location in Event Location Box.') ? openInNewTab(`https://google.co.th/maps`) : ""
                : openInNewTab(`https://google.co.th/maps/search/${eventLocation}/`) }
            >Search In Map</Button>
          </div>
          }
          
        </div>
        <div className="create__timetitle">
            <div className="title__starttime">Start</div>
            <div className="title__endtime">End</div>
            {diffTimeMin < 0  ?
                <div className="title__duration__NG">
                  Duration:  {diffTimeHour} Hour {diffTimeMin} Minute 
                </div>
            :
              diffTimeHour < 0 ?
                <div className="title__duration__NG">
                  Duration:  {diffTimeHour} Hour {diffTimeMin} Minute 
                </div>
                :
                diffTimeMin === 0 &&  diffTimeHour === 0 ?
                <div className="title__duration__NG">
                  Duration:  {diffTimeHour} Hour {diffTimeMin} Minute 
                </div>
                :
                <div className="title__duration__OK">
                  Duration:  {diffTimeHour} Hour {diffTimeMin} Minute 
                </div>
            }
            

        </div>
        <div className="create__time">
            <div className="create__starthour">
              <Select
                value={selectedTime.startHour}
                options={hourOptions}
                onChange={e => setselectedTime({startHour :e,startMinute: selectedTime.startMinute, endHour: selectedTime.endHour, endMinute: selectedTime.endMinute})}
                placeholder="hour"
                isSearchable={false}
                maxmenuheight={215}
              />
            </div>
            <div className="create__startminute">
              <Select
                value={selectedTime.startMinute}
                options={minuteOptions}
                onChange={e => setselectedTime({startHour : selectedTime.startHour,startMinute :e, endHour: selectedTime.endHour, endMinute: selectedTime.endMinute})}
                placeholder="min"
                isSearchable={false}
                maxmenuheight={215}
              />
            </div>
            <div className="create__endhour">
              <Select
                value={selectedTime.endHour}
                options={hourOptions}
                onChange={e => setselectedTime({startHour :selectedTime.startHour,startMinute: selectedTime.startMinute, endHour: e, endMinute: selectedTime.endMinute})}
                placeholder="hour"
                isSearchable={false}
                maxmenuheight={215}
              />
            </div>
            <div className="create__endminute">
              <Select
                value={selectedTime.endMinute}
                options={minuteOptions}
                onChange={e => setselectedTime({startHour : selectedTime.startHour,startMinute :selectedTime.startMinute, endHour: selectedTime.endHour, endMinute: e})}
                placeholder="min"
                isSearchable={false}
                maxmenuheight={215}
              />
            </div>
        </div>
        <div className="create__optionicon">
        {iconOptions.map(iconn => (
                  <div style={{
                    backgroundColor: `${selectedColor}`
                  }}
                  onClick={() => setselectedIcon(iconn)} className={`${selectedIcon === iconn ? "create__selectedicon" : "create__icon"} `}> 
                    <span className="icon">{iconn}</span>
                  </div>
            ))}
        </div>
        {editedEvents ?
        <div className="create__createbtn" >
        <Button variant="contained" 
                color="primary"
                type="submit"
                className="aaaaa"
                onClick={() => editBtnHandler(invitefriendList)}
                >Edit</Button>
        </div>
        :
        currentDate >= dateFns.addDays(new Date(selectDate),1) ?
        <div className="create__createbtn" >
        <Button variant="contained" 
                color="primary"
                type="submit"
                className="aaaaa"
                onClick={() => createBtnHandler(invitefriendList)}
                >Take Notes</Button>
        </div>
        :
        <div className="create__createbtn" >
        <Button variant="contained" 
                color="primary"
                type="submit"
                className="aaaaa"
                onClick={() => createBtnHandler(invitefriendList)}
                >Create</Button>
        </div>
        }
      </div>

    
      <div className={`${inviteToggle ? "create__centershow" : "create__centerhide"} `}>
        {inviteToggle ? 
        <>
        
          <div className="create__title"></div>
          <div className="create__with">+</div>
          
          {invitefriendList && invitefriendList.length === 0
            ? ""
            : invitefriendList.map(friendWith => (
                <InviteWith key={friendWith._id} friendWith={friendWith} invitefriendList={invitefriendList} setinvitefriendList={setinvitefriendList} setinviteSelect={setinviteSelect} allfriendList={allfriendList} setallfriendList={setallfriendList}/> 
            ))}
          

        </>
        :
        ""}
      </div>
      <div className={`${inviteToggle ? "create__rightshow" : "create__righthide"} `}>
        {inviteToggle ? 
        <>
          <div className="create__title"></div>
          <InputBase
                        className="friend__search"
                        placeholder="Search Friend Name"
                        inputProps={{ 'aria-label': 'search google maps' }}
                        onChange={e => setsearchFriend(e.target.value)}
                    />
          <div className="create__friendlist">
          
          {allfriendList && allfriendList.length === 0
                    ? 
                    <>
                    <div className="create__friendlist__not">Not Friends avaliable</div>
                    <div className="create__friendlist__not">[Friend: Following each other]</div>
                    </>
                    : 
                    searchFriend === "" ?
                    allfriendList.map(friend => (
                      <CreateWith key={friend._id} friend={friend} invitefriendList={invitefriendList} setinvitefriendList={setinvitefriendList} setinviteSelect={setinviteSelect} allfriendList={allfriendList} setallfriendList={setallfriendList}/> 
                     ))
                    :
                    allfriendList.filter(acc => {return acc.name.toLowerCase().includes(searchFriend.toLowerCase());}).length > 0 ?
                    allfriendList.filter(acc => {return acc.name.toLowerCase().includes(searchFriend.toLowerCase());}).map(friend => (
                      <CreateWith key={friend._id} friend={friend} invitefriendList={invitefriendList} setinvitefriendList={setinvitefriendList} setinviteSelect={setinviteSelect} allfriendList={allfriendList} setallfriendList={setallfriendList}/> 
                     ))
                    :
                    <div className="create__friendlist__not">Not have friend avaliable</div>
                    }
         
           </div>
          
        </>
        :
        ""}
      </div>
    </div>
    
  </div>;
};

export default EventsCreate