import * as actionTypes from '../constants/followConstants';


export const getFollowingReducers = (state = { followingList: [] }, action) => {
    switch(action.type) {
        case actionTypes.GET_USER_FOLLOWING_REQUEST:
            return {
                loading: true,
            }
        case actionTypes.GET_USER_FOLLOWING_SUCCESS:
            return {
                loading: false,
                followingList:action.payload
            }
        case actionTypes.GET_USER_FOLLOWING_FAIL:
            return {
                loading: false,
                error: action.payload
            } 
        default:
            return state;
    }
}

export const getCurrentFollowingReducers = (state = { currentfollowingList: [] }, action) => {
    switch(action.type) {
        case actionTypes.GET_CURRENT_USER_FOLLOWING_REQUEST:
            return {
                loading: true,
            }
        case actionTypes.GET_CURRENT_USER_FOLLOWING_SUCCESS:
            return {
                loading: false,
                currentfollowingList:action.payload
            }
        case actionTypes.GET_CURRENT_USER_FOLLOWING_FAIL:
            return {
                loading: false,
                error: action.payload
            } 
        default:
            return state;
    }
}

export const getFollowerReducers = (state = { followerList: [] }, action) => {
    switch(action.type) {
        case actionTypes.GET_USER_FOLLOWERS_REQUEST:
            return {
                loading: true,
            }
        case actionTypes.GET_USER_FOLLOWERS_SUCCESS:
            return {
                loading: false,
                followerList:action.payload
            }
        case actionTypes.GET_USER_FOLLOWERS_FAIL:
            return {
                loading: false,
                error: action.payload
            } 
        default:
            return state;
    }
}

export const getFriendReducers = (state = { friendList: [] }, action) => {
    switch(action.type) {
        case actionTypes.GET_USER_FRIEND_REQUEST:
            return {
                loading: true,
            }
        case actionTypes.GET_USER_FRIEND_SUCCESS:
            return {
                loading: false,
                friendList:action.payload
            }
        case actionTypes.GET_USER_FRIEND_FAIL:
            return {
                loading: false,
                error: action.payload
            } 
        default:
            return state;
    }
}

export const checkFavouriteReducers = (state = { resultFavourite: {} }, action) => {
    switch(action.type) {
        case actionTypes.CHECK_FAVOURITE_REQUEST:
            return {
                resultFavourite: action.payload
            }
        case actionTypes.CHECK_FAVOURITE_FAIL:
            return {
                error: action.payload
            } 
        default:
            return state;
    }
}

export const checkFriendReducers = (state = { resultFriend: {} }, action) => {
    switch(action.type) {
        case actionTypes.CHECK_FRIEND_REQUEST:
            return {
                resultFriend: action.payload
            }
        case actionTypes.CHECK_FRIEND_FAIL:
            return {
                error: action.payload
            } 
        default:
            return state;
    }
}


export const addFollowReducers = (state = { followRecord: [] }, action) => {
    switch(action.type) {
        case actionTypes.ADD_TO_FOLLOWS:
                return { //[เหมือนให้ CREATE ไม่มีใน cart ให้ เพิ่มเข้าไปเลย *item คือชุดข้อมูลที่กดเข้าไปใหม่]
                    ...state,
                    followRecord : [...state.followRecord,action.payload]
                }
        case actionTypes.ADD_TO_FOLLOWS_FAIL:
            return { 
                error: action.payload
            }
        default:
            return state;
    }
}

export const unFollowReducers = (state = { unfollowLog: {}}, action) => {
    switch(action.type) {
        case actionTypes.UNFOLLOWS:
            return {
                unfollowLog:action.payload
            }
        case actionTypes.UNFOLLOWS_FAIL:
            return { 
                error: action.payload
            }
        default:
            return state;
    }
}

export const getFollowRequestReducers = (state = { requestList: [] }, action) => {
    switch(action.type) {
        case actionTypes.GET_FOLLOWREQUEST_REQUEST:
            return {
                loading: true,
            }
        case actionTypes.GET_FOLLOWREQUEST_SUCCESS:
            return {
                loading: false,
                requestList:action.payload
            }
        case actionTypes.GET_FOLLOWREQUEST_FAIL:
            return {
                loading: false,
                error: action.payload
            } 
        default:
            return state;
    }
}
export const getFollowResponseReducers = (state = { responsefList: [] }, action) => {
    switch(action.type) {
        case actionTypes.GET_FOLLOWRESPONSE_REQUEST:
            return {
                loading: true,
            }
        case actionTypes.GET_FOLLOWRESPONSE_SUCCESS:
            return {
                loading: false,
                responsefList:action.payload
            }
        case actionTypes.GET_FOLLOWRESPONSE_FAIL:
            return {
                loading: false,
                error: action.payload
            } 
        default:
            return state;
    }
}
export const getFollowAcknowledgeReducers = (state = { acknowledgeList: [] }, action) => {
    switch(action.type) {
        case actionTypes.GET_FOLLOWACKNOWLEDGE_REQUEST:
            return {
                loading: true,
            }
        case actionTypes.GET_FOLLOWACKNOWLEDGE_SUCCESS:
            return {
                loading: false,
                acknowledgeList:action.payload
            }
        case actionTypes.GET_FOLLOWACKNOWLEDGE_FAIL:
            return {
                loading: false,
                error: action.payload
            } 
        default:
            return state;
    }
}
export const checkRelationReducers = (state = { resultRelation: {}} , action) => {
    switch(action.type) {
        case actionTypes.CHECK_RELATION_REQUEST:
            return {
                resultRelation:action.payload
            }
        case actionTypes.CHECK_RELATION_FAIL:
            return {
                error: action.payload
            } 
        default:
            return state;
    }
}

export const updateRequestReducers = (state = { requestLog: {}}, action) => {
    switch(action.type) {
        case actionTypes.UPDATE_REQUEST:
            return {
                requestLog:action.payload
            }
        case actionTypes.UPDATE_REQUEST_FAIL:
            return { 
                error: action.payload
            }
        default:
            return state;
    }
}

export const updateResponsefReducers = (state = { responsefLog: {}}, action) => {
    switch(action.type) {
        case actionTypes.UPDATE_FOLLOWRESPOND:
            return {
                responsefLog:action.payload
            }
        case actionTypes.UPDATE_FOLLOWRESPOND_FAIL:
            return { 
                error: action.payload
            }
        default:
            return state;
    }
}

export const updateAcknowledgeReducers = (state = { acknowledgeLog: {}}, action) => {
    switch(action.type) {
        case actionTypes.UPDATE_ACKNOWLEDGE:
            return {
                acknowledgeLog:action.payload
            }
        case actionTypes.UPDATE_ACKNOWLEDGE_FAIL:
            return { 
                error: action.payload
            }
        default:
            return state;
    }
}

export const updateFavouriteReducers = (state = { favouriteLog: {}}, action) => {
    switch(action.type) {
        case actionTypes.UPDATE_FAVOURITE:
            return {
                favouriteLog:action.payload
            }
        case actionTypes.UPDATE_FAVOURITE_FAIL:
            return { 
                error: action.payload
            }
        default:
            return state;
    }
}

export const updateFriendReducers = (state = { friendLog: {}}, action) => {
    switch(action.type) {
        case actionTypes.UPDATE_FRIEND:
            return {
                friendLog:action.payload
            }
        case actionTypes.UPDATE_FRIEND_FAIL:
            return { 
                error: action.payload
            }
        default:
            return state;
    }
}