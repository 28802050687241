import './EventsCalendar.css';

const EventsCalendar = ({...event}) => {
    
    return (
        <div style={{
            backgroundColor: `${event.eventColor}`
          }} className="event__calendar">
            <span className="icon">{event.eventIcon}</span><p>[{new Date(event.eventDate).toString().substring(16,21)}]</p><p>{event.eventLocation}</p>
        </div>
    )
}

export default EventsCalendar