import React, { useState } from 'react';
import './Schedule.css';
import * as dateFns from 'date-fns';
//Components
import EventsCalendar from './EventsCalendar';



const Schedule = ({Amount,EventDetailList,
                    previewToggle,setSideToggle,setselectEvents,setselectDate,eventTodayLoaded,allLoaded,curAccount,vAccount,isLoggedIn,userstat,friendToggle,mobileSize}) => {
    const [state,setState] = useState({
        currentMonth: new Date(),
        selectedDate: new Date()
      });
      const [eventsInitial, seteventsInitial] = useState(true);
      const [testDate, settestDate] = useState(new Date());
      if (eventsInitial)
      {
        if (Amount > 0 && typeof(EventDetailList) !== [] && typeof(EventDetailList[0]) !== 'undefined') {
            /*
            console.log(EventDetailList);
            const sortedActivities = EventDetailList.sort(function(b, a){
      
                    return new Date(b.eventDate) - new Date(a.eventDate);
                });
            console.log(sortedActivities);*/
            seteventsInitial(false);
        }
    }
     
    const renderHeader = () => {
        const dateFormat = "MMMM yyyy";
        return (
            <div className="header row flex-middle">
            <div className="col col-start">
                <div className="icon" onClick={prevMonth}>
                chevron_left
                </div>
            </div>
            <div className="col col-center">
                <span>
                {dateFns.format(state.currentMonth, dateFormat)}
                </span>
            </div>
            <div className="col col-end" onClick={nextMonth}>
                <div className="icon">chevron_right</div>
            </div>
            </div>
        );
    };

    const renderDays = () => {
        const dateFormat = 'EEEE';
        const days = [];
        let startDate = dateFns.startOfWeek(state.currentMonth);
        for (let i = 0; i < 7; i++) {
            days.push(
            <div className="col col-center" key={i}>
                {dateFns.format(dateFns.addDays(startDate, i), dateFormat)}
            </div>
            );
        }
        return <div className="days row">{days}</div>;
    };

    const renderCells = () => {
        const { currentMonth, selectedDate } = state;
        const monthStart = dateFns.startOfMonth(currentMonth);
        const monthEnd = dateFns.endOfMonth(monthStart);
        const startDate = dateFns.startOfWeek(monthStart);
        const endDate = dateFns.endOfWeek(monthEnd);
        let dateFormat = "";
        if (mobileSize) {
            dateFormat = "d EE";
        }  else {
            dateFormat = "d";
        }

        
        const rows = [];

        let days = [];
        let day = startDate;
        let formattedDate = "";
        
        while (day <= endDate) {
        for (let i = 0; i < 7; i++) {
            formattedDate = dateFns.format(day, dateFormat);
            const cloneDay = day;
            days.push(
            <div
                className={ `col  
                ${previewToggle ? 
                    dateFns.isSaturday(day) || dateFns.isSunday(day) ? "cellh" :
                    "cell" 
                    : 
                    "hide"} 
                ${!dateFns.isSameMonth(day, monthStart) ? "disabled"
                    : dateFns.isSameDay(day, selectedDate) ? "selected" : ""}
                `}
                key={day}
                onClick={() => onDateClick(cloneDay) || setSideToggle(true)}
            >
                <span className="number">{formattedDate}</span>
                {/*<span className="eventbtn">+</span>*/}
                <span className="bg"><span className="icon">zoom_in</span></span>


                {!dateFns.isSameMonth(day, monthStart) ? "" :
                EventDetailList && EventDetailList.length === 0
                ? "" : 
                curAccount === null  ?
                    EventDetailList.filter(x => x.eventShareType === 1).sort((b, a) => new Date(b.eventDate) - new Date(a.eventDate)).map(event => dateFns.isSameDay(day, new Date(event.eventDate)) ? 
                    <>
                        <EventsCalendar key={event._id} {...event}/> 
                    </>
                    : "" )
                :  friendToggle ?
                    EventDetailList.filter(x => x.eventShareType === 1 || x.eventShareType === 2).sort((b, a) => new Date(b.eventDate) - new Date(a.eventDate)).map(event => dateFns.isSameDay(day, new Date(event.eventDate)) ? 
                    <>
                        <EventsCalendar key={event._id} {...event}/> 
                    </>
                    : "" )
                    : curAccount.userDetail._id === vAccount.vuserDetail._id ?
                        EventDetailList.sort((b, a) => new Date(b.eventDate) - new Date(a.eventDate)).map(event => dateFns.isSameDay(day, new Date(event.eventDate)) ? 
                        <>
                            <EventsCalendar key={event._id} {...event}/> 
                        </>
                        : "" )
                        :
                        EventDetailList.filter(x => x.eventShareType === 1).sort((b, a) => new Date(b.eventDate) - new Date(a.eventDate)).map(event => dateFns.isSameDay(day, new Date(event.eventDate)) ? 
                    <>
                        <EventsCalendar key={event._id} {...event}/> 
                    </>
                    : "" )
                } 
               
                
                
                
            </div>
            );
            day = dateFns.addDays(day, 1);
        }
        rows.push(
            <div className="row" key={day}>
            {days}
            </div>
        );
        days = [];
        }
        return <div className="body">{rows}</div>;
    };

    const getEventFromSelectDate = (sdate) => {
        if (curAccount === null)
        {
            return EventDetailList.filter(sevent => dateFns.isSameDay(sdate, new Date(sevent.eventDate)) && sevent.eventShareType === 1).sort((b, a) => new Date(b.eventDate) - new Date(a.eventDate))
        } else {
            if (friendToggle)
            {
                return EventDetailList.filter(sevent => dateFns.isSameDay(sdate, new Date(sevent.eventDate)) && (sevent.eventShareType === 1 || sevent.eventShareType === 2)).sort((b, a) => new Date(b.eventDate) - new Date(a.eventDate))
            } else {
                if (curAccount.userDetail._id === vAccount.vuserDetail._id)
                {
                    return EventDetailList.filter(sevent => dateFns.isSameDay(sdate, new Date(sevent.eventDate))).sort((b, a) => new Date(b.eventDate) - new Date(a.eventDate))
                } else {
                    return EventDetailList.filter(sevent => dateFns.isSameDay(sdate, new Date(sevent.eventDate)) && sevent.eventShareType === 1).sort((b, a) => new Date(b.eventDate) - new Date(a.eventDate))
                }
            }
        }
        
    }

    const onDateClick = (sday) => {
        //console.log(getEventFromSelectDate(sday));
        setselectEvents(getEventFromSelectDate(sday));
        setselectDate(sday);
        // ถ้า Click แล้วให้ทำอะไรใส่ในนี้
        // ไป find array ของ event ในวันที่ กด Click  แล้วนำ state ของ App.js มารับค่าออกไปแสดงที่ sidedrawer
    };

    const nextMonth = () => {
        setState({
            currentMonth: dateFns.addMonths(state.currentMonth, 1),
            selectedDate : new Date()
          });
    };

    const prevMonth = () => {
        setState({
            currentMonth: dateFns.subMonths(state.currentMonth, 1),
            selectedDate : new Date()
          });
    };
    return (
        
        <div className="schedule__calendar">
                {allLoaded === false ? "" : userstat > 0 ? renderHeader() : eventTodayLoaded ? renderHeader() : ""}
                {allLoaded === false ? "" : userstat > 0 ? renderDays() : eventTodayLoaded ? renderDays() : ""}
                {allLoaded === false ? "" : userstat > 0 ? renderCells() : eventTodayLoaded ? renderCells() : ""}
                {/*
                {allLoaded ? renderHeader() : ""}
                {allLoaded ? renderDays() : ""}
                {allLoaded ? renderCells(): ""}
                */}
             
        </div>
    )
}


export default Schedule;