import './Navbar.css';
import { Link } from "react-router-dom";
import logo from '../logo.png';
import { Button } from "@material-ui/core";
import { useState } from 'react'; 
import {useHistory} from 'react-router-dom';
import InputBase from '@material-ui/core/InputBase';

const Navbar = ({loclick,session,setSession,requestorList,invitorEvent,fromEventWith,setfromEventWith,setNotiToggle,notiToggle,curAccount,setsearchToggle,setsearchAccount,setfromFollow,fromNoti,setfromNoti,resfollowList,invitorResEvent,acknowList,mainToggle,mobileSize}) => {
    function topleftFunction() {
        document.body.scrollTop = 0; // For Safari
        document.body.scrollLeft = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        document.documentElement.scrollLeft = 0; // For Chrome, Firefox, IE and Opera
      }
    let history = useHistory();
    
    const openUserAccount = (linkk) => {
        history.push(`/${linkk}`);
        window.location.reload();
      }

      const handleSearch = (textt) => {
        setsearchAccount(textt);
        if (textt === "") {
            setsearchToggle(false);
            topleftFunction();
        } else {
            setsearchToggle(true);
            setfromFollow(0);
        }
        
      };
    return (
        <nav className="navbar">
            
            <div className="navbar__logo">
                {session.isLoggedIn ?
                    <div id="logo" onClick={() => openUserAccount(curAccount.userDetail.linkId)}>
                        <span className="icon">date_range</span>
                    </div>
                :    
                <div id="logo" onClick={() => openUserAccount("")}>
                <span className="icon">date_range</span>
                </div>
                }
                
                    <img src="https://firebasestorage.googleapis.com/v0/b/follows-up.appspot.com/o/icon%2Ffollowsup.png?alt=media&token=54fb4bfb-da8b-4afc-ae9a-8962bfde1d36" 
                    alt="Logo" className="Logo" 
                    onClick={() => session.isLoggedIn ? openUserAccount(curAccount.userDetail.linkId) : openUserAccount("") } />
                    <h2>Follow's up</h2>
                
                    
            </div>
    
            
            <div className="navbar__right"> 
            
                    <InputBase
                        className="navbar__search"
                        placeholder="Search Account Name"
                        inputProps={{ 'aria-label': 'search google maps' }}
                        onChange={e => handleSearch(e.target.value)}
                    />
            {curAccount !== null ?
            <div id="logo">
                    {/*<span className="iconlove">volunteer_activism</span>*/}
                    <div className="eventwithreq__logo" onClick={()=> {notiToggle ? 
                                                                            fromEventWith ? 
                                                                                fromNoti ?
                                                                                window.location.reload() || setNotiToggle(false) || setfromNoti(false)

                                                                                : setNotiToggle(false) : setNotiToggle(true) || setfromEventWith(true) 
                                                                       : setNotiToggle(true) || setfromEventWith(true)}}>
                    {invitorEvent && invitorEvent.length !== 0 ?
                        invitorResEvent && invitorResEvent.length !== 0 ?
                        <span className="eventwithreq__noti">{invitorEvent.length+invitorResEvent.length}</span> : <span className="eventwithreq__noti">{invitorEvent.length}</span>
                        :
                        invitorResEvent && invitorResEvent.length !== 0 ?
                        <span className="eventwithreq__noti">{invitorResEvent.length}</span> : ""
                    }

                        <span className="icon">person_pin_circle</span>
                    </div>
                    <div className="followreq__logo" onClick={()=> {notiToggle ? 
                                                                        fromEventWith ? 
                                                                        setNotiToggle(true)  || setfromEventWith(false) 
                                                                        :  fromNoti ?
                                                                        window.location.reload() || setNotiToggle(false) || setfromNoti(false)
                                                                        : setNotiToggle(false) 
                                                                    : setNotiToggle(true) || setfromEventWith(false)}}>
                    
                    {requestorList && requestorList.length !== 0 ?
                        resfollowList && resfollowList.length !== 0 ?
                            acknowList && acknowList.length !== 0 ?
                            <span className="followreq__noti">{requestorList.length+resfollowList.length+acknowList.length}</span> 
                            : <span className="followreq__noti">{requestorList.length+resfollowList.length}</span>
                        : 
                            acknowList && acknowList.length !== 0 ?
                            <span className="followreq__noti">{requestorList.length+acknowList.length}</span> 
                            : <span className="followreq__noti">{requestorList.length}</span>
                    :
                        resfollowList && resfollowList.length !== 0 ?
                            acknowList && acknowList.length !== 0 ?
                            <span className="followreq__noti">{resfollowList.length+acknowList.length}</span> 
                            : <span className="followreq__noti">{resfollowList.length}</span>
                        : 
                            acknowList && acknowList.length !== 0 ?
                            <span className="followreq__noti">{acknowList.length}</span> 
                            : ""
                    }
                        

                        <span className="icon">hail</span>
                    </div>
            </div>
            :
            ""}
            <div className="navbar__login">
                {session.isLoggedIn ? 
                    curAccount === null ? 
                    ""
                    :
                    <img src={curAccount.userDetail.imageUrl} alt="nav__pic" className="nav__pic" onClick={loclick}/> 
                
                : 
                   
                <Button variant="contained"  color="primary" className= "navbar_login_btn" onClick={loclick}>
                    Login
                    </Button>
                }
            
            </div>
            
            

            </div>
        </nav>
    )
}

export default Navbar