import './App.css';
import { useState , useEffect} from 'react'; 
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import ButterToast,{ POS_BOTTOM, POS_RIGHT } from "butter-toast";
import firebase from './firebase';
import { useSelector, useDispatch } from 'react-redux';

//Components
import Navbar from './components/Navbar';
import Backdrop from './components/Backdrop'; 
import SideDrawer from './components/SideDrawer'; 
import LoginDrawer from './components/LoginDrawer'; 
import EventsCreate from './components/EventsCreate'; 
import Notification from './components/Notification';
import Register from './components/Register';  
import SearchDrawer from './components/SearchDrawer'; 
import Main from './components/Main'; 

//Screens
import HomeScreen from './screens/HomeScreen';
import UserScreen from './screens/UserScreen';


// Actions
import { getAllUsersAct } from './redux/actions/userActions';
import { updateResponsefAct,updateAcknowledgeAct } from './redux/actions/followActions';
import { updateInviteResAct } from './redux/actions/withActions';


function App() {
  const dispatch = useDispatch();
  const getAllUser = useSelector(state => state.getAllUsers); 
  const { alluserloading, allUsers } = getAllUser; 
  const updateResponse = useSelector(state => state.updateResponses); 
  const { responsefLog } = updateResponse; 
  const updateInviteRes = useSelector(state => state.updateInviteRess); 
  const { invitedResLog } = updateInviteRes; 
  const updateAcknowledge = useSelector(state => state.updateAcknowledges); 
  const { acknowledgeLog } = updateAcknowledge; 

  //***************State ******************* */
  const [mobileSize, setmobileSize] = useState(false);
  const [sideToggle, setSideToggle] = useState(false);
  const [loginToggle, setLoginToggle] = useState(false);
  const [eventToggle, setEventToggle] = useState(false);
  const [notiToggle, setNotiToggle] = useState(false);
  const [registerToggle, setregisterToggle] = useState(false);
  const [searchToggle, setsearchToggle] = useState(false);
  const [mainToggle, setMainToggle] = useState(false);
  const [selectEvents, setselectEvents] = useState([]);
  const [addedEvents, setaddedEvents] = useState(null);
  const [editedEvents, seteditedEvents] = useState(false);
  const [editedWithEvents, seteditedWithEvents] = useState([]);
  const [selectDate, setselectDate] = useState(new Date());
  const [session,setSession] = useState({
    isLoggedIn: false,
    currentUser: null,
    errorMessage: null
  });
  const [curAccount,setCurAccount] = useState(null);
  const [vAccount,setvAccount] = useState(null);
  const [allAccount,setAllAccount] = useState([]);
  const [requestorList, setrequestorList] = useState([]);
  const [requestorStatus, setrequestorStatus] = useState([]);
  const [acknowList, setacknowList] = useState([]);
  const [resfollowList, setresfollowList] = useState([]);
  const [invitorList, setinvitorList] = useState([]);
  const [invitorEvent, setinvitorEvent] = useState([]);
  const [invitorResEvent, setinvitorResEvent] = useState([]);
  const [notiResponse, setnotiResponse] = useState(false);
  const [fromNoti, setfromNoti] = useState(false);
  const [fromEventWith, setfromEventWith] = useState(false);
  const [fromFollow, setfromFollow] = useState(0);
  const [searchAccount, setsearchAccount] = useState("");
  const [vfollowerMember,setvfollowerMember] = useState([]);
  const [vfollowingMember,setvfollowingMember] = useState([]);

  const [swipeToggle, setswipeToggle] = useState(false);
  const [swiped, setswiped] = useState(false);
  const [swipe,setswipe] = useState({
    x: 0,
    swiping: false,
  });
/*************************************************** */
  useEffect(() => {
    dispatch(getAllUsersAct());
    if (window.matchMedia('(max-width: 960px)').matches) { 
      setmobileSize(true);
    }
    const handleAuth = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setSession({
          isLoggedIn: true,
          currentUser: user,
          errorMessage: null
        })
      } else {
        setSession({
          isLoggedIn: false,
          currentUser: null,
          errorMessage: null
        })
      }
    });
    return () => {
      handleAuth();
    }
  }, [])
  

  useEffect(() => {
    if (alluserloading === false && typeof(allUsers) !== 'undefined') {
      //console.log(allUsers);
      setAllAccount(allUsers);
    }
  }, [allUsers])
  /*
  useEffect(()=>{ 
    console.log(searchAccount);
    console.log(allAccount.filter(element => {return element.name.toLowerCase().includes(searchAccount.toLowerCase());}));
  },[searchAccount]); 
  */

  useEffect(() => {
    if(eventToggle === false) {
      setaddedEvents(null);
      seteditedEvents(false);
      seteditedWithEvents([]);
    }
  }, [eventToggle])
  /*
  useEffect(() => {
      leftFunction();
  }, [searchToggle])
*/


  useEffect(() => {
    if(notiToggle) {
        if(requestorList && requestorList.length !== 0)
        {
          if(resfollowList && resfollowList.length !== 0)
          {
            if(acknowList && acknowList.length !== 0)
            {
              let followNotiArray = [];
              requestorList.map((item) => {
                  const updatedItem = {
                    ...item,
                    notiType: 0,
                  };
                  followNotiArray.push(updatedItem);
              });
              resfollowList.map((item) => {
                const updatedItem = {
                  ...item,
                  notiType: 1,
                };
                followNotiArray.push(updatedItem);
                dispatch(updateResponsefAct(curAccount.userDetail._id,item._id,"false"));
              });
              acknowList.map((item) => {
                const updatedItem = {
                  ...item,
                  notiType: 2,
                };
                followNotiArray.push(updatedItem);
                dispatch(updateAcknowledgeAct(item._id,curAccount.userDetail._id,"false"));
              });
              setrequestorList(followNotiArray);
              setresfollowList([]);
              setacknowList([]);
            }
            else 
            {
              let followNotiArray = [];
              requestorList.map((item) => {
                  const updatedItem = {
                    ...item,
                    notiType: 0,
                  };
                  followNotiArray.push(updatedItem);
              });
              resfollowList.map((item) => {
                const updatedItem = {
                  ...item,
                  notiType: 1,
                };
                followNotiArray.push(updatedItem);
                dispatch(updateResponsefAct(curAccount.userDetail._id,item._id,"false"));
              });
              setrequestorList(followNotiArray);
              setresfollowList([]);
            }
            
          } 
          else 
          {
            if(acknowList && acknowList.length !== 0) 
            {
              let followNotiArray = [];
              requestorList.map((item) => {
                  const updatedItem = {
                    ...item,
                    notiType: 0,
                  };
                  followNotiArray.push(updatedItem);
              });
              acknowList.map((item) => {
                const updatedItem = {
                  ...item,
                  notiType: 2,
                };
                followNotiArray.push(updatedItem);
                dispatch(updateAcknowledgeAct(item._id,curAccount.userDetail._id,"false"));
              });
              setrequestorList(followNotiArray);
              setacknowList([]);
            } 
            else 
            {
              let followNotiArray = [];
              requestorList.map((item) => {
                  const updatedItem = {
                    ...item,
                    notiType: 0,
                  };
                  followNotiArray.push(updatedItem);
              });
              setrequestorList(followNotiArray);
            }  
          }
        } 
        else 
        {
          if(resfollowList && resfollowList.length !== 0)
          {
            if(acknowList && acknowList.length !== 0)
            {
              let followNotiArray = [];
              resfollowList.map((item) => {
                const updatedItem = {
                  ...item,
                  notiType: 1,
                };
                followNotiArray.push(updatedItem);
                dispatch(updateResponsefAct(curAccount.userDetail._id,item._id,"false"));
              });
              acknowList.map((item) => {
                const updatedItem = {
                  ...item,
                  notiType: 2,
                };
                followNotiArray.push(updatedItem);
                dispatch(updateAcknowledgeAct(item._id,curAccount.userDetail._id,"false"));
              });
              setrequestorList(followNotiArray);
              setresfollowList([]);
              setacknowList([]);
            }
            else 
            {
              let followNotiArray = [];
              resfollowList.map((item) => {
                const updatedItem = {
                  ...item,
                  notiType: 1,
                };
                followNotiArray.push(updatedItem);
                dispatch(updateResponsefAct(curAccount.userDetail._id,item._id,"false"));
              });
              setrequestorList(followNotiArray);
              setresfollowList([]);
            }
          } 
          else 
          {
            if(acknowList && acknowList.length !== 0) 
            {
              let followNotiArray = [];
              acknowList.map((item) => {
                const updatedItem = {
                  ...item,
                  notiType: 2,
                };
                followNotiArray.push(updatedItem);
                dispatch(updateAcknowledgeAct(item._id,curAccount.userDetail._id,"false"));
              });
              setrequestorList(followNotiArray);
              setacknowList([]);
            } 
          }
        }
      


        if(invitorEvent && invitorEvent.length !== 0){
          if(invitorResEvent && invitorResEvent.length !== 0){
            let inviteNotiArray = [];
            invitorEvent.map((item) => {
              const updatedItem = {
                ...item,
                notiType: false,
              };
              inviteNotiArray.push(updatedItem);
            });
            invitorResEvent.map((item) => {
              const updatedItem = {
                ...item.inviteResEvent[0],
                notiType: true,
                resResult: item.inviteResResult,
                resId: item.inviteResUser[0]._id,
                resName: item.inviteResUser[0].name,
                resLink: item.inviteResUser[0].linkId,
                resImageUrl: item.inviteResUser[0].imageUrl,
              };
              inviteNotiArray.push(updatedItem);
              dispatch(updateInviteResAct(item.inviteResEvent[0].eventId,item.inviteResUser[0]._id,"false"));
              
            });
            setinvitorEvent(inviteNotiArray);
            setinvitorResEvent([]);
          } else {
            let inviteNotiArray = [];
            invitorEvent.map((item) => {
              const updatedItem = {
                ...item,
                notiType: false,
              };
              inviteNotiArray.push(updatedItem);
            });
            setinvitorEvent(inviteNotiArray);
          }
        } else {
          let inviteNotiArray = [];
          invitorResEvent.map((item) => {
            const updatedItem = {
              ...item.inviteResEvent[0],
              notiType: true,
              resResult: item.inviteResResult,
              resId: item.inviteResUser[0]._id,
              resName: item.inviteResUser[0].name,
              resLink: item.inviteResUser[0].linkId,
              resImageUrl: item.inviteResUser[0].imageUrl,
            };
            inviteNotiArray.push(updatedItem);
            dispatch(updateInviteResAct(item.inviteResEvent[0].eventId,item.inviteResUser[0]._id,"false"));
          });
          setinvitorEvent(inviteNotiArray);
          setinvitorResEvent([]);
        }
      
    } else {
      setrequestorList(requestorList.filter((res) => res.notiType === 0));
      setinvitorEvent(invitorEvent.filter((res) => res.notiType === false));
    }
  }, [notiToggle])




  if (notiResponse)
    {
      setnotiResponse(false);
    }
    /*********************** Function*********** */
    window.addEventListener("resize", function(event) {
      if (window.matchMedia('(max-width: 960px)').matches) { 
        setmobileSize(true);
      } else {
        setmobileSize(false);
      }
      //console.log(document.body.clientWidth + ' wide by ' + document.body.clientHeight+' high');
    })
    
    /*
    function leftFunction() {
      //document.body.scrollTop = 0; // For Safari
      document.body.scrollLeft = 0; // For Safari
      //document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      document.documentElement.scrollLeft = 0; // For Chrome, Firefox, IE and Opera
    }
  
  useEffect(() => {
    console.log(selectDate.toISOString());
    //console.log(selectDate.getUTCHours()); //17 ของประเทศไทยเอาไปใช้ตอน queary วันที่หา Event
    //console.log(diff_to_GMT(selectDate));
  }, [selectDate]) */
  
  const onTouchStart = (e) => {
    const touch = e.touches[0];
    setswipe({x: touch.clientX});
    setswiped(false);
  };
  const onTouchMove = (e) => {
    if (e.changedTouches && e.changedTouches.length) {
      //const touch = e.changedTouches[0];
      setswipe({
        x : swipe.x,
        swiping: true});
    }
  };
  const onTouchEnd = (e) => {
    const touch = e.changedTouches[0];
    const absX = Math.abs(touch.clientX - swipe.x);
    if (swipe.swiping && absX > 90) {
      setswiped(true);
    }
    setswipe({
      x: 0,
      swiping: false,});
  };
  /*
  const el = document.getElementById('root');
  el.ontouchstart = onTouchStart;
  el.ontouchmove = onTouchMove;
  el.ontouchend = onTouchEnd;

*/

  
  if(swiped) {
    if (curAccount !== null) {
      setswipeToggle(!swipeToggle);
    } else {
      setLoginToggle(!loginToggle)
    }
    setswiped(false);
  }

// 

  return ( 
      <Router>
      <Navbar loclick={() => setLoginToggle(true)} {...{session,setSession,requestorList,invitorEvent,fromEventWith,setfromEventWith,setNotiToggle,notiToggle,curAccount,setsearchToggle,setsearchAccount,setfromFollow,fromNoti,setfromNoti,resfollowList,invitorResEvent,acknowList,mainToggle,mobileSize}}/>
      <SideDrawer show={sideToggle} {...{selectEvents,selectDate,curAccount,vAccount,setfromNoti,setaddedEvents,setEventToggle,setSideToggle,sideToggle,seteditedEvents,seteditedWithEvents}}/>
      <Backdrop show={sideToggle || loginToggle || eventToggle || registerToggle || fromFollow !== 0} 
                click={()=>setSideToggle(false) || setLoginToggle(false) || setEventToggle(false) || setregisterToggle(false) || setfromFollow(0) || setsearchToggle(false) || fromNoti ? window.location.reload() || setfromNoti(false) : ""}
                />
      <LoginDrawer show={loginToggle} loclick={()=>setLoginToggle(false)} {...{session,setSession,curAccount,setregisterToggle,setLoginToggle,loginToggle}}/>
      <SearchDrawer show={searchToggle} {...{session,setSession,curAccount,setsearchToggle,searchAccount,allAccount,vfollowerMember,vfollowingMember,fromFollow,setfromFollow,vAccount}}/>
      <Main show={mainToggle} loclick={() => setLoginToggle(!loginToggle)} {...{setMainToggle,mobileSize}}/>
      <EventsCreate show={eventToggle} eclick={()=>setEventToggle(false)} {...{session,setSession,selectDate,curAccount,addedEvents,setselectDate,editedEvents,editedWithEvents}}/>
      <Register show={registerToggle} eclick={()=>setregisterToggle(false)} {...{session,setSession,curAccount,setSession}}/>
      <Notification show={notiToggle} {...{curAccount,requestorList,setrequestorList,setnotiResponse,setfromNoti,requestorStatus,fromEventWith,invitorEvent,setinvitorEvent,invitorList,resfollowList,setresfollowList}}/>
      <ButterToast position={{vertical:POS_BOTTOM,horizontal:POS_RIGHT}} />
      <main onTouchStart={e => onTouchStart(e)} onTouchMove={e => onTouchMove(e)} onTouchEnd={e=> onTouchEnd(e)}>
        <Switch>
        <Route exact path="/"> <HomeScreen {...session} 
                                            setNotiToggle={setNotiToggle} 
                                            setfromNoti = {setfromNoti} 
                                            fromNoti={fromNoti} 
                                            setregisterToggle={setregisterToggle}
                                            setMainToggle={setMainToggle}  />  
        </Route>

        <Route exact path="/:linkId"> <UserScreen {...session} 
                                                  setSession={setSession} 
                                                  setSideToggle={setSideToggle} 
                                                  setselectEvents={setselectEvents} 
                                                  setselectDate={setselectDate} 
                                                  setCurAccount={setCurAccount} 
                                                  curAccount={curAccount}
                                                  vAccount= {vAccount}
                                                  setvAccount={setvAccount} 
                                                  setNotiToggle={setNotiToggle} 
                                                  setrequestorList={setrequestorList}
                                                  setrequestorStatus={setrequestorStatus} 
                                                  setfromNoti = {setfromNoti}
                                                  setinvitorList = {setinvitorList}
                                                  setinvitorEvent = {setinvitorEvent}
                                                  fromNoti={fromNoti}
                                                  vfollowerMember={vfollowerMember}
                                                  setvfollowerMember= {setvfollowerMember}
                                                  setvfollowingMember= {setvfollowingMember}
                                                  setfromFollow= {setfromFollow} 
                                                  setsearchToggle= {setsearchToggle}
                                                  setregisterToggle={setregisterToggle}
                                                  allAccount={allAccount} 
                                                  setresfollowList={setresfollowList} 
                                                  setacknowList={setacknowList}
                                                  setinvitorResEvent={setinvitorResEvent}
                                                  mobileSize={mobileSize}
                                                  setLoginToggle={setLoginToggle}
                                                  loginToggle={loginToggle}
                                                  setEventToggle={setEventToggle}
                                                  setaddedEvents={setaddedEvents}
                                                  swipeToggle={swipeToggle}
                                                  /> 
        </Route>

        {/*{match ? <UserScreen match={match} {...session} {...userDetail} setSideToggle={setSideToggle}/> : <div>a</div>}*/}




        
        </Switch>
      </main>
      </Router>
  );
}

export default App;
