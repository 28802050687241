import './EventSetting.css';
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { updateEventTypeAct,deleteEventAct } from '../redux/actions/eventActions';
import { updateWithEventTypeAct,deleteEventWithAct,deleteAllEventWithAct } from '../redux/actions/withActions';

const EventSetting = ({show,seevent,setfromNoti,curAccount,eventtypee,seteventtypee,seteSetting,setaddedEvents,setEventToggle,setSideToggle,sideToggle,seteditedEvents,seteditedWithEvents,withUserList}) => {
    // ******************** Redux Store variable**********************
    const dispatch = useDispatch();
    const updateEventType = useSelector(state => state.updateEventTypes); 
    const { eventLog } = updateEventType; 
    const updateWithEventType = useSelector(state => state.updateWithEventTypes); 
    const { withEventTypeLog } = updateWithEventType; 
    const deleteEvent = useSelector(state => state.deleteEvents); 
    const { deleteEventRecord } = deleteEvent; 
    const deleteEventWith = useSelector(state => state.deleteEventWiths); 
    const { deleteEventWithRecord } = deleteEventWith; 
    const deleteAllEventWith = useSelector(state => state.deleteAllEventWiths); 
    const { deleteAllEventWithRecord } = deleteAllEventWith; 

    
    

    const setupdateevent = (eid, type) => {
        dispatch(updateEventTypeAct(eid,type));
        console.log("Update eventType Success");
      };

      const setupdatewithevent = (eid, id, type) => {
        dispatch(updateWithEventTypeAct(eid,id,type));
        console.log("Update witheventType Success");
      };

      const handleDeleteEvent = (eid) => {
        if(window.confirm('Are you sure to delete this event?'))
        {
            dispatch(deleteAllEventWithAct(eid));
            dispatch(deleteEventAct(eid));
            console.log("Delete Event & EventWith Success");
            window.location.reload()
        }
      };
      const handleDeleteEventWith = (eid,id) => {
        if(window.confirm('Are you sure to delete this event?'))
        {  
            dispatch(deleteEventWithAct(eid,id));
            console.log("Delete EventWith Success");
            window.location.reload()
        }
      };
      const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
      }
      /*
      const handleDeleteAllEventWith = (eid) => {
        if(window.confirm('Are you sure to delete this record?'))
        {
            dispatch(deleteAllEventWithAct(eid));
            console.log("Delete All EventWith Success");
        }
      };*/

    const eventSettingDrawerClass = ["eventsettingdrawer"]; 
    if (show) {
        eventSettingDrawerClass.push("show");
    }


    return (
        <div className="eventsetting__main">
            <div className="eventsetting__who">Who will see this event ?</div>
            {seevent.createId !== curAccount.userDetail._id ?
                eventtypee === 1 ?
                    <>
                    <div className="eventsetting__public__select">Public</div>
                    <div className="eventsetting__friend" onClick={() => setupdatewithevent(seevent.eventId,curAccount.userDetail._id,2) || seteventtypee(2) || setfromNoti(true) || seteSetting(false)}>Friends</div>
                    <div className="eventsetting__me" onClick={() => setupdatewithevent(seevent.eventId,curAccount.userDetail._id,3) || seteventtypee(3) || setfromNoti(true) || seteSetting(false)}>Only Me</div>
                    {seevent.eventLocation.toLowerCase().toString().substring(0,4) === 'www.' ?
                    <div className="eventsetting__map" onClick={() => openInNewTab(`https://${seevent.eventLocation}`)}>Goto Link</div>
                    :
                    <div className="eventsetting__map" onClick={() => openInNewTab(`https://google.co.th/maps/search/${seevent.eventLocation}/`)}>Goto Map</div>}
                    <div className="eventsetting__delete"onClick={() => handleDeleteEventWith(seevent.eventId,curAccount.userDetail._id)}>Delete event</div>
                    </>
                : 
                eventtypee === 2 ?
                    <>
                    <div className="eventsetting__public" onClick={() => setupdatewithevent(seevent.eventId,curAccount.userDetail._id,1) || seteventtypee(1) || setfromNoti(true) || seteSetting(false)}>Public</div>
                    <div className="eventsetting__friend__select">Friends</div>
                    <div className="eventsetting__me" onClick={() => setupdatewithevent(seevent.eventId,curAccount.userDetail._id,3) || seteventtypee(3) || setfromNoti(true) || seteSetting(false)}>Only Me</div>
                    {seevent.eventLocation.toLowerCase().toString().substring(0,4) === 'www.' ?
                    <div className="eventsetting__map" onClick={() => openInNewTab(`https://${seevent.eventLocation}`)}>Goto Link</div>
                    :
                    <div className="eventsetting__map" onClick={() => openInNewTab(`https://google.co.th/maps/search/${seevent.eventLocation}/`)}>Goto Map</div>}
                    <div className="eventsetting__delete"onClick={() => handleDeleteEventWith(seevent.eventId,curAccount.userDetail._id)}>Delete event</div>
                    </>
                :
                    <>
                    <div className="eventsetting__public" onClick={() => setupdatewithevent(seevent.eventId,curAccount.userDetail._id,1) || seteventtypee(1) || setfromNoti(true) || seteSetting(false)}>Public</div>
                    <div className="eventsetting__friend" onClick={() => setupdatewithevent(seevent.eventId,curAccount.userDetail._id,2) || seteventtypee(2) || setfromNoti(true) || seteSetting(false)}>Friends</div>
                    <div className="eventsetting__me__select">Only Me</div>
                    {seevent.eventLocation.toLowerCase().toString().substring(0,4) === 'www.' ?
                    <div className="eventsetting__map" onClick={() => openInNewTab(`https://${seevent.eventLocation}`)}>Goto Link</div>
                    :
                    <div className="eventsetting__map" onClick={() => openInNewTab(`https://google.co.th/maps/search/${seevent.eventLocation}/`)}>Goto Map</div>}
                    <div className="eventsetting__delete"onClick={() => handleDeleteEventWith(seevent.eventId,curAccount.userDetail._id)}>Delete event</div>
                    </>
            :
                eventtypee === 1 ?
                    <>
                    <div className="eventsetting__public__select">Public</div>
                    <div className="eventsetting__friend" onClick={() => setupdateevent(seevent.eventId,2) || seteventtypee(2) || setfromNoti(true) || seteSetting(false)}>Friends</div>
                    <div className="eventsetting__me" onClick={() => setupdateevent(seevent.eventId,3) || seteventtypee(3) || setfromNoti(true) || seteSetting(false)}>Only Me</div>
                    {seevent.eventLocation.toLowerCase().toString().substring(0,4) === 'www.' ?
                    <div className="eventsetting__map" onClick={() => openInNewTab(`https://${seevent.eventLocation}`)}>Goto Link</div>
                    :
                    <div className="eventsetting__map" onClick={() => openInNewTab(`https://google.co.th/maps/search/${seevent.eventLocation}/`)}>Goto Map</div>}
                    <div className="eventsetting__edit" onClick={()=> setEventToggle(true) || setSideToggle(false) || setaddedEvents(seevent) || seteditedEvents(true) || seteditedWithEvents(withUserList)}>Edit event</div>
                    <div className="eventsetting__delete"onClick={() => handleDeleteEvent(seevent.eventId)}>Delete event</div>
                    </>
                : 
                eventtypee === 2 ?
                    <>
                    <div className="eventsetting__public" onClick={() => setupdateevent(seevent.eventId,1) || seteventtypee(1) || setfromNoti(true) || seteSetting(false)}>Public</div>
                    <div className="eventsetting__friend__select">Friends</div>
                    <div className="eventsetting__me" onClick={() => setupdateevent(seevent.eventId,3) || seteventtypee(3) || setfromNoti(true) || seteSetting(false)}>Only Me</div>
                    {seevent.eventLocation.toLowerCase().toString().substring(0,4) === 'www.' ?
                    <div className="eventsetting__map" onClick={() => openInNewTab(`https://${seevent.eventLocation}`)}>Goto Link</div>
                    :
                    <div className="eventsetting__map" onClick={() => openInNewTab(`https://google.co.th/maps/search/${seevent.eventLocation}/`)}>Goto Map</div>}
                    <div className="eventsetting__edit" onClick={()=> setEventToggle(true) || setSideToggle(false) || setaddedEvents(seevent) || seteditedEvents(true) || seteditedWithEvents(withUserList)}>Edit event</div>
                    <div className="eventsetting__delete"onClick={() => handleDeleteEvent(seevent.eventId)}>Delete event</div>
                    </>
                :
                    <>
                    <div className="eventsetting__public" onClick={() => setupdateevent(seevent.eventId,1) || seteventtypee(1) || setfromNoti(true) || seteSetting(false)}>Public</div>
                    <div className="eventsetting__friend" onClick={() => setupdateevent(seevent.eventId,2) || seteventtypee(2) || setfromNoti(true) || seteSetting(false)}>Friends</div>
                    <div className="eventsetting__me__select">Only Me</div>
                    {seevent.eventLocation.toLowerCase().toString().substring(0,4) === 'www.' ?
                    <div className="eventsetting__map" onClick={() => openInNewTab(`https://${seevent.eventLocation}`)}>Goto Link</div>
                    :
                    <div className="eventsetting__map" onClick={() => openInNewTab(`https://google.co.th/maps/search/${seevent.eventLocation}/`)}>Goto Map</div>}
                    <div className="eventsetting__edit" onClick={()=> setEventToggle(true) || setSideToggle(false) || setaddedEvents(seevent) || seteditedEvents(true) || seteditedWithEvents(withUserList)}>Edit event</div>
                    <div className="eventsetting__delete"onClick={() => handleDeleteEvent(seevent.eventId)}>Delete event</div>
                    </>
            
            }
            

            
        </div>
    )
}

export default EventSetting