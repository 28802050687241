
export const GET_USER_DETAILS_REQUEST = "GET_USER_DETAILS_REQUEST";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAIL = "GET_USER_DETAILS_FAIL";
export const GET_USER_DETAILS_RESET = "GET_USER_DETAILS_RESET";

export const GET_VUSER_DETAILS_REQUEST = "GET_VUSER_DETAILS_REQUEST";
export const GET_VUSER_DETAILS_SUCCESS = "GET_VUSER_DETAILS_SUCCESS";
export const GET_VUSER_DETAILS_FAIL = "GET_VUSER_DETAILS_FAIL";



export const ADD_USER = "ADD_USER";
export const ADD_USER_FAIL = "ADD_USER_FAIL";

export const GET_ALL_LINK_REQUEST = "GET_ALL_LINK_REQUEST";
export const GET_ALL_LINK_SUCCESS = "GET_ALL_LINK_SUCCESS";
export const GET_ALL_LINK_FAIL = "GET_ALL_LINK_FAIL";

export const GET_ALL_USERS_REQUEST = "GET_ALL_USERS_REQUEST";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAIL = "GET_ALL_USERS_FAIL";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const UPDATE_USERTYPE = "UPDATE_USERTYPE";
export const UPDATE_USERTYPE_FAIL = "UPDATE_USERTYPE_FAIL";

export const UPDATE_USERKEY = "UPDATE_USERKEY";
export const UPDATE_USERKEY_FAIL = "UPDATE_USERKEY_FAIL";

export const UPDATE_USERLAST = "UPDATE_USERLAST";
export const UPDATE_USERLAST_FAIL = "UPDATE_USERLAST_FAIL";