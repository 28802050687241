import * as actionTypes from '../constants/followConstants';
import axios from "axios";
import * as dateFns from 'date-fns';
const apiUrl = 'https://asia-southeast2-follows-up.cloudfunctions.net/app';
//const apiUrl = 'http://localhost:5000';
//const apiUrl = 'http://192.168.1.5:5000';



export const getFollowingList = (id) => async (dispatch) => {
    try {
        dispatch({type: actionTypes.GET_USER_FOLLOWING_REQUEST});
        const startDate = dateFns.startOfWeek(new Date());
        const endString = `T${startDate.getUTCHours().toString()}:00:00.000Z`;
        let currentTime = `${new Date().toISOString()}`;
        let endTime = ""
        if ((new Date().getUTCHours()) >= 17 && (new Date().getUTCHours()) < 24) {
            endTime = `${dateFns.addDays(new Date(),1).toISOString().substring(0,10)}${endString}`;
        } else {
            endTime = `${new Date().toISOString().substring(0,10)}${endString}`;
        }
        const {data}  = await axios.get(apiUrl+`/api/follows/${id}/${currentTime}/${endTime}/afollowing`);
        dispatch({
            type: actionTypes.GET_USER_FOLLOWING_SUCCESS,
            payload: data
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.GET_USER_FOLLOWING_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};

export const getCurrentFollowingListAct = (id) => async (dispatch) => {
    try {
        dispatch({type: actionTypes.GET_CURRENT_USER_FOLLOWING_REQUEST});
        const startDate = dateFns.startOfWeek(new Date());
        const endString = `T${startDate.getUTCHours().toString()}:00:00.000Z`;
        let currentTime = `${new Date().toISOString()}`;
        let endTime = ""
        if ((new Date().getUTCHours()) >= 17 && (new Date().getUTCHours()) < 24) {
            endTime = `${dateFns.addDays(new Date(),1).toISOString().substring(0,10)}${endString}`;
        } else {
            endTime = `${new Date().toISOString().substring(0,10)}${endString}`;
        }
        const {data}  = await axios.get(apiUrl+`/api/follows/${id}/${currentTime}/${endTime}/afollowing`);
        dispatch({
            type: actionTypes.GET_CURRENT_USER_FOLLOWING_SUCCESS,
            payload: data
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.GET_CURRENT_USER_FOLLOWING_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};

export const getFollowerList = (id) => async (dispatch) => {
    try {
        dispatch({type: actionTypes.GET_USER_FOLLOWERS_REQUEST});

        const {data}  = await axios.get(apiUrl+`/api/follows/${id}/follower`);
        dispatch({
            type: actionTypes.GET_USER_FOLLOWERS_SUCCESS,
            payload: data
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.GET_USER_FOLLOWERS_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};

export const getFriendListAct = (id) => async (dispatch) => {
    try {
        dispatch({type: actionTypes.GET_USER_FRIEND_REQUEST});

        const {data}  = await axios.get(apiUrl+`/api/follows/${id}/friend`);
        dispatch({
            type: actionTypes.GET_USER_FRIEND_SUCCESS,
            payload: data
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.GET_USER_FRIEND_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};

export const checkFavouriteAct = (id,followid) => async (dispatch) => {
    try {
        const {data}  = await axios.get(apiUrl+`/api/follows/checkfavourite/${id}/${followid}`);
        dispatch({
            type: actionTypes.CHECK_FAVOURITE_REQUEST,
            payload: data[0]
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.CHECK_FAVOURITE_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};

export const checkFriendAct = (id,followid) => async (dispatch) => {
    try {
        const {data}  = await axios.get(apiUrl+`/api/follows/checkfriend/${id}/${followid}`);
        dispatch({
            type: actionTypes.CHECK_FRIEND_REQUEST,
            payload: data[0]
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.CHECK_FRIEND_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};

export const checkRelationAct = (id,followid) => async (dispatch) => {
    try {
        const {data}  = await axios.get(apiUrl+`/api/follows/checkrelation/${id}/${followid}`);
        dispatch({
            type: actionTypes.CHECK_RELATION_REQUEST,
            payload: data[0]
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.CHECK_RELATION_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};

export const addToFollowAct = (newRecord) => async (dispatch) => {
    try {
        //const { data } = await axios.post('http://192.168.1.5:5000/api/follows', newRecord[0]);
        //const { data } = await axios.post('http://localhost:5000/api/follows', newRecord[0]);
        const { data } = await axios.post('https://asia-southeast2-follows-up.cloudfunctions.net/app/api/follows', newRecord[0]);
        dispatch({
            type: actionTypes.ADD_TO_FOLLOWS,
            payload: data
        });
        console.log("Data imported Success.");
        window.location.reload();
    } catch (error) {
        dispatch({
            type: actionTypes.ADD_TO_FOLLOWS_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
        console.error("Error with data imported[Action].");
    }
};

export const unFollowAct = (id,followid) => async (dispatch) => {
    try {
        const {data}  = await axios.get(apiUrl+`/api/follows/unfollow/${id}/${followid}`);
        dispatch({
            type: actionTypes.UNFOLLOWS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: actionTypes.UNFOLLOWS_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};

export const getFollowRequestListAct = (id) => async (dispatch) => {
    try {
        dispatch({type: actionTypes.GET_FOLLOWREQUEST_REQUEST});

        const {data}  = await axios.get(apiUrl+`/api/follows/${id}/followReq`);
        dispatch({
            type: actionTypes.GET_FOLLOWREQUEST_SUCCESS,
            payload: data
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.GET_FOLLOWREQUEST_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};

export const getFollowAcknowledgeListAct = (id) => async (dispatch) => {
    try {
        dispatch({type: actionTypes.GET_FOLLOWACKNOWLEDGE_REQUEST});

        const {data}  = await axios.get(apiUrl+`/api/follows/${id}/followAck`);
        dispatch({
            type: actionTypes.GET_FOLLOWACKNOWLEDGE_SUCCESS,
            payload: data
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.GET_FOLLOWACKNOWLEDGE_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};

export const updateRequestAct = (id,followid) => async (dispatch) => {
    try {
        const {data}  = await axios.get(apiUrl+`/api/follows/updaterequest/${id}/${followid}/false`);
        dispatch({
            type: actionTypes.UPDATE_REQUEST,
            payload: data
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.UPDATE_REQUEST_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};
export const updateAcknowledgeAct = (id,followid,res) => async (dispatch) => {
    try {
        const {data}  = await axios.get(apiUrl+`/api/follows/updateack/${id}/${followid}/${res}`);
        dispatch({
            type: actionTypes.UPDATE_ACKNOWLEDGE,
            payload: data
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.UPDATE_ACKNOWLEDGE_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};

export const getFollowResponseListAct = (id) => async (dispatch) => {
    try {
        dispatch({type: actionTypes.GET_FOLLOWRESPONSE_REQUEST});

        const {data}  = await axios.get(apiUrl+`/api/follows/${id}/resfollowReq`);
        dispatch({
            type: actionTypes.GET_FOLLOWRESPONSE_SUCCESS,
            payload: data
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.GET_FOLLOWRESPONSE_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};

export const updateResponsefAct = (id,followid,resf) => async (dispatch) => {
    try {
        const {data}  = await axios.get(apiUrl+`/api/follows/updateresponse/${id}/${followid}/${resf}`);
        dispatch({
            type: actionTypes.UPDATE_FOLLOWRESPOND,
            payload: data
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.UPDATE_FOLLOWRESPOND_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};


export const updateFavouriteAct = (id,followid,favou) => async (dispatch) => {
    try {
        const {data}  = await axios.get(apiUrl+`/api/follows/updatefavourite/${id}/${followid}/${favou}`);
        dispatch({
            type: actionTypes.UPDATE_FAVOURITE,
            payload: data
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.UPDATE_FAVOURITE_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};

export const updateFriendAct = (id,followid,fri) => async (dispatch) => {
    try {
        const {data}  = await axios.get(apiUrl+`/api/follows/updatefriend/${id}/${followid}/${fri}`);
        dispatch({
            type: actionTypes.UPDATE_FRIEND,
            payload: data
        });
        
    } catch (error) {
        dispatch({
            type: actionTypes.UPDATE_FRIEND_FAIL,
            payload: 
                error.response && error.response.data.message 
                ?  error.response.data.message 
                : error.message,
        });
    }
};